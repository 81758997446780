// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// types
import { ArrElement } from '../../types/types'

// payloads
export type GetPagesPayload = RequestResponse<GetUrls['/admin/pages/']>['pages']
export type GetPagesListItemPayload = ArrElement<GetPagesPayload>
export type GetPagesPageIdPayload = RequestResponse<GetUrls['/admin/pages/{pageID}']>
export type PostPagesPageIdDuplicatePayload = RequestResponse<PostUrls['/admin/pages/{pageID}/duplicate']>

// query params
export type GetPagesQueryParams = RequestParams<GetUrls['/admin/pages/']>['query']

// bodies
export type PostPagesBody = RequestPayload<PostUrls['/admin/pages/']>
export type PutPagesPageIdBody = RequestPayload<PutUrls['/admin/pages/{pageID}']>

export enum GET_PAGES {
	START = 'GET_PAGES_START',
	DONE = 'GET_PAGES_DONE',
	FAILED = 'GET_PAGES_FAILED'
}

export enum GET_PAGE {
	START = 'GET_PAGE_START',
	DONE = 'GET_PAGE_DONE',
	FAILED = 'GET_PAGE_FAILED'
}

export enum UPDATE_PAGE {
	START = 'UPDATE_PAGE_START',
	DONE = 'UPDATE_PAGE_DONE',
	FAILED = 'UPDATE_PAGE_FAILED'
}

export enum DELETE_PAGE {
	START = 'DELETE_PAGE_START',
	DONE = 'DELETE_PAGE_DONE',
	FAILED = 'DELETE_PAGE_FAILED'
}

export enum CREATE_PAGE {
	START = 'CREATE_PAGE_START',
	DONE = 'CREATE_PAGE_DONE',
	FAILED = 'CREATE_PAGE_FAILED'
}

export enum DUPLICATE_PAGE {
	START = 'DUPLICATE_PAGE_START',
	DONE = 'DUPLICATE_PAGE_DONE',
	FAILED = 'DUPLICATE_PAGE_FAILED'
}
