import { FC, useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import { Spin } from 'antd'
import { each, find, forEach, get, map } from 'lodash'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { createInstructor, getInstructor, updateInstructor } from '../../redux/instructors/actions'

// types
import { ArrElement } from '../../types/types'
import { GetTrainersIdPayload, PostTrainersBody, PutTrainersIdBody } from '../../redux/instructors/types'

// enums
import { FORMS, LANGUAGE, LANGUAGES } from '../../utils/enums'

// forms
import InstructorForm from './forms/InstructorForm'

interface IDetailInstructor {
	computedMatch: {
		params: any
	}
}

const DetailInstructor: FC<IDetailInstructor> = ({ computedMatch }) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()

	const instructor = useSelector((state) => get(state, 'instructors.instructorDetail'))
	const isLoading = get(instructor, 'isLoading')

	const { id } = computedMatch.params

	const initDetailForm = useCallback(
		(data: any) => {
			const instructorData = data || get(instructor, 'data')

			const langData = get(instructorData, 'translations')

			const initValues = {
				...instructorData,
				order: get(instructorData, 'order') || 1,
				language: get(instructorData, 'language') || LANGUAGE.SK,
				updatedAt: dayjs(instructorData.updatedAt).format('H:mm:ss D. MMM YYYY '),
				programs: map(get(instructorData, 'programs'), (item) => ({ id: item.id, title: item.name })),
				recommendedBlogPosts: map(get(instructorData, 'recommendedBlogPosts'), (item) => ({ id: item.id, title: item.title })),
				roles: get(instructorData, 'roles'),
				video: [{ id: get(instructorData, 'video.id'), title: get(instructorData, 'video.name') }]
			}

			const langValues: any = {}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`name_${item}`] = get(lang, 'name')
				langValues[`urlSlug_${item}`] = get(lang, 'urlSlug')
				langValues[`briefDescription_${item}`] = get(lang, 'briefDescription')
				langValues[`detailedDescription_${item}`] = get(lang, 'detailedDescription')
				langValues[`education_${item}`] = get(lang, 'education')
				langValues[`rewards_${item}`] = get(lang, 'rewards')
				langValues[`about_${item}`] = get(lang, 'about')
				langValues[`motivationBannerDescription_${item}`] = get(lang, 'motivationBannerDescription')
				langValues[`motivationBannerLabel_${item}`] = get(lang, 'motivationBannerLabel')
				langValues[`motivationBannerName_${item}`] = get(lang, 'motivationBannerName')
			})

			dispatch(
				initialize(FORMS.INSTRUCTOR_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch, instructor]
	)

	useEffect(() => {
		if (id) {
			dispatch(getInstructor(id, (data: GetTrainersIdPayload) => initDetailForm(data)))
		} else {
			dispatch(
				initialize(FORMS.INSTRUCTOR_FORM, {
					order: 1
				})
			)
		}
	}, [dispatch, id])

	const handleCreate = (body: PostTrainersBody) =>
		dispatch(createInstructor(body, (instructorID: number) => history.push(`${t('paths:instructor|path')}/${instructorID}`)))

	const handleUpdate = (body: PutTrainersIdBody) =>
		dispatch(updateInstructor(id, body, () => dispatch(getInstructor(id, (data: GetTrainersIdPayload) => initDetailForm(data)))))

	const handleSubmit = (values: any) => {
		const body: any = {
			translations: [],
			order: get(values, 'order', 1),
			image: get(values, 'image'),
			language: get(values, 'language'),
			coverImage: get(values, 'coverImage'),
			isPublished: get(values, 'isPublished'),
			facebook: get(values, 'facebook'),
			instagram: get(values, 'instagram'),
			website: get(values, 'website'),
			isVisibleOnLandingPage: get(values, 'isVisibleOnLandingPage'),
			videoID: get(values, 'video[0].id', null) || null,
			programs: map(get(values, 'programs'), (item) => get(item, 'id')),
			recommendedBlogPosts: map(get(values, 'recommendedBlogPosts', []), (item) => get(item, 'id')),
			roles: get(values, 'roles')
		}

		each(LANGUAGES, (item) => {
			const name = get(values, `name_${item}`)
			const urlSlug = get(values, `urlSlug_${item}`)
			const briefDescription = get(values, `briefDescription_${item}`)
			const detailedDescription = get(values, `detailedDescription_${item}`)
			const education = get(values, `education_${item}`, '')
			const rewards = get(values, `rewards_${item}`)
			const about = get(values, `about_${item}`)
			const motivationBannerDescription = get(values, `motivationBannerDescription_${item}`)
			const motivationBannerLabel = get(values, `motivationBannerLabel_${item}`)
			const motivationBannerName = get(values, `motivationBannerName_${item}`)

			if (!name) {
				return
			}

			const formatOutput = (value: any) => (value && value.length > 0 ? value : undefined)

			const translation: ArrElement<PostTrainersBody['translations']> = {
				language: item,
				name: formatOutput(name),
				urlSlug: formatOutput(urlSlug),
				briefDescription: formatOutput(briefDescription),
				detailedDescription: formatOutput(detailedDescription),
				rewards: formatOutput(rewards),
				about: formatOutput(about),
				education: formatOutput(education),
				motivationBannerDescription: formatOutput(motivationBannerDescription),
				motivationBannerLabel: formatOutput(motivationBannerLabel),
				motivationBannerName: formatOutput(motivationBannerName)
			}

			body.translations.push(translation)
		})

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<InstructorForm onSubmit={handleSubmit} isCreate={!id} />
			</Spin>
		</div>
	)
}

export default DetailInstructor
