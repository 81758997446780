// utils
import { GetUrls, PostUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// types
import { ArrElement } from '../../types/types'

// payloads
export type GetPaymentsPayload = RequestResponse<GetUrls['/admin/payments/']>['payments']
export type GetPaymentsListItemPayload = ArrElement<GetPaymentsPayload>
export type GetPaymentsIdPayload = RequestResponse<GetUrls['/admin/payments/{id}']>

// query params
export type GetPaymentsQueryParams = RequestParams<GetUrls['/admin/payments/']>['query']
export type GetPaymentsExportQueryParams = RequestParams<GetUrls['/admin/payments/export']>['query']

// bodies
export type PostPaymentsRefundBody = RequestPayload<PostUrls['/admin/payments/refund']>
export type PostPaymentsCodeDeactivateBody = RequestPayload<PostUrls['/admin/payments/code-deactivate']>
export type PostPaymentsUserIdInvoice = RequestPayload<PostUrls['/admin/payments/{userID}/invoice']>

export enum GET_USER_PAYMENTS {
	START = 'GET_USER_PAYMENTS_START',
	DONE = 'GET_USER_PAYMENTS_DONE',
	FAILED = 'GET_USER_PAYMENTS_FAILED'
}

export enum GET_SUBSCRIPTION_PAYMENTS {
	START = 'GET_SUBSCRIPTION_PAYMENTS_START',
	DONE = 'GET_SUBSCRIPTION_PAYMENTS_DONE',
	FAILED = 'GET_SUBSCRIPTION_PAYMENTS_FAILED'
}

export enum GET_PAYMENTS {
	START = 'GET_PAYMENTS_START',
	DONE = 'GET_PAYMENTS_DONE',
	FAILED = 'GET_PAYMENTS_FAILED'
}

export enum EXPORT_PAYMENTS {
	START = 'EXPORT_PAYMENTS_START',
	DONE = 'EXPORT_PAYMENTS_DONE',
	FAILED = 'EXPORT_PAYMENTS_FAILED'
}

export enum GET_PAYMENT {
	START = 'GET_PAYMENT_START',
	DONE = 'GET_PAYMENT_DONE',
	FAILED = 'GET_PAYMENT_FAILED'
}

export enum GET_PAYMENT_INVOICE {
	START = 'GET_PAYMENT_INVOICE_START',
	DONE = 'GET_PAYMENT_INVOICE_DONE',
	FAILED = 'GET_PAYMENT_INVOICE_FAILED'
}

export enum REFUND_PAYMENT {
	START = 'REFUND_PAYMENT_START',
	DONE = 'REFUND_PAYMENT_DONE',
	FAILED = 'REFUND_PAYMENT_FAILED'
}

export enum REFUND_PAYMENTS {
	START = 'REFUND_PAYMENTS_START',
	DONE = 'REFUND_PAYMENTS_DONE',
	FAILED = 'REFUND_PAYMENTS_FAILED'
}

export enum EXECUTE_PAYMENT {
	START = 'EXECUTE_PAYMENT_START',
	DONE = 'EXECUTE_PAYMENT_DONE',
	FAILED = 'EXECUTE_PAYMENT_FAILED'
}

export enum DEACTIVATE_SMS_CODE {
	START = 'DEACTIVATE_SMS_CODE_START',
	DONE = 'DEACTIVATE_SMS_CODE_DONE',
	FAILED = 'DEACTIVATE_SMS_CODE_FAILED'
}
