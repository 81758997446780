import { useCallback, useEffect, useRef, useState } from 'react'
import ceil from 'lodash/ceil'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import {
	checkGeneration,
	createVideo,
	generateAnimation,
	generateThumbnail,
	getVideo,
	saveAnimation,
	stopGenerating,
	updateVideo
} from '../../redux/videos/actions'

// utils
import { FORMS, GENERATE_STATE, LANGUAGE, LANGUAGES, VIDEO_DIFFICULTY } from '../../utils/enums'

// types
import { GetVideosIdPayload, PostVideosBody, PutVideosIdBody } from '../../redux/videos/types'
import { IComputedMatch } from '../../types/interfaces'

// forms
import ThumbnailGIFForm from './forms/ThumbnailGIFForm'
import VideoForm from './forms/VideoForm'
import { ThumbnailGIFFormValues } from './forms/validateGIFForm'
import { VideoFormValues } from './forms/validateVideoForm'

// components
import GeneratorOverlay from '../../components/GeneratorOverlay'

type VideoDetailProps = {
	computedMatch: IComputedMatch
}

const VideoDetail = ({ computedMatch }: VideoDetailProps) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()
	const { isArray } = Array

	// refs
	const generateAnimationRef = useRef<any>(null)

	// selectors
	const detail = useSelector((state: RootState) => state.videos?.detail)
	const generation = useSelector((state: RootState) => state.videos?.ganerateAnimation)

	// states
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [thumbnailsChanged, setThumbnailsChanged] = useState(false)

	// constants
	const { id } = computedMatch.params
	const isLoading = detail?.isLoading

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {
			bodyParts: [],
			accessories: [],
			order: 1
		}

		dispatch(initialize(FORMS.VIDEO_FORM, initValues))
	}, [dispatch])

	const getValueObject = (value: any, attName: string) => {
		if (value === null || isEmpty(value)) return null
		if (isArray(value)) {
			return [{ id: value[0].id, title: value[0]?.[`${attName}`] }]
		}
		return [{ id: value.id, title: value?.[`${attName}`] }]
	}

	const initDetailForm = useCallback(
		(data: GetVideosIdPayload) => {
			const videoData = data?.video
			const langData = videoData?.translations
			const langValues: any = {}

			const initValues = {
				id: get(videoData, 'id'),
				language: get(videoData, 'language'),
				image: get(videoData, 'image'),
				difficulty: get(videoData, 'difficulty'),
				mediumDifficulty: videoData?.mediumDifficulty,
				bodyParts: get(videoData, 'bodyParts', []),
				calories: map(get(videoData, 'calories.items', []), (item, index) => ({
					key: index,
					weightFrom: get(item, 'from'),
					weightTo: get(item, 'to'),
					calories: get(item, 'value')
				})),
				order: get(videoData, 'order') || 1,
				publishDate: get(videoData, 'publishDate'),
				isPublished: get(videoData, 'isPublished'),
				isPreferredLiveStream: get(videoData, 'isPreferredLiveStream'),
				updatedAt: dayjs(videoData?.updatedAt).format('H:mm:ss D. MMM YYYY '),
				accessories: map(get(videoData, 'accessories'), (item) => ({ id: item.id, title: item.name })),
				targets: map(get(videoData, 'targets'), (item) => ({ id: item.id, title: get(item, 'translations.0.name') })),
				motivationAdvices: getValueObject(videoData?.motivationAdvice || [], 'text'),
				recommendedExercises: videoData?.recommendedExercises?.filter((item) => !!item).map((item) => ({ id: item.id, title: item.name })),
				recommendedBlogPosts: map(get(videoData, 'recommendedBlogPosts'), (item) => ({ id: item.id, title: item.title })),
				trainers: map(get(videoData, 'trainers'), (item) => ({ id: item.id, title: item.name }))
			}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`name_${item}`] = get(lang, 'name')
				langValues[`urlSlug_${item}`] = get(lang, 'urlSlug')
				langValues[`url_${item}`] = get(lang, 'url')
				langValues[`briefDescription_${item}`] = get(lang, 'briefDescription')
				langValues[`detailedDescription_${item}`] = get(lang, 'detailedDescription')
				langValues[`stickerText_${item}`] = get(lang, 'stickerText')
				langValues[`stickerColor_${item}`] = get(lang, 'stickerColor')
				langValues[`video_${item}`] = get(lang, 'video')
				langValues[`duration_${item}`] = lang?.durationSeconds ? lang.durationSeconds / 60 : 0
			})

			dispatch(
				initialize(FORMS.VIDEO_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch]
	)

	useEffect(() => {
		if (id) {
			dispatch(getVideo(id, (data) => initDetailForm(data)))
		} else {
			initEmptyDetailForm()
		}
	}, [computedMatch, id, initDetailForm, initEmptyDetailForm])

	useEffect(() => {
		if (get(generation, 'status') === GENERATE_STATE.CHECKING && generateAnimationRef.current === null) {
			generateAnimationRef.current = setInterval(() => {
				dispatch(checkGeneration(get(detail, 'data.video.id'), { thumbsetID: get(generation, 'pictureID') }))
			}, 5000)
		}
		if (get(generation, 'status') === GENERATE_STATE.SAVING) {
			clearInterval(generateAnimationRef.current)
			generateAnimationRef.current = null
			dispatch(
				saveAnimation(
					get(detail, 'data.video.id'),
					{
						thumbsetID: get(generation, 'pictureID')
					},
					() => {
						dispatch(
							getVideo(id, (data) => {
								initDetailForm(data)
							})
						)
						setThumbnailsChanged(true)
					}
				)
			)
		}
		return () => clearInterval(generateAnimationRef.current)
	}, [generation?.status])

	const handleCreate = (body: PostVideosBody) => dispatch(createVideo(body, (videoID) => history.push(`${t('paths:videoDefault|path')}/detail/${videoID}`)))

	const handleUpdate = (body: PutVideosIdBody) =>
		dispatch(
			updateVideo(id, body, () => {
				dispatch(getVideo(id, (data) => initDetailForm(data)))
				setThumbnailsChanged(false)
			})
		)

	const handleSubmit = (values: VideoFormValues) => {
		const body: any = {
			translations: [],
			language: get(values, 'language'),
			difficulty: get(values, 'difficulty'),
			mediumDifficulty: values?.difficulty === VIDEO_DIFFICULTY.MEDIUM ? (values?.mediumDifficulty ?? null) : null,
			order: get(values, 'order'),
			bodyParts: get(values, 'bodyParts'),
			calories: !isEmpty(get(values, 'calories'))
				? {
						items: map(get(values, 'calories'), (item) => ({
							from: get(item, 'weightFrom'),
							to: get(item, 'weightTo'),
							value: get(item, 'calories')
						}))
					}
				: null,
			image: get(values, 'image'),
			publishDate: get(values, 'publishDate', dayjs()),
			isPublished: get(values, 'isPublished'),
			isPreferredLiveStream: get(values, 'isPreferredLiveStream'),
			easyExerciseVariant: get(values, 'easyExerciseVariant[0].id'),
			hardExerciseVariant: get(values, 'hardExerciseVariant[0].id'),
			motivationAdvice: get(values, 'motivationAdvices[0].id'),
			bonusExercises: map(get(values, 'bonusExercises'), (item: any) => ({
				id: get(item, 'id'),
				title: get(item, 'translations.0.name'),
				translations: [
					{
						language: LANGUAGE.SK,
						label: get(item, 'labelSK')
					},
					{
						language: LANGUAGE.CZ,
						label: get(item, 'labelCZ')
					}
				]
			})),
			accessories: map(get(values, 'accessories'), (item: any) => item.id),
			programs: map(get(values, 'programs'), (item: any) => item.id),
			trainers: map(get(values, 'trainers'), (item: any) => item.id),
			upsellProducts: map(get(values, 'upsellProducts'), (item: any) => item.id),
			recommendedExercises: map(get(values, 'recommendedExercises'), (item: any) => item.id),
			recommendedBlogPosts: map(get(values, 'recommendedBlogPosts'), (item: any) => item.id),
			targets: map(get(values, 'targets'), (item: any) => item.id)
		}

		each(LANGUAGES, (item) => {
			const name = get(values, `name_${item}`)
			const urlSlug = get(values, `urlSlug_${item}`)
			const briefDescription = get(values, `briefDescription_${item}`)
			const detailedDescription = get(values, `detailedDescription_${item}`)
			const video = get(values, `url_${item}`)
			const duration = get(values, `duration_${item}`)

			if (name && urlSlug && briefDescription && detailedDescription && video && duration) {
				const translation: any = {
					name: get(values, `name_${item}`),
					urlSlug: get(values, `urlSlug_${item}`),
					url: get(values, `url_${item}`),
					language: item,
					video: get(values, `video_${item}`),
					durationSeconds: ceil(get(values, `duration_${item}` as any) * 60),
					briefDescription: get(values, `briefDescription_${item}`),
					detailedDescription: get(values, `detailedDescription_${item}`),
					stickerText: get(values, `stickerText_${item}`)
				}

				if (translation.stickerText) {
					translation.stickerColor = get(values, `stickerColor_${item}`) ? get(values, `stickerColor_${item}`) : '#FEA385'
				}
				body.translations.push(translation)
			}
		})

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	const handleGenerateThumbnail = async () => {
		await generateThumbnail(id)
		setThumbnailsChanged(true)
		setTimeout(() => {
			dispatch(getVideo(id, (data) => initDetailForm(data)))
		}, 500)
	}

	const handleSubmitGifGeneration = (values: ThumbnailGIFFormValues) => {
		setIsModalVisible(false)
		const submitValues = {
			start: get(values, 'start'),
			duration: get(values, 'duration')
		}

		dispatch(generateAnimation(id, submitValues, undefined, () => clearInterval(generateAnimationRef.current)))
	}

	const handleStopGenerating = () => {
		dispatch(stopGenerating())
		clearInterval(generateAnimationRef.current)
	}

	return (
		<>
			{get(generation, 'status') !== GENERATE_STATE.IDLE && <GeneratorOverlay status={get(generation, 'status')} discard={handleStopGenerating} />}
			<div className={'page-wrapper'}>
				<Spin spinning={isLoading}>
					<VideoForm
						onSubmit={handleSubmit}
						isCreate={!id}
						showModal={setIsModalVisible}
						generateThumbnail={handleGenerateThumbnail}
						thumbnailsChanged={thumbnailsChanged}
					/>
				</Spin>
			</div>
			<ThumbnailGIFForm onSubmit={handleSubmitGifGeneration} isVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
		</>
	)
}

export default VideoDetail
