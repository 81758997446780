import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import {
	GET_ACCESSORIES,
	DELETE_ACCESSORY,
	GET_ACCESSORY,
	CREATE_ACCESSORY,
	UPDATE_ACCESSORY,
	GetAccessoriesIdPayload,
	GetAccessoriesQueryParams,
	PostAccessoriesBody,
	PutAccessoriesIdBody
} from './types'

export const getAccessories = (params: GetAccessoriesQueryParams) => async (dispatch: any) => {
	dispatch({ type: GET_ACCESSORIES.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ACCESSORIES, normalizeQueryParams)

		dispatch({
			type: GET_ACCESSORIES.DONE,
			payload: {
				tableList: data.accessories,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_ACCESSORIES.FAILED })
		Promise.reject(error)
	}
}

export const getAccessory = (id: number, onSuccess?: (args: GetAccessoriesIdPayload) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: GET_ACCESSORY.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_ACCESSORY(id))
		dispatch({
			type: GET_ACCESSORY.DONE,
			payload: data
		})
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_ACCESSORY.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteAccessory = (id: number, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_ACCESSORY.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_ACCESSORY(id))
		dispatch({ type: DELETE_ACCESSORY.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_ACCESSORY.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createAccessory = (body: PostAccessoriesBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: CREATE_ACCESSORY.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => query || undefined)
		await postReq(ENDPOINTS.CREATE_ACCESSORY, undefined, normalizeQueryParams)
		dispatch({ type: CREATE_ACCESSORY.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: CREATE_ACCESSORY.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateAccessory = (id: number, body: PutAccessoriesIdBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_ACCESSORY.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => query || undefined)
		await putReq(ENDPOINTS.UPDATE_ACCESSORY(id), undefined, normalizeQueryParams)
		dispatch({ type: UPDATE_ACCESSORY.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_ACCESSORY.FAILED })
		return onFailure && onFailure(error)
	}
}
