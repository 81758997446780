// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetVideosPayload = RequestResponse<GetUrls['/admin/videos/']>['videos']
export type GetVideosIdPayload = RequestResponse<GetUrls['/admin/videos/{id}']>
export type GetAdminVideosSearchPayload = RequestResponse<GetUrls['/admin/videos/search']>['videos']

// query params
export type GetVideosQueryParams = RequestParams<GetUrls['/admin/videos/']>['query']
export type GetVideosIdAnimatedThumbnailsStatusQueryParams = RequestParams<GetUrls['/admin/videos/{id}/animatedThumbnails/status']>['query']

// bodies
export type PostVideosBody = RequestPayload<PostUrls['/admin/videos/']>
export type PostVideosIdAnimatedThumbnailsBody = RequestPayload<PostUrls['/admin/videos/{id}/animatedThumbnails']>
export type PutVideosIdBody = RequestPayload<PutUrls['/admin/videos/{id}']>
export type PutVideosIdAnimatedThumbnailsBody = RequestPayload<PutUrls['/admin/videos/{id}/animatedThumbnails']>

export enum GET_VIDEOS {
	START = 'GET_VIDEOS_START',
	DONE = 'GET_VIDEOS_DONE',
	FAILED = 'GET_VIDEOS_FAILED'
}

export enum GET_VIDEO {
	START = 'GET_VIDEO_START',
	DONE = 'GET_VIDEO_DONE',
	FAILED = 'GET_VIDEO_FAILED'
}

export enum UPDATE_VIDEO {
	START = 'UPDATE_VIDEO_START',
	DONE = 'UPDATE_VIDEO_DONE',
	FAILED = 'UPDATE_VIDEO_FAILED'
}

export enum CREATE_VIDEO {
	START = 'CREATE_VIDEO_START',
	DONE = 'CREATE_VIDEO_DONE',
	FAILED = 'CREATE_VIDEO_FAILED'
}

export enum DELETE_VIDEO {
	START = 'DELETE_VIDEO_START',
	DONE = 'DELETE_VIDEO_DONE',
	FAILED = 'DELETE_VIDEO_FAILED'
}

export enum ANIMATION_SAVE {
	START = 'ANIMATION_SAVE_START',
	DONE = 'ANIMATION_SAVE_DONE',
	FAILED = 'ANIMATION_SAVE_FAILED'
}

export enum CHECK_GENERATION {
	START = 'CHECK_GENERATION_START',
	DONE = 'CHECK_GENERATION_DONE',
	CHECKING = 'CHECK_GENERATION_CHECKING',
	FAILED = 'CHECK_GENERATION_FAILED'
}

export enum GENERATE_ANIMATION {
	START = 'DUPLICATE_EXERCISE_START',
	DONE = 'DUPLICATE_EXERCISE_DONE',
	STOP = 'DUPLICATE_EXERCISE_FAILED'
}
