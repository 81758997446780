// utils
import { GetUrls, RequestParams, RequestResponse } from '../../utils/request'

// payload
export type GetDashboardPayload = RequestResponse<GetUrls['/admin/dashboard/']>
export type GetStatisticsPayload = RequestResponse<GetUrls['/admin/statistics/']>['statistics']
export type GetSubscriptionStatisticsPayload = RequestResponse<GetUrls['/admin/statistics/subscriptions']>['subscriptionStatistics']
export type GetStatisticsProgramsEngagementsPayload = RequestResponse<GetUrls['/admin/statistics/programs/engagements']>['programsEngagementStatistics']
export type GetStatisticsProgramsEngagementsTrendsPayload = RequestResponse<
	GetUrls['/admin/statistics/programs/engagements/trends']
>['programEngagementTrendStatistics']
export type GetStatisticsExercisesViewsPayload = RequestResponse<GetUrls['/admin/statistics/exercises/views']>['exercisesViewStatistics']
export type GetStatisticsExercisesViewsTrendsPayload = RequestResponse<GetUrls['/admin/statistics/exercises/views/trends']>['exercisesViewsTrendStatistics']
export type GetStatisticsExercisesFavouritesPayload = RequestResponse<GetUrls['/admin/statistics/exercises/favourites']>['favouriteExercisesStatistics']
export type GetStatisticsPaymentsPayload = RequestResponse<GetUrls['/admin/statistics/payments']>
export type GetStatisticsNutritionPlansPayload = {
	basic: RequestResponse<GetUrls['/admin/statistics/nutrition-plans']>['nutritionPlanStatistics']['basic'] | null
	bonus: RequestResponse<GetUrls['/admin/statistics/nutrition-plans']>['nutritionPlanStatistics']['bonus'] | null
}

// query params
export type GetStatisticsQueryParams = RequestParams<GetUrls['/admin/statistics/']>['query']
export type GetStatisticsSubscriptionsQueryParams = RequestParams<GetUrls['/admin/statistics/subscriptions']>['query']
export type GetStatisticsProgramsEngagementsQueryParams = RequestParams<GetUrls['/admin/statistics/programs/engagements']>['query']
export type GetStatisticsProgramsEngagementsTrendsQueryParams = RequestParams<GetUrls['/admin/statistics/programs/engagements/trends']>['query']
export type GetStatisticsExercisesViewsQueryParams = RequestParams<GetUrls['/admin/statistics/exercises/views']>['query']
export type GetStatisticsExercisesViewsTrendsQueryParams = RequestParams<GetUrls['/admin/statistics/exercises/views/trends']>['query']
export type GetStatisticsExercisesFavouritesQueryParams = RequestParams<GetUrls['/admin/statistics/exercises/favourites']>['query']
export type GetStatisticsPaymentsQueryParams = RequestParams<GetUrls['/admin/statistics/payments']>['query']
export type GetStatisticsNutritionPlansQueryParams = RequestParams<GetUrls['/admin/statistics/nutrition-plans']>['query']

export enum GET_DASHBOARD {
	START = 'GET_DASHBOARD_START',
	DONE = 'GET_DASHBOARD_DONE',
	FAILED = 'GET_DASHBOARD_FAILED'
}

export enum GET_STATISTICS {
	START = 'GET_STATISTICS_START',
	DONE = 'GET_STATISTICS_DONE',
	FAILED = 'GET_STATISTICS_FAILED'
}

export enum GET_SUBSCRIPTION_STATISTICS {
	START = 'GET_SUBSCRIPTION_STATISTICS_START',
	DONE = 'GET_SUBSCRIPTION_STATISTICS_DONE',
	FAILED = 'GET_SUBSCRIPTION_STATISTICS_FAILED'
}

export enum GET_PROGRAMS_ENGAGEMENT_STATISTICS {
	START = 'GET_PROGRAMS_ENGAGEMENT_STATISTICS_START',
	DONE = 'GET_PROGRAMS_ENGAGEMENT_STATISTICS_DONE',
	FAILED = 'GET_PROGRAMS_ENGAGEMENT_STATISTICS_FAILED'
}

export enum GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS {
	START = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_START',
	DONE = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_DONE',
	FAILED = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_FAILED'
}

export enum GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY {
	START = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY_START',
	DONE = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY_DONE',
	FAILED = 'GET_PROGRAM_ENGAGEMENT_TREND_STATISTICS_PER_DAY_FAILED'
}

export enum GET_EXERCISES_VIEWS_STATISTICS {
	START = 'GET_EXERCISES_VIEWS_STATISTICS_START',
	DONE = 'GET_EXERCISES_VIEWS_STATISTICS_DONE',
	FAILED = 'GET_EXERCISES_VIEWS_STATISTICS_FAILED'
}

export enum GET_EXERCISES_VIEWS_TREND_STATISTICS {
	START = 'GET_EXERCISES_VIEWS_TREND_STATISTICS_START',
	DONE = 'GET_EXERCISES_VIEWS_TREND_STATISTICS_DONE',
	FAILED = 'GET_EXERCISES_VIEWS_TREND_STATISTICS_FAILED'
}

export enum GET_EXERCISES_FAVOURTITES_STATISTICS {
	START = 'GET_EXERCISES_FAVOURTITES_STATISTICS_START',
	DONE = 'GET_EXERCISES_FAVOURTITES_STATISTICS_DONE',
	FAILED = 'GET_EXERCISES_FAVOURTITES_STATISTICS_FAILED'
}

export enum GET_PAYMENTS_STATISTICS {
	START = 'GET_PAYMENTS_STATISTICS_START',
	DONE = 'GET_PAYMENTS_STATISTICS_DONE',
	FAILED = 'GET_PAYMENTS_STATISTICS_FAILED'
}

export enum GET_RECIPE_BOOKS_STATISTICS {
	START = 'GET_RECIPE_BOOKS_STATISTICS_START',
	FAILED = 'GET_RECIPE_BOOKS_STATISTICS_FAILED',
	DONE = 'GET_RECIPE_BOOKS_STATISTICS_DONE'
}
