import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, postReq } from '../../utils/request'

// types
import {
	GetSubscriptionsDonationsId,
	GET_DONATION,
	GET_DONATIONS,
	SEND_DONATION,
	GetSubscriptionsDonationsQueryParams,
	PostSubscriptionsDonationsIdBody
} from './types'

export const getDonations = (params: GetSubscriptionsDonationsQueryParams) => async (dispatch: any) => {
	dispatch({ type: GET_DONATIONS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_DONATIONS, normalizeQueryParams)

		dispatch({
			type: GET_DONATIONS.DONE,
			payload: {
				donations: data?.donations,
				context: data?.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_DONATIONS.FAILED })
		Promise.reject(error)
	}
}

export const getDonation = (id: number, onSuccess?: (args: GetSubscriptionsDonationsId) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: GET_DONATION.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_DONATION(id))
		dispatch({ type: GET_DONATION.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_DONATION.FAILED })
		return onFailure && onFailure(error)
	}
}

export const resendDonation =
	(id: number, body: PostSubscriptionsDonationsIdBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: SEND_DONATION.START })
		try {
			await postReq(ENDPOINTS.GET_DONATION(id), undefined, body)
			dispatch({ type: SEND_DONATION.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: SEND_DONATION.FAILED })
			return onFailure && onFailure(error)
		}
	}
