// utils
import { GetUrls, PostUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetSubscriptionsDonations = RequestResponse<GetUrls['/admin/subscriptions/donations']>['donations']
export type GetSubscriptionsDonationsId = RequestResponse<GetUrls['/admin/subscriptions/donations/{id}']>

// query params
export type GetSubscriptionsDonationsQueryParams = RequestParams<GetUrls['/admin/subscriptions/donations']>['query']

// bodies
export type PostSubscriptionsDonationsIdBody = RequestPayload<PostUrls['/admin/subscriptions/donations/{id}']>

export enum GET_DONATIONS {
	START = 'GET_DONATIONS_START',
	DONE = 'GET_DONATIONS_DONE',
	FAILED = 'GET_DONATIONS_FAILED'
}

export enum GET_DONATION {
	START = 'GET_DONATION_START',
	DONE = 'GET_DONATION_DONE',
	FAILED = 'GET_DONATION_FAILED'
}

export enum SEND_DONATION {
	START = 'SEND_DONATION_START',
	DONE = 'SEND_DONATION_DONE',
	FAILED = 'SEND_DONATION_FAILED'
}
