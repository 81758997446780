import { SET_FILTERS, RESET_FILTERS } from './types'

export const initState = {
	page: '',
	filters: {
		page: 1
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case RESET_FILTERS:
			return {
				...initState
			}
		case SET_FILTERS:
			return {
				...initState,
				...state,
				page: action.payload?.page,
				filters: action.payload?.filters
			}
		default:
			return state
	}
}
