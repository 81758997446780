import dayjs from 'dayjs'
import get from 'lodash/get'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, putReq, postReq, deleteReq } from '../../utils/request'

// types
import {
	GET_PAGE,
	GET_PAGES,
	UPDATE_PAGE,
	DELETE_PAGE,
	CREATE_PAGE,
	DUPLICATE_PAGE,
	GetPagesPageIdPayload,
	PostPagesPageIdDuplicatePayload,
	GetPagesQueryParams,
	PutPagesPageIdBody,
	PostPagesBody
} from './types'

export const getPages = (params: GetPagesQueryParams) => async (dispatch: any) => {
	dispatch({ type: GET_PAGES.START })
	try {
		const normalizeQueryParams = mapValues(params, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_PAGES, normalizeQueryParams)

		const tableList = map(data.pages, (item) => ({
			key: get(item, 'id'),
			title: get(item, 'title'),
			urlSlug: get(item, 'urlSlug'),
			isPublished: get(item, 'isPublished'),
			updatedAt: dayjs(get(item, 'updatedAt')),
			pageType: get(item, 'pageType'),
			language: get(item, 'language')
		}))

		dispatch({
			type: GET_PAGES.DONE,
			payload: {
				tableList,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_PAGES.FAILED })
		Promise.reject(error)
	}
}

export const getPage = (id: number, onSuccess?: (args: GetPagesPageIdPayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: GET_PAGE.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_PAGE(id))
		dispatch({ type: GET_PAGE.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_PAGE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createPage = (body: PostPagesBody, onSuccess?: (args: number) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: CREATE_PAGE.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => query || undefined)
		const { data } = await postReq(ENDPOINTS.GET_PAGES, undefined, normalizeQueryParams)
		dispatch({ type: CREATE_PAGE.DONE })
		return onSuccess && onSuccess(get(data, 'data.id'))
	} catch (error) {
		dispatch({ type: CREATE_PAGE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updatePage = (id: number, body: PutPagesPageIdBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_PAGE.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => {
			if (query === null) {
				return undefined
			}

			return query
		})

		await putReq(ENDPOINTS.UPDATE_PAGE(id), undefined, normalizeQueryParams)
		dispatch({ type: UPDATE_PAGE.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_PAGE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deletePage = (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_PAGE.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_PAGE(id))
		dispatch({ type: DELETE_PAGE.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_PAGE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const duplicatePage =
	(id: number, onSuccess?: (args: PostPagesPageIdDuplicatePayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: DUPLICATE_PAGE.START })
		try {
			const { data } = await postReq(ENDPOINTS.DUPLICATE_PAGE(id))
			dispatch({ type: DUPLICATE_PAGE.DONE })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: DUPLICATE_PAGE.FAILED })
			return onFailure && onFailure(error)
		}
	}
