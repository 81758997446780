// utils
import { GetUrls, PatchUrls, PostUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetPushNotificationsPayload = RequestResponse<GetUrls['/admin/push-notifications/']>['notifications']
export type GetPushNotificationsIdPayload = RequestResponse<GetUrls['/admin/push-notifications/{id}']>

// query params
export type GetPushNotificationsQueryParams = RequestParams<GetUrls['/admin/push-notifications/']>['query']
export type GetStatisticsNotificationsQueryParams = RequestParams<GetUrls['/admin/statistics/notifications']>['query']

// bodies
export type PostPushNotificationsBody = RequestPayload<PostUrls['/admin/push-notifications/']>
export type PatchPushNotificationsIdBody = RequestPayload<PatchUrls['/admin/push-notifications/{id}']>

export enum GET_PUSH_NOTIFICATIONS {
	START = 'GET_PUSH_NOTIFICATIONS_START',
	DONE = 'GET_PUSH_NOTIFICATIONS_DONE',
	FAILED = 'GET_PUSH_NOTIFICATIONS_FAILED'
}

export enum GET_PUSH_NOTIFICATION {
	START = 'GET_PUSH_NOTIFICATION_START',
	DONE = 'GET_PUSH_NOTIFICATION_DONE',
	FAILED = 'GET_PUSH_NOTIFICATION_FAILED'
}

export enum CREATE_PUSH_NOTIFICATION {
	START = 'CREATE_PUSH_NOTIFICATION_START',
	DONE = 'CREATE_PUSH_NOTIFICATION_DONE',
	FAILED = 'CREATE_PUSH_NOTIFICATION_FAILED'
}

export enum UPDATE_PUSH_NOTIFICATION {
	START = 'UPDATE_PUSH_NOTIFICATION_START',
	DONE = 'UPDATE_PUSH_NOTIFICATION_DONE',
	FAILED = 'UPDATE_PUSH_NOTIFICATION_FAILED'
}

export enum DELETE_PUSH_NOTIFICATION {
	START = 'DELETE_PUSH_NOTIFICATION_START',
	DONE = 'DELETE_PUSH_NOTIFICATION_DONE',
	FAILED = 'DELETE_PUSH_NOTIFICATION_FAILED'
}

export enum GET_PUSH_NOTIFICATIONS_STATS {
	START = 'GET_PUSH_NOTIFICATIONS_STATS_START',
	DONE = 'GET_PUSH_NOTIFICATIONS_STATS_DONE',
	FAILED = 'GET_PUSH_NOTIFICATIONS_STATS_FAILED'
}
