import React, { useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { getPaymentDetail, refundPayment, deactivateSMSCode } from '../../redux/payments/actions'

// utils
import { EMPTY_VALUE, FORMS, PAYMENT_CURRENCY_LABEL, PAYMENT_METHOD } from '../../utils/enums'

import {
	convertCentsToEuros,
	formatPaymentMethod,
	formatPaymentState,
	formatPaymentType,
	formatSubscriptionType,
	formatPromoCodeType
} from '../../utils/helpers'

// types
import { IComputedMatch } from '../../types/interfaces'
import { GetPaymentsIdPayload } from '../../redux/payments/types'

// form
import PaymentsForm from './forms/PaymentsForm'

type DetailPaymentsProps = {
	computedMatch: IComputedMatch
}

const DetailPayments = ({ computedMatch }: DetailPaymentsProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const detail = useSelector((state: RootState) => state.payments?.detail)

	// constants
	const { id } = computedMatch.params
	const isLoading = detail?.isLoading

	const initDetailForm = useCallback(
		(data: GetPaymentsIdPayload) => {
			//
			const trustPayBank = {
				dataAccountId: get(data, 'data.AccountId'),
				dataAmount: get(data, 'data.Amount'),
				dataCounterAccount: get(data, 'data.CounterAccount'),
				dataCounterAccountName: get(data, 'data.CounterAccountName'),
				dataCurrency: PAYMENT_CURRENCY_LABEL[get(data, 'data.Currency')],
				dataPaymentId: get(data, 'data.PaymentId'),
				dataReference: get(data, 'data.Reference'),
				dataResultCode: get(data, 'data.ResultCode'),
				dataAcquirerResponseId: get(data, 'data.AcquirerResponseId'),
				dataSignature: get(data, 'data.Signature'),
				dataType: get(data, 'data.Type')
			}

			const trustPayCard = {
				cardExpiration: get(data, 'data.CardExpiration'),
				cardId: get(data, 'data.CardID'),
				cardMask: get(data, 'data.CardMask'),
				cardRecTxSec: get(data, 'data.CardRecTxSec'),
				cardAuthNumber: get(data, 'data.AuthNumber'),
				cardAcqResId: get(data, 'data.AcqResId')
			}

			const paypal = {
				paypalCreateTime: dayjs(get(data, 'data.create_time')).format('YYYY.MM.DD HH:MM'),
				paypalEventType: get(data, 'data.event_type'),
				paypalSummary: get(data, 'data.summary'),
				paypalId: get(data, 'data.id'),
				paypalPayerID: get(data, 'data.PayerID'),
				paypalPaymentId: get(data, 'data.paymentId'),
				paypalToken: get(data, 'data.paymentId'),
				paypalData: get(data, 'data')
			}

			let initValues: any = {
				id:
					(get(data, 'paymentMethod') === PAYMENT_METHOD.IOS_IN_APP_PURCHASE || get(data, 'paymentMethod') === PAYMENT_METHOD.ANDROID_IN_APP_PURCHASE
						? get(data, 'paymentID')
						: get(data, 'id')) || EMPTY_VALUE,
				contactEmail: get(data, 'contactEmail'),
				registerEmail: get(data, 'registerEmail'),
				// eslint-disable-next-line
				// @ts-expect-error
				currency: PAYMENT_CURRENCY_LABEL[data?.currency] as PAYMENT_CURRENCY_LABEL,
				invoiceNumber: get(data, 'invoiceNumber'),
				paymentMethod: formatPaymentMethod(get(data, 'paymentMethod')) || EMPTY_VALUE,
				paymentMethodRaw: get(data, 'paymentMethod'),
				paymentState: formatPaymentState(get(data, 'paymentState'), get(data, 'isRefunded'), get(data, 'paymentType')),
				paymentType: formatPaymentType(get(data, 'paymentType'), get(data, 'subscriptionType')),
				price: convertCentsToEuros(get(data, 'price')),
				subscriptionType: formatSubscriptionType(get(data, 'subscriptionType'), get(data, 'paymentType')),
				createdAt: get(data, 'createdAt') && (get(data, 'createdAt') as any) !== 0 ? dayjs(data.createdAt).format('H:mm:ss D. MMM YYYY ') : EMPTY_VALUE,
				processedAt:
					get(data, 'processedAt') && (get(data, 'processedAt') as any) !== 0 ? dayjs(data.processedAt).format('H:mm:ss D. MMM YYYY ') : EMPTY_VALUE,
				discount: get(data, 'discount'),
				promoCode: get(data, 'promoCode.code'),
				promoCodeType: formatPromoCodeType(get(data, 'promoCode.type') as any),
				subscriptionDonation: get(data, 'subscriptionDonation') ? t('Áno') : t('Nie'),
				note: get(data, 'note', null),
				phoneNumber: get(data, 'phoneNumber') ? `+${get(data, 'phoneNumber', '')}` : '',
				data: get(data, 'data'),
				smsCode: get(data, 'smsCode')
			}

			switch (get(data, 'paymentMethod')) {
				case PAYMENT_METHOD.TRUSTPAY_BANK:
					initValues = { ...initValues, ...trustPayBank }
					break
				case PAYMENT_METHOD.TRUSTPAY_CARD:
					initValues = { ...initValues, ...trustPayBank, ...trustPayCard }
					break
				case PAYMENT_METHOD.SMS:
					initValues = { ...initValues, smsNotifications: get(data, 'smsNotifications') }
					break
				case PAYMENT_METHOD.PAYPAL:
					initValues = { ...initValues, ...paypal }
					break
				case PAYMENT_METHOD.BANK_TRANSACTION:
					initValues = { ...initValues, data: get(data, 'data') }
					break
				default:
					initValues = { ...initValues, data: get(data, 'data') }
			}

			dispatch(initialize(FORMS.PAYMENT_FORM, initValues))
		},
		[dispatch, t]
	)

	const handleRefund = () => {
		dispatch(
			refundPayment(id, () => {
				dispatch(
					getPaymentDetail(id, (data) => {
						initDetailForm(data)
					})
				)
			})
		)
	}

	const handleSMSCodeDeactivate = (code: string) => {
		dispatch(
			deactivateSMSCode(code, () => {
				dispatch(getPaymentDetail(id, (data) => initDetailForm(data)))
			})
		)
	}

	useEffect(() => {
		dispatch(getPaymentDetail(id, (data) => initDetailForm(data)))
	}, [dispatch, id, initDetailForm])

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<PaymentsForm detail={detail} handleRefund={handleRefund} handleSMSCodeDeactivate={handleSMSCodeDeactivate} />
			</Spin>
		</div>
	)
}

export default DetailPayments
