// utils
import { GetUrls, PostUrls, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetUsersUserIdNutritionPlansPayload = RequestResponse<GetUrls['/admin/users/{userID}/nutrition-plans/']>

// bodies
export type PostUsersUserIdNutritionPlansStandardOrderBody = RequestPayload<PostUrls['/admin/users/{userID}/nutrition-plans/standard-order']>
export type PostUsersUserIdNutritionPlansBonusOrderBody = RequestPayload<PostUrls['/admin/users/{userID}/nutrition-plans/bonus-order']>

export enum GET_RECIPE_BOOKS {
	START = 'GET_RECIPE_BOOKS_START',
	DONE = 'GET_RECIPE_BOOKS_DONE',
	FAILED = 'GET_RECIPE_BOOKS_FAILED'
}

export enum SEND_ORDER_STANDARD_RECIPE_BOOK {
	START = 'SEND_ORDER_STANDARD_RECIPE_BOOK_START',
	DONE = 'SEND_ORDER_STANDARD_RECIPE_BOOK_DONE',
	FAILED = 'SEND_ORDER_STANDARD_RECIPE_BOOK_FAILED'
}

export enum SEND_ORDER_BONUS_RECIPE_BOOK {
	START = 'SEND_ORDER_BONUS_RECIPE_BOOK_START',
	DONE = 'SEND_ORDER_BONUS_RECIPE_BOOK_DONE',
	FAILED = 'SEND_ORDER_BONUS_RECIPE_BOOK_FAILED'
}
