import dayjs from 'dayjs'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import {
	GET_BLOG_CATEGORIES,
	GET_BLOG_CATEGORY,
	DELETE_BLOG_CATEGORY,
	UPDATE_BLOG_CATEGORY,
	CREATE_BLOG_CATEGORY,
	GetBlogCategoriesIdPayload,
	GetBlogCategoriesQueryParams,
	PostBlogCategoriesBody
} from './types'

export const getBlogCategories = (params: GetBlogCategoriesQueryParams) => async (dispatch: any) => {
	dispatch({ type: GET_BLOG_CATEGORIES.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_BLOG_CATEGORIES, normalizeQueryParams)

		const tableList = map(data.blogCategories, (item) => ({
			key: item?.id,
			name: item?.name || '',
			parentName: item?.parent?.name || '',
			order: item?.order || '',
			updatedAt: dayjs(item?.updatedAt).format('D. MMM YYYY HH:mm')
		}))

		dispatch({
			type: GET_BLOG_CATEGORIES.DONE,
			payload: {
				tableList,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_BLOG_CATEGORIES.FAILED })
		Promise.reject(error)
	}
}

export const getBlogCategory =
	(id: number, onSuccess?: (args: GetBlogCategoriesIdPayload) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: GET_BLOG_CATEGORY.START })
		try {
			const { data } = await getReq(ENDPOINTS.GET_ADMIN_BLOG_CATEGORY(id))
			dispatch({ type: GET_BLOG_CATEGORY.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_BLOG_CATEGORY.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const deleteBlogCategory = (id: number, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_BLOG_CATEGORY.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_ADMIN_BLOG_CATEGORY(id))
		dispatch({ type: DELETE_BLOG_CATEGORY.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_BLOG_CATEGORY.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateBlogCategory = (id: number, body: any, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_BLOG_CATEGORY.START })
	try {
		const normalizeQueryParams = Object.keys(body || {}).reduce((acc, cv) => {
			if (cv === 'parentCategoryID') {
				return {
					...acc,
					[cv]: body[cv] || null
				}
			}
			return {
				...acc,
				[cv]: body[cv] || undefined
			}
		}, {})

		await putReq(ENDPOINTS.UPDATE_ADMIN_BLOG_CATEGORY(id), undefined, normalizeQueryParams)
		dispatch({ type: UPDATE_BLOG_CATEGORY.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_BLOG_CATEGORY.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createBlogCategory = (body: PostBlogCategoriesBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: CREATE_BLOG_CATEGORY.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => query || undefined)
		await postReq(ENDPOINTS.CREATE_ADMIN_BLOG_CATEGORY, undefined, normalizeQueryParams)
		dispatch({ type: CREATE_BLOG_CATEGORY.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: CREATE_BLOG_CATEGORY.FAILED })
		return onFailure && onFailure(error)
	}
}
