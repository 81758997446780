import ceil from 'lodash/ceil'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, patchReq } from '../../utils/request'

// types
import { GET_TRANSLATIONS, GetTranslationsQueryParams, PatchTranslationsBody, UPDATE_TRANSLATION } from './types'

export const getTranslations = (params: GetTranslationsQueryParams) => async (dispatch: any) => {
	dispatch({ type: GET_TRANSLATIONS.START })

	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_TRANSLATIONS, normalizeQueryParams)

		const totalPages = ceil(get(data, 'context.totalCount', 0) / get(data, 'context.count', 0))
		const isLastPage = totalPages === get(data, 'context.page', 0)

		dispatch({
			type: GET_TRANSLATIONS.DONE,
			payload: {
				rawList: data.translations,
				context: data.context,
				isLastPage
			}
		})
	} catch (error) {
		dispatch({ type: GET_TRANSLATIONS.FAILED })
		Promise.reject(error)
	}
}

export const updateTranslations = (body: PatchTranslationsBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_TRANSLATION.START })
	try {
		await patchReq(ENDPOINTS.UPDATE_TRANSLATION, undefined, body)
		dispatch({ type: UPDATE_TRANSLATION.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_TRANSLATION.FAILED })
		return onFailure && onFailure(error)
	}
}
