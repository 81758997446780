// types
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetTrainersPayload = RequestResponse<GetUrls['/admin/trainers/']>['trainers']
export type GetTrainersIdPayload = RequestResponse<GetUrls['/admin/trainers/{id}']>

// query params
export type GetTrainersQueryParams = RequestParams<GetUrls['/admin/trainers/']>['query']

// bodies
export type PostTrainersBody = RequestPayload<PostUrls['/admin/trainers/']>
export type PutTrainersIdBody = RequestPayload<PutUrls['/admin/trainers/{id}']>

export enum GET_INSTRUCTORS {
	START = 'GET_INSTRUCTORS_START',
	DONE = 'GET_INSTRUCTORS_DONE',
	FAILED = 'GET_INSTRUCTORS_FAILED'
}

export enum GET_INSTRUCTOR {
	START = 'GET_INSTRUCTOR_START',
	DONE = 'GET_INSTRUCTOR_DONE',
	FAILED = 'GET_INSTRUCTOR_FAILED'
}

export enum DELETE_INSTRUCTOR {
	START = 'DELETE_INSTRUCTOR_START',
	DONE = 'DELETE_INSTRUCTOR_DONE',
	FAILED = 'DELETE_INSTRUCTOR_FAILED'
}

export enum CREATE_INSTRUCTOR {
	START = 'CREATE_INSTRUCTOR_START',
	DONE = 'CREATE_INSTRUCTOR_DONE',
	FAILED = 'CREATE_INSTRUCTOR_FAILED'
}

export enum UPDATE_INSTRUCTOR {
	START = 'UPDATE_INSTRUCTOR_START',
	DONE = 'UPDATE_INSTRUCTOR_DONE',
	FAILED = 'UPDATE_INSTRUCTOR_FAILED'
}
