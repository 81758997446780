import i18next from 'i18next'
import { FormErrors } from 'redux-form'

// utils
import { LANGUAGE } from '../../../utils/enums'
import { urlRegEx } from '../../../utils/regex'

export type DownloadFormValues = {
	id: number
	title: string
	description: string
	category: number
	language: LANGUAGE
	file?: string
	url?: string
	image: string
	imageTitle?: string
	imageAlt?: string
}

export default (values: DownloadFormValues): FormErrors<DownloadFormValues> => {
	const errors: FormErrors<DownloadFormValues> = {}

	if (!values.title) {
		errors.title = i18next.t('Toto pole je povinné')
	}

	if (!values.description) {
		errors.description = i18next.t('Toto pole je povinné')
	}

	if (!values.category) {
		errors.category = i18next.t('Toto pole je povinné')
	}

	if (!values.language) {
		errors.language = i18next.t('Toto pole je povinné')
	}

	if (!values.file && !values.url) {
		errors.file = i18next.t('Pole Súbor alebo URL je povinné')
	}

	if (values.url && !urlRegEx.test(values.url)) {
		errors.url = i18next.t('Neplatná URL')
	}

	if (!values.image) {
		errors.image = i18next.t('Toto pole je povinné')
	}

	if (!values.imageTitle) {
		errors.imageTitle = i18next.t('Toto pole je povinné')
	}

	if (!values.imageAlt) {
		errors.imageAlt = i18next.t('Toto pole je povinné')
	}

	return errors
}
