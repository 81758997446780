import { Modal } from 'antd'
import { initialize, submit } from 'redux-form'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// utils
import { useCallback, useEffect } from 'react'
import { FORMS } from '../../../utils/enums'

// types
import { CustomPaymentFormValues } from '../forms/validateCustomPaymentForm'

// forms
import CustomPaymentForm from '../forms/CustomPaymentForm'

type CustomPaymentModalProps = {
	open: boolean
	setShowModal: (value: boolean) => void
	submitPayment: (values: CustomPaymentFormValues) => void
}

const CustomPaymentModal = ({ open, setShowModal, submitPayment }: CustomPaymentModalProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {
			sendEmail: false
		}
		dispatch(initialize(FORMS.CUSTOM_PAYMENT_FORM, initValues))
	}, [dispatch])

	useEffect(() => {
		initEmptyDetailForm()
	}, [dispatch])

	return (
		<Modal
			open={open}
			title={t('Vytvoriť platbu')}
			okText={t('Vytvoriť')}
			cancelText={t('Zrušiť')}
			onCancel={() => setShowModal(false)}
			onOk={() => dispatch(submit(FORMS.CUSTOM_PAYMENT_FORM))}
		>
			<CustomPaymentForm onSubmit={submitPayment} />
		</Modal>
	)
}

export default CustomPaymentModal
