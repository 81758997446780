import { mapValues } from 'lodash'
import * as ACTIONS from './types'
import { getReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'

interface IParams {
	search?: string
	page?: number
	limit?: number
}

const queries = (params?: any) => ({
	limit: 20,
	page: 1,
	...params
})

const getList = (endpoint: string, params?: IParams) => async (dispatch: any) => {
	try {
		dispatch({ type: ACTIONS.GET_LIST.START })

		const normalizeQueryParams = mapValues(queries(params), (query) => query || undefined)
		const { data } = await getReq(endpoint, normalizeQueryParams)

		dispatch({
			type: ACTIONS.GET_LIST.DONE,
			payload: data
		})

		return true
	} catch (error) {
		dispatch({ type: ACTIONS.GET_LIST.FAILED })
		return false
	}
}

export const getExerciseList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_EXERCISES, params)
export const getTargetsList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_TARGETS, params)
export const getMotivationAdviceList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_MOTIVATION_ADVICE, params)
export const getAccessorieList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_ACCESSORIES, params)
export const getLivestreamCategoriesList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_LIVESTREAM_CATEGORIES, params)
export const getTrainerList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_TRAINERS, params)
export const getProgramList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_PROGRAMS, params)
export const getBlogPostList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_BLOG_POSTS, params)
export const getBlogCategoriesList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_BLOG_CATEGORIES, params)
export const getPreferenceList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_PREFFERENCES, params)
export const getEshopProductList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_ESHOP_PRODUCTS, params)
export const getVideosList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_VIDEOS, params)
export const getGiftProductsList = (params?: IParams) => getList(ENDPOINTS.LIST_GET_GIFT_PRODUCTS, params)
