import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { createBanner, getBanner, getBanners, updateBanner } from '../../redux/banners/actions'
import { RootState } from '../../redux'

// utils
import { BANNER_TYPE, FORMS, LANGUAGES } from '../../utils/enums'

// types

// forms
import BannerForm from './forms/BannerForm'
import { IComputedMatch } from '../../types/interfaces'
import { PostHeadlinesBody, PutHeadlinesIdBody } from '../../redux/banners/types'

type DetailBannerProps = {
	computedMatch: IComputedMatch
}

const DetailBanner = ({ computedMatch }: DetailBannerProps) => {
	const { t } = useTranslation()
	const history = useHistory()
	const dispatch = useDispatch()
	const { id } = computedMatch.params

	// selectors
	const detail = useSelector((state: RootState) => state.accessories?.detail)
	const isLoading = detail?.isLoading

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {
			order: 1
		}

		dispatch(initialize(FORMS.BANNER_FORM, initValues))
	}, [dispatch])

	const initDetailForm = useCallback(
		(data: any) => {
			const langData = data?.translations

			const initValues = {
				...data,
				bannerType: data?.types || BANNER_TYPE.ESHOP_SLIDER,
				updatedAt: dayjs(data.updatedAt).format('H:mm:ss D. MMM YYYY '),
				premiumAudience: data?.premiumAudience,
				nonPremiumAudience: data?.nonPremiumAudience
			}

			const langValues = {} as any

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`title_${item}`] = get(lang, 'title')
				langValues[`shortTitle_${item}`] = get(lang, 'shortTitle')
				langValues[`image_${item}`] = get(lang, 'image')
				langValues[`buttonText_${item}`] = get(lang, 'buttonText')
				langValues[`subTitle_${item}`] = get(lang, 'subTitle')
				langValues[`buttonLink_${item}`] = get(lang, 'buttonLink')
			})

			dispatch(
				initialize(FORMS.BANNER_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch]
	)

	const getAllLists = useCallback(() => {
		dispatch(getBanners())
	}, [dispatch])

	useEffect(() => {
		if (id) {
			dispatch(
				getBanner(id, (data) => {
					initDetailForm(data)
				})
			)
		} else {
			initEmptyDetailForm()
		}
		getAllLists()
	}, [dispatch, getAllLists, id, initDetailForm, initEmptyDetailForm])

	const handleCreate = (body: PostHeadlinesBody) => dispatch(createBanner(body, () => history.push(t('paths:banners|path'))))

	const handleUpdate = (body: PutHeadlinesIdBody) =>
		dispatch(
			updateBanner(id, body, () => {
				dispatch(
					getBanner(id, (data) => {
						initDetailForm(data)
					})
				)
			})
		)

	const handleSubmit = (values: any) => {
		const body: any = {
			name: values?.name,
			urlSlug: values?.urlSlug,
			translations: [],
			types: values?.bannerType,
			order: values?.order || 1,
			image: values?.image,
			isVisible: values?.isVisible || false,
			premiumAudience: values?.premiumAudience || false,
			nonPremiumAudience: values?.nonPremiumAudience || false
		}

		each(LANGUAGES, (item) => {
			const title =
				get(values, 'bannerType')[0] === BANNER_TYPE.ESHOP_SLIDER
					? get(values, `title_${item}`, get(values, `subTitle_${item}`))
					: get(values, `subTitle_${item}`)
			const shortTitle = get(values, `shortTitle_${item}`)
			const subTitle = get(values, `subTitle_${item}`)
			const buttonText = get(values, `buttonText_${item}`)
			const buttonLink = get(values, `buttonLink_${item}`)
			const transalteImage = get(values, `image_${item}`)

			if (title) {
				const translation = {
					language: item,
					image: transalteImage || undefined,
					title: title && title.length > 0 ? title : undefined,
					shortTitle: shortTitle && shortTitle.length > 0 ? shortTitle : undefined,
					subTitle: subTitle && subTitle.length > 0 ? subTitle : undefined,
					buttonText: buttonText && buttonText.length > 0 ? buttonText : undefined,
					buttonLink: buttonLink && buttonLink.length > 0 ? buttonLink : undefined
				}

				body.translations.push(translation)
			}
		})
		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<BannerForm onSubmit={handleSubmit} isCreate={!id} />
			</Spin>
		</div>
	)
}

export default DetailBanner
