import { useEffect, useState } from 'react'
import get from 'lodash/get'
import i18next from 'i18next'
import { Button, Empty, Popconfirm, Table } from 'antd'
import { DeleteOutlined, LinkOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { deleteBlogCategory, getBlogCategories } from '../../redux/blogCategories/actions'
import { setFilters } from '../../redux/filters/actions'

// utils
import { EMPTY_VALUE } from '../../utils/enums'
import { setFiltersForPage } from '../../utils/helpers'

// types
import { GetBlogCategoriesPayload } from '../../redux/blogCategories/types'

// atoms
import OrderRepresent from '../../atoms/OrderRepresent'

const PAGE_SIZE = 20

const BlogCategories = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const dataSource = useSelector((state: RootState) => state.blogCategories?.list?.tableList)
	const isLoadingList = useSelector((state: RootState) => state.blogCategories?.list?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.blogCategories?.detail?.isLoading)
	const context = useSelector((state: RootState) => state.blogCategories?.list?.context)

	// states
	const [filter, setFilter] = useState(setFiltersForPage(t('paths:blogCategories|key'), persistFilter))

	// constants
	const columns = [
		{
			title: i18next.t('Názov'),
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			sorter: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Nadradená kategória'),
			dataIndex: 'parentName',
			key: 'parentName',
			ellipsis: true,
			sorter: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Poradie'),
			dataIndex: 'order',
			sorter: true,
			key: 'order',
			align: 'center',
			width: 60,
			render: (value: number) => <OrderRepresent value={value} tooltip={t('Poradie')} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (_: any, record: GetBlogCategoriesPayload[0]) => (
				<>
					<span style={{ marginRight: '5px' }}>
						<Link to={`${t('paths:blogCategoryDetail|path')}/${get(record, 'key')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					<Popconfirm
						title={t('Skutočne chcete vymazať záznam?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						cancelText={t('Zrušiť')}
						okText={t('Vymazať')}
						onConfirm={(e: any) => {
							e.stopPropagation()
							dispatch(
								deleteBlogCategory(record?.id, () => {
									const body = {
										limit: PAGE_SIZE,
										page: 1,
										...filter
									}
									dispatch(getBlogCategories(body))
									dispatch(setFilters(t('paths:blogCategories|key'), body))
								})
							)
						}}
						onCancel={(e: any) => e.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary',
							danger: true
						}}
						cancelButtonProps={{
							size: 'small',
							type: 'ghost'
						}}
					>
						<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
					</Popconfirm>
				</>
			)
		}
	]

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getBlogCategories(body))

		dispatch(setFilters(t('paths:blogCategories|key'), body))
	}, [filter, dispatch, t])

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-end'}>
				<Button icon={<PlusCircleOutlined />} href={t('paths:blogCategoryCreate|path')} type={'primary'}>
					{t('Pridať kategóriu')}
				</Button>
			</div>
			<Table
				className={'general-table'}
				columns={columns as any}
				dataSource={dataSource}
				onChange={handleTableChange}
				style={{ marginTop: 20 }}
				scroll={{ x: 'max-content' }}
				showSorterTooltip={false}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingList || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:blogCategoryDetail|path')}/${get(record, 'key')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

export default BlogCategories
