// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, putReq } from '../../utils/request'

// types
import { GET_SETTINGS, PutSettingsPricelistBody, PutSettingsPricelistPayload, UPDATE_SETTINGS } from './settingsTypes'

export const getSettings = (onSuccess?: (args: any) => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: GET_SETTINGS.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_SETTINGS)
		dispatch({ type: GET_SETTINGS.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_SETTINGS.FAILED })
		return onFailure && onFailure()
	}
}

export const updatePriceList =
	(body: PutSettingsPricelistBody, onSuccess?: (args: PutSettingsPricelistPayload) => void, onFailure?: () => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_SETTINGS.START })

		try {
			const { data } = await putReq(ENDPOINTS.UPDATE_PRICELIST, undefined, body)
			dispatch({ type: UPDATE_SETTINGS.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: UPDATE_SETTINGS.FAILED })
			return onFailure && onFailure()
		}
	}
