// utils
import { PutUrls, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type PutSettingsPricelistPayload = RequestResponse<PutUrls['/admin/settings/pricelist']>

// bodies
export type PutSettingsPricelistBody = RequestPayload<PutUrls['/admin/settings/pricelist']>

export enum GET_SETTINGS {
	START = 'GET_SETTINGS_START',
	DONE = 'GET_SETTINGS_DONE',
	FAILED = 'GET_SETTINGS_FAILED'
}

export enum UPDATE_SETTINGS {
	START = 'UPDATE_SETTINGS_START',
	DONE = 'UPDATE_SETTINGS_DONE',
	FAILED = 'UPDATE_SETTINGS_FAILED'
}
