// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetBlogPostsPayload = RequestResponse<GetUrls['/admin/blog-posts/']>['blogPosts']
export type GetBlogPostsIdPayload = RequestResponse<GetUrls['/admin/blog-posts/{id}']>
export type PostBlogPostsIdDuplicatePayload = RequestResponse<PostUrls['/admin/blog-posts/{id}/duplicate']>
export type PostBlogPostsIdRestorePayload = RequestResponse<PostUrls['/admin/blog-posts/{id}/restore']>

// query params
export type GetBlogPostsQueryParams = RequestParams<GetUrls['/admin/blog-posts/']>['query']

// bodies
export type PostBlogPostsBody = RequestPayload<PostUrls['/admin/blog-posts/']>
export type PutBlogPostsIdBody = RequestPayload<PutUrls['/admin/blog-posts/{id}']>

export enum GET_BLOG_POSTS {
	START = 'GET_BLOG_POSTS_START',
	DONE = 'GET_BLOG_POSTS_DONE',
	FAILED = 'GET_BLOG_POSTS_FAILED'
}

export enum GET_BLOG_RECIPES {
	START = 'GET_BLOG_RECIPES_START',
	DONE = 'GET_BLOG_RECIPES_DONE',
	FAILED = 'GET_BLOG_RECIPES_FAILED'
}

export enum GET_BLOG_POST {
	START = 'GET_BLOG_POST_START',
	DONE = 'GET_BLOG_POST_DONE',
	FAILED = 'GET_BLOG_POST_FAILED'
}

export enum DELETE_BLOG_POST {
	START = 'DELETE_BLOG_POST_START',
	DONE = 'DELETE_BLOG_POST_DONE',
	FAILED = 'DELETE_BLOG_POST_FAILED'
}

export enum DUPLICATE_BLOG_POST {
	START = 'DUPLICATE_BLOG_POST_START',
	DONE = 'DUPLICATE_BLOG_POST_DONE',
	FAILED = 'DUPLICATE_BLOG_POST_FAILED'
}

export enum RESTORE_BLOG_POST {
	START = 'RESTORE_BLOG_POST_START',
	DONE = 'RESTORE_BLOG_POST_DONE',
	FAILED = 'RESTORE_BLOG_POST_FAILED'
}

export enum CREATE_BLOG_POST {
	START = 'CREATE_BLOG_POST_START',
	DONE = 'CREATE_BLOG_POST_DONE',
	FAILED = 'CREATE_BLOG_POST_FAILED'
}

export enum UPDATE_BLOG_POST {
	START = 'UPDATE_BLOG_POST_START',
	DONE = 'UPDATE_BLOG_POST_DONE',
	FAILED = 'UPDATE_BLOG_POST_FAILED'
}
