import dayjs from 'dayjs'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import {
	GET_EXERCISE_TARGETS,
	GET_EXERCISE_TARGET,
	DELETE_EXERCISE_TARGET,
	CREATE_EXERCISE_TARGET,
	UPDATE_EXERCISE_TARGET,
	GetTargetIdPayload,
	GetTargetQueryParams,
	PostTargetBody,
	PutTargetIdBody
} from './types'

export const getExerciseTargets = (params: GetTargetQueryParams) => async (dispatch: any) => {
	dispatch({ type: GET_EXERCISE_TARGETS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_EXERCISE_TARGETS, normalizeQueryParams)

		const tableList = map(data.targets, (item) => ({
			key: item?.id,
			name: item?.name,
			createdAt: dayjs(item?.createdAt),
			updatedAt: dayjs(item?.updatedAt),
			nameSlug: item?.nameSlug
		}))

		dispatch({
			type: GET_EXERCISE_TARGETS.DONE,
			payload: {
				tableList,
				filterOptions: data.filterOptions,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_EXERCISE_TARGETS.FAILED })
		Promise.reject(error)
	}
}

export const getExerciseTarget = (id: number, onSuccess?: (args: GetTargetIdPayload) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: GET_EXERCISE_TARGET.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_EXERCISE_TARGET(id))
		dispatch({ type: GET_EXERCISE_TARGET.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_EXERCISE_TARGET.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteExerciseTarget = (id: number, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_EXERCISE_TARGET.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_ADMIN_EXERCISE_TARGET(id))
		dispatch({ type: DELETE_EXERCISE_TARGET.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_EXERCISE_TARGET.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createExerciseTarget = (body: PostTargetBody, onSuccess?: (args: number) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: CREATE_EXERCISE_TARGET.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => query || undefined)
		const { data } = await postReq(ENDPOINTS.CREATE_ADMIN_EXERCISE_TARGET, undefined, normalizeQueryParams)
		dispatch({ type: CREATE_EXERCISE_TARGET.DONE })
		return onSuccess && onSuccess(data?.data?.id)
	} catch (error) {
		dispatch({ type: CREATE_EXERCISE_TARGET.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateExerciseTarget = (id: number, body: PutTargetIdBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_EXERCISE_TARGET.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => query || undefined)
		await putReq(ENDPOINTS.UPDATE_ADMIN_EXERCISE_TARGET(id), undefined, normalizeQueryParams)
		dispatch({ type: UPDATE_EXERCISE_TARGET.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_EXERCISE_TARGET.FAILED })
		return onFailure && onFailure(error)
	}
}
