import { useCallback, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import i18next from 'i18next'
import { Button, Empty, Form, Input, Menu, notification, Popconfirm, Select, Table, Tooltip } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { capitalize, debounce, findKey, get, join, map } from 'lodash'
import { change } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
	CloseCircleOutlined,
	DeleteOutlined,
	PlusCircleOutlined,
	QuestionCircleOutlined,
	RollbackOutlined,
	LinkOutlined,
	CopyOutlined
} from '@ant-design/icons'

// redux
import { RootState } from '../../redux'
import { deleteBlogPost, getBlogPosts, restoreBlogPost, duplicateBlogPost } from '../../redux/blog/actions'
import { getBlogCategories } from '../../redux/select/actions'
import { setFilters } from '../../redux/filters/actions'

// utils
import { LANGUAGE, LANGUAGES, BLOG_POST_TYPE, BLOG_POSTS_FILTER_OPTION, INPUT_MAX_LENGTH, EMPTY_VALUE, FORMS } from '../../utils/enums'
import { getLanguageName, setFiltersForPage } from '../../utils/helpers'

// types
import { GetBlogPostsPayload } from '../../redux/blog/types'

// atoms
import BooleanRepresent from '../../atoms/BooleanRepresent'
import DateRepresent from '../../atoms/DateRepresent'

// assets
import flagCzechia from '../../assets/images/icons/flag-czechia.svg'
import flagSlovakia from '../../assets/images/icons/flag-slovakia.svg'

const PAGE_SIZE = 20

const { Option } = Select

const BlogPosts = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { t } = useTranslation()

	const persistFilter = useSelector((state: RootState) => state.filters)
	const dataSource = useSelector((state: RootState) => state.blog?.list?.tableList)
	const isLoadingList = useSelector((state: RootState) => state.blog?.list?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.blog?.detail?.isLoading)
	const context = useSelector((state: RootState) => state.blog?.list?.context)
	const blogCategories = useSelector((state: RootState) => state.select?.blogCategories?.list) || []

	// states
	const [filter, setFilter] = useState<any>({
		...setFiltersForPage(t('paths:blog|key'), persistFilter),
		language: null
	})
	const [selectedTab, setSelectedTab] = useState(() => {
		let defaultValue = null
		if (Object.values(persistFilter.filters).includes('TRUE')) {
			defaultValue = findKey(persistFilter.filters, (option) => option === 'TRUE')
		}

		return defaultValue || persistFilter?.filters.type || BLOG_POST_TYPE.ARTICLE
	})

	const columns = [
		{
			title: '',
			dataIndex: 'language',
			key: 'language',
			width: 30,
			render: (value: LANGUAGE) => {
				switch (value) {
					case LANGUAGE.SK:
						return <img src={flagSlovakia} alt={value} className={'lng-flag'} />
					case LANGUAGE.CZ:
						return <img src={flagCzechia} alt={value} className={'lng-flag'} />
					default:
						return value
				}
			}
		},
		{
			title: i18next.t('Názov'),
			dataIndex: 'title',
			key: 'title',
			sorter: true,
			ellipsis: true,
			width: 200,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Autor'),
			dataIndex: 'author',
			key: 'author',
			ellipsis: true,
			width: 210,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Kategória'),
			dataIndex: 'category',
			key: 'category',
			ellipsis: true,
			width: 180,
			render: (category: GetBlogPostsPayload[0]['category']) => (category ? join(category, ', ') : EMPTY_VALUE)
		},
		{
			title: i18next.t('Publikované'),
			dataIndex: 'isPublished',
			sorter: true,
			key: 'isPublished',
			width: 110,
			align: 'center',
			render: (state: boolean, record: GetBlogPostsPayload[0]) => <BooleanRepresent isTrue={state && dayjs(new Date()) >= dayjs(record.publishedAt)} />
		},
		{
			title: i18next.t('Dátum publikovania'),
			dataIndex: 'publishedAt',
			sorter: true,
			key: 'publishedAt',
			width: 180,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: i18next.t('Dátum poslednej úpravy'),
			dataIndex: 'updatedAt',
			sorter: true,
			key: 'updatedAt',
			width: 210,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (_: any, record: GetBlogPostsPayload[0] & { key: number }) => (
				<>
					<span style={{ marginRight: '8px' }}>
						<Link to={`${t('paths:blogDetail|path')}/${record?.key}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e: any) => e.stopPropagation()} />
						</Link>
					</span>
					{!(record.isDeleted || false) ? (
						<>
							<span style={{ marginRight: '8px' }}>
								<Popconfirm
									title={t('Skutočne chcete duplikovať záznam?')}
									icon={<QuestionCircleOutlined style={{ color: 'orange' }} />}
									cancelText={t('Zrušiť')}
									okText={t('Duplikovať')}
									onConfirm={(e: any) => {
										e.stopPropagation()
										dispatch(
											duplicateBlogPost(record.key, (data) => {
												history.push(`${t('paths:blogDetail|path')}/${get(data, 'data.id')}`)
											})
										)
									}}
									onCancel={(e: any) => e.stopPropagation()}
									okButtonProps={{
										size: 'small',
										type: 'primary'
									}}
									cancelButtonProps={{
										size: 'small',
										type: 'ghost'
									}}
								>
									<Button
										icon={<CopyOutlined />}
										onClick={(e) => {
											e.stopPropagation()
										}}
									/>
								</Popconfirm>
							</span>
							<Popconfirm
								title={t('Skutočne chcete vymazať záznam?')}
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								cancelText={t('Zrušiť')}
								okText={t('Vymazať')}
								onConfirm={(e: any) => {
									e.stopPropagation()
									dispatch(
										deleteBlogPost(record.key, () => {
											const body: any = {
												limit: PAGE_SIZE,
												page: 1,
												...filter,
												type: selectedTab
											}
											dispatch(getBlogPosts(body))
											dispatch(setFilters(t('paths:blog|key'), body))
										})
									)
								}}
								onCancel={(e: any) => e.stopPropagation()}
								okButtonProps={{
									size: 'small',
									type: 'primary',
									danger: true
								}}
								cancelButtonProps={{
									size: 'small',
									type: 'ghost'
								}}
							>
								<Button
									icon={<DeleteOutlined />}
									type={'primary'}
									danger
									onClick={(e) => {
										e.stopPropagation()
									}}
								/>
							</Popconfirm>
						</>
					) : (
						<Popconfirm
							title={t('Skutočne chcete obnoviť záznam?')}
							icon={<QuestionCircleOutlined style={{ color: 'green' }} />}
							cancelText={t('Zrušiť')}
							okText={t('Obnoviť')}
							onConfirm={(e: any) => {
								e.stopPropagation()
								dispatch(
									restoreBlogPost(record.key, () => {
										const body: any = {
											limit: PAGE_SIZE,
											page: 1,
											...filter
										}
										dispatch(getBlogPosts(body))
										history.push(`${t('paths:blogDetail|path')}/${record?.key}`)
										dispatch(setFilters(t('paths:blog|key'), body))
									})
								)
							}}
							onCancel={(e: any) => e.stopPropagation()}
							okButtonProps={{
								size: 'small',
								type: 'primary',
								danger: true
							}}
							cancelButtonProps={{
								size: 'small',
								type: 'ghost'
							}}
						>
							<Tooltip placement={'topLeft'} title={t('Obnoviť záznam')}>
								<Button icon={<RollbackOutlined />} type={'primary'} onClick={(e: any) => e.stopPropagation()} />
							</Tooltip>
						</Popconfirm>
					)}
				</>
			)
		}
	]

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter,
			type: [BLOG_POST_TYPE.ARTICLE, BLOG_POST_TYPE.RECIPE].includes(selectedTab) ? selectedTab : null
		}

		dispatch(getBlogPosts(body))

		dispatch(setFilters(t('paths:blog|key'), body))
	}, [filter, selectedTab, dispatch, t])

	useEffect(() => {
		dispatch(getBlogCategories())
	}, [dispatch])

	const resetFilter = () => {
		setFilter({
			language: null
		})
	}

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			...filter,
			page: pagination.current,
			...order
		})
	}

	const debounced = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[filter]
	)

	const handleOnChange = (e: any) => {
		if (e?.target?.value?.length > 2 || e?.target?.value?.length === 0) {
			debounced(e.target.value)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Input.Search defaultValue={filter?.search} maxLength={INPUT_MAX_LENGTH} style={{ width: 300 }} onChange={handleOnChange} allowClear />
				</Form.Item>
				<Form.Item>
					<Button
						icon={<PlusCircleOutlined />}
						style={{ marginRight: 20 }}
						onClick={() =>
							history.push({
								pathname: t('paths:blogCreate|path')
							})
						}
						type={'primary'}
					>
						{t('Pridať článok')}
					</Button>
					<Button
						icon={<PlusCircleOutlined />}
						onClick={() => {
							dispatch(change(FORMS.BLOG_FORM, 'type', BLOG_POST_TYPE.RECIPE))
							history.push({
								pathname: t('paths:blogCreate|path')
							})
						}}
						type={'primary'}
					>
						{t('Pridať recept')}
					</Button>
				</Form.Item>
			</div>
			<div className={'flex'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Select
						style={{ width: 300, marginRight: 20 }}
						onChange={(value) => setFilter({ ...filter, categoryIDs: value, page: 1 })}
						placeholder={t('Kategória')}
						value={filter.categoryIDs}
						defaultValue={persistFilter?.filters.categoryIDs}
						mode={'multiple'}
						filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
						{map(blogCategories, (category, index) => (
							<Option key={index} value={get(category, 'id')}>
								{get(category, 'name') || ''}
							</Option>
						))}
					</Select>
					<Select style={{ width: 200, marginRight: 20 }} onChange={(value) => setFilter({ ...filter, language: value })} value={filter?.language}>
						<Option value={null}>{t('Všetky jazyky')}</Option>
						{map(LANGUAGES, (language, index) => (
							<Option key={index} value={language}>
								{capitalize(getLanguageName(language))}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<Button style={{ marginBottom: 20 }} icon={<CloseCircleOutlined />} onClick={resetFilter}>
						{t('Zrušiť filter')}
					</Button>
				</Form.Item>
			</div>
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore */}
			<Menu
				style={{ marginTop: -20 }}
				onClick={(menu: any) => {
					setSelectedTab(menu.key)
					const options = {
						[BLOG_POSTS_FILTER_OPTION.PUBLISHED]: null,
						[BLOG_POSTS_FILTER_OPTION.PLANNED]: null,
						[BLOG_POSTS_FILTER_OPTION.CONCEPTS]: null,
						[BLOG_POSTS_FILTER_OPTION.DELETED]: null
					}
					if (menu.item.props.filterBy) {
						setFilter({
							...filter,
							page: 1,
							...options,
							[menu.item.props.filterBy]: 'TRUE'
						})
					} else setFilter({ ...filter, page: 1, ...options })
				}}
				selectedKeys={[selectedTab]}
				mode={'horizontal'}
				items={[
					{ key: BLOG_POST_TYPE.ARTICLE, label: t('Blogové články') },
					{ key: BLOG_POST_TYPE.RECIPE, label: t('Recepty') },
					{ key: BLOG_POSTS_FILTER_OPTION.PUBLISHED, label: t('Publikované'), filterBy: BLOG_POSTS_FILTER_OPTION.PUBLISHED },
					{ key: BLOG_POSTS_FILTER_OPTION.PLANNED, label: t('Naplánované'), filterBy: BLOG_POSTS_FILTER_OPTION.PLANNED },
					{ key: BLOG_POSTS_FILTER_OPTION.CONCEPTS, label: t('Koncepty'), filterBy: BLOG_POSTS_FILTER_OPTION.CONCEPTS },
					{ key: BLOG_POSTS_FILTER_OPTION.DELETED, label: t('Vymazané'), filterBy: BLOG_POSTS_FILTER_OPTION.DELETED }
				]}
			/>
			<Table
				className={'general-table'}
				columns={columns as any}
				dataSource={dataSource}
				onChange={handleTableChange}
				style={{ marginTop: 0 }}
				showSorterTooltip={false}
				scroll={{ x: 'width' }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingList || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => {
						if (record.isDeleted) {
							notification.info({
								message: `${t('Vymazaný záznam je potrebné najskôr obnoviť')}`
							})
						} else {
							history.push(`${t('paths:blogDetail|path')}/${get(record, 'key')}`)
						}
					}
				})}
				rowClassName={() => (filter.deleted === 'TRUE' ? 'disabled-table-row' : '')}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

BlogPosts.propTypes = {}

export default BlogPosts
