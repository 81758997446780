// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetDownloadsPayload = RequestResponse<GetUrls['/admin/downloads/']>['downloads']
export type GetDownloadsIdPayload = RequestResponse<GetUrls['/admin/downloads/{id}']>

// query params
export type GetDownloadsQueryParams = RequestParams<GetUrls['/admin/downloads/']>['query']

// bodies
export type PostDownloadsBody = RequestPayload<PostUrls['/admin/downloads/']>
export type PutDownloadsIdBody = RequestPayload<PutUrls['/admin/downloads/{id}']>

export enum GET_DOWNLOADS {
	START = 'GET_DOWNLOADS_START',
	DONE = 'GET_DOWNLOADS_DONE',
	FAILED = 'GET_DOWNLOADS_FAILED'
}

export enum GET_DOWNLOAD {
	START = 'GET_DOWNLOAD_START',
	DONE = 'GET_DOWNLOAD_DONE',
	FAILED = 'GET_DOWNLOAD_FAILED'
}

export enum CREATE_DOWNLOAD {
	START = 'CREATE_DOWNLOAD_START',
	DONE = 'CREATE_DOWNLOAD_DONE',
	FAILED = 'CREATE_DOWNLOAD_FAILED'
}

export enum UPDATE_DOWNLOAD {
	START = 'UPDATE_DOWNLOAD_START',
	DONE = 'UPDATE_DOWNLOAD_DONE',
	FAILED = 'UPDATE_DOWNLOAD_FAILED'
}

export enum DELETE_DOWNLOAD {
	START = 'DELETE_DOWNLOAD_START',
	DONE = 'DELETE_DOWNLOAD_DONE',
	FAILED = 'DELETE_DOWNLOAD_FAILED'
}
