import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS, ANIMATED_THUMBNAIL_STATUS } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import {
	GET_VIDEO,
	GET_VIDEOS,
	DELETE_VIDEO,
	CREATE_VIDEO,
	UPDATE_VIDEO,
	GENERATE_ANIMATION,
	ANIMATION_SAVE,
	CHECK_GENERATION,
	GetVideosIdPayload,
	GetVideosQueryParams,
	PutVideosIdBody,
	PostVideosBody,
	PostVideosIdAnimatedThumbnailsBody,
	PutVideosIdAnimatedThumbnailsBody,
	GetVideosIdAnimatedThumbnailsStatusQueryParams
} from './types'

export const getVideos = (params: GetVideosQueryParams) => async (dispatch: any) => {
	dispatch({ type: GET_VIDEOS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_VIDEOS, normalizeQueryParams)
		dispatch({ type: GET_VIDEOS.DONE, payload: { videos: data?.videos, context: data?.context } })
	} catch (error) {
		dispatch({ type: GET_VIDEOS.FAILED })
		Promise.reject(error)
	}
}

export const getVideo = (id: number, onSuccess?: (args: GetVideosIdPayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: GET_VIDEO.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_VIDEO(id))
		dispatch({ type: GET_VIDEO.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_VIDEO.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteVideo = (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_VIDEO.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_VIDEO(id))
		dispatch({ type: DELETE_VIDEO.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_VIDEO.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createVideo = (body: PostVideosBody, onSuccess?: (id: number) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: CREATE_VIDEO.START })
	try {
		const { data } = await postReq(ENDPOINTS.CREATE_VIDEO, undefined, body)
		dispatch({ type: CREATE_VIDEO.DONE })
		return onSuccess && onSuccess(data?.data?.id)
	} catch (error) {
		dispatch({ type: CREATE_VIDEO.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateVideo = (id: number, body: PutVideosIdBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_VIDEO.START })
	try {
		await putReq(ENDPOINTS.UPDATE_VIDEO(id), undefined, body)
		dispatch({ type: UPDATE_VIDEO.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_VIDEO.FAILED })
		return onFailure && onFailure(error)
	}
}

export const generateThumbnail = async (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => {
	try {
		await postReq(ENDPOINTS.GENERATE_THUMBNAIL(id))
		return onSuccess && onSuccess()
	} catch (error) {
		return onFailure && onFailure(error)
	}
}

export const generateAnimation =
	(id: number, body: PostVideosIdAnimatedThumbnailsBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		try {
			dispatch({ type: GENERATE_ANIMATION.START })
			const { data } = await postReq(ENDPOINTS.GENERATE_THUMBNAIL_ANIMATION(id), undefined, body)
			dispatch({ type: GENERATE_ANIMATION.DONE, payload: data })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: GENERATE_ANIMATION.STOP })
			return onFailure && onFailure(error)
		}
	}

export const saveAnimation =
	(id: number, body: PutVideosIdAnimatedThumbnailsBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		try {
			dispatch({ type: ANIMATION_SAVE.START })
			await putReq(ENDPOINTS.SAVE_THUMBNAIL_ANIMATION(id), undefined, body)
			dispatch({ type: ANIMATION_SAVE.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: ANIMATION_SAVE.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const checkGeneration =
	(id: number, params: GetVideosIdAnimatedThumbnailsStatusQueryParams, onSuccess?: () => void, onFailure?: (error: unknown) => void) =>
	async (dispatch: any) => {
		try {
			dispatch({ type: CHECK_GENERATION.START })
			const normalizeQueryParams = mapValues(params, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.CHECK_THUMBNAIL_GENERATION(id), normalizeQueryParams)
			if (data?.status === ANIMATED_THUMBNAIL_STATUS.COMPLETED) {
				dispatch({ type: CHECK_GENERATION.DONE })
			}
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: CHECK_GENERATION.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const stopGenerating = () => (dispatch: any) => dispatch({ type: ANIMATION_SAVE.FAILED })
