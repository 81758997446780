// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetTagsPayload = RequestResponse<GetUrls['/admin/tags/']>['tags']
export type GetTagsIdPayload = RequestResponse<GetUrls['/admin/tags/{id}']>

// query params
export type GetTagsQueryParams = RequestParams<GetUrls['/admin/tags/']>['query']

// bodies
export type PostTagsBody = RequestPayload<PostUrls['/admin/tags/']>
export type PutTagsTagIdBody = RequestPayload<PutUrls['/admin/tags/{tagID}']>

export enum GET_TAGS {
	START = 'GET_TAGS_START',
	DONE = 'GET_TAGS_DONE',
	FAILED = 'LOAD_TAGS_FAILED'
}

export enum GET_TAG {
	START = 'GET_TAG_START',
	DONE = 'GET_TAG_DONE',
	FAILED = 'GET_TAG_FAILED'
}

export enum UPDATE_TAG {
	START = 'UPDATE_TAG_START',
	DONE = 'UPDATE_TAG_DONE',
	FAILED = 'UPDATE_TAG_FAILED'
}

export enum CREATE_TAG {
	START = 'CREATE_TAG_START',
	DONE = 'CREATE_TAG_DONE',
	FAILED = 'CREATE_TAG_FAILED'
}

export enum DELETE_TAG {
	START = 'DELETE_TAG_START',
	DONE = 'DELETE_TAG_DONE',
	FAILED = 'DELETE_TAG_FAILED'
}
