// utils
import { GetUrls, RequestResponse } from '../../utils/request'

// payloads
export type GetLandingPayload = RequestResponse<GetUrls['/admin/landing/']>

export enum GET_LANDING_PAGE {
	START = 'GET_LANDING_PAGE_START',
	DONE = 'GET_LANDING_PAGE_DONE',
	FAILED = 'GET_LANDING_PAGE_FAILED'
}

export enum UPDATE_LANDING_PAGE {
	START = 'UPDATE_LANDING_PAGE_START',
	DONE = 'UPDATE_LANDING_PAGE_DONE',
	FAILED = 'UPDATE_LANDING_PAGE_FAILED'
}

export const UPDATE_PAGE_METADATA = 'UPDATE_PAGE_METADATA'
export const UPDATE_CHALLENGE_RECORD = 'UPDATE_CHALLENGE_RECORD'
export const CREATE_CHALLENGE_RECORD = 'CREATE_CHALLENGE_RECORD'
export const REMOVE_CHALLENGE_RECORD = 'REMOVE_CHALLENGE_RECORD'

export const UPDATE_TESTIMONIAL_RECORD = 'UPDATE_TESTIMONIAL_RECORD'
export const CREATE_TESTIMONIAL_RECORD = 'CREATE_TESTIMONIAL_RECORD'
export const REMOVE_TESTIMONIAL_RECORD = 'REMOVE_TESTIMONIAL_RECORD'

export const UPDATE_PARTNER_RECORD = 'UPDATE_PARTNER_RECORD'
export const CREATE_PARTNER_RECORD = 'CREATE_PARTNER_RECORD'
export const REMOVE_PARTNER_RECORD = 'REMOVE_PARTNER_RECORD'

export const CHANGE_PARTNERS_ORDER = 'CHANGE_PARTNERS_ORDER'
export const CHANGE_CHALLENGES_ORDER = 'CHANGE_CHALLENGES_ORDER'
export const CHANGE_TESTIMONIALS_ORDER = 'CHANGE_TESTIMONIALS_ORDER'
