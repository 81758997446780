// utils
import { GetUrls, PatchUrls, PostUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetUsersIdPayload = RequestResponse<GetUrls['/admin/users/{id}']>
export type GetUsersUserIdSubscriptionsTier = RequestResponse<GetUrls['/admin/users/{userID}/subscriptions/tier']>

// query params
export type GetUsersQueryParams = RequestParams<GetUrls['/admin/users/']>['query']
export type GetUsersUserIdUserAgentsQueryParams = RequestParams<GetUrls['/admin/users/{userID}/user-agents']>['query']

// bodies
export type PostUsersUserIdProgramsBody = RequestPayload<PostUrls['/admin/users/{userID}/programs/']>
export type PatchUsersIdBody = RequestPayload<PatchUrls['/admin/users/{id}']>
export type PatchUsersUserIdProgramsUserProgramIdBody = RequestPayload<PatchUrls['/admin/users/{userID}/programs/{userProgramID}']>

export enum GET_USERS {
	START = 'GET_USERS_START',
	DONE = 'GET_USERS_DONE',
	FAILED = 'GET_USERS_FAILED'
}

export enum GET_DETAIL_USER {
	START = 'GET_DETAIL_USER_START',
	DONE = 'GET_DETAIL_USER_DONE',
	FAILED = 'GET_DETAIL_USER_FAILED'
}

export enum DELETE_USER {
	START = 'DELETE_USER_START',
	DONE = 'DELETE_USER_DONE',
	FAILED = 'DELETE_USER_FAILED'
}

export enum CREATE_USER {
	START = 'CREATE_USER_START',
	DONE = 'CREATE_USER_DONE',
	FAILED = 'CREATE_USER_FAILED'
}

export enum UPDATE_USER {
	START = 'UPDATE_USER_START',
	DONE = 'UPDATE_USER_DONE',
	FAILED = 'UPDATE_USER_FAILED'
}

export enum GET_USER_PROGRAMS {
	START = 'GET_USER_PROGRAMS_START',
	DONE = 'GET_USER_PROGRAMS_DONE',
	FAILED = 'GET_USER_PROGRAMS_FAILED'
}

export enum GET_USER_PROGRAM {
	START = 'GET_USER_PROGRAM_START',
	DONE = 'GET_USER_PROGRAM_DONE',
	FAILED = 'GET_USER_PROGRAM_FAILED'
}

export enum DELETE_USER_PROGRAM {
	START = 'START_DELETE_USER_PROGRAM',
	DONE = 'DONE_DELETE_USER_PROGRAM',
	FAILED = 'FAILED_DELETE_USER_PROGRAM'
}

export enum UPDATE_USER_PROGRAM {
	START = 'START_UPDATE_USER_PROGRAM',
	DONE = 'DONE_UPDATE_USER_PROGRAM',
	FAILED = 'FAILED_UPDATE_USER_PROGRAM'
}

export enum CREATE_USER_PROGRAM {
	START = 'START_CREATE_USER_PROGRAM',
	DONE = 'DONE_CREATE_USER_PROGRAM',
	FAILED = 'FAILED_CREATE_USER_PROGRAM'
}

export enum GET_USER_USER_AGENTS {
	START = 'START_GET_USER_USER_AGENTS',
	DONE = 'DONE_GET_USER_USER_AGENTS',
	FAILED = 'FAILED_GET_USER_USER_AGENTS'
}
