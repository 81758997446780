// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetHeadlinesPayload = RequestResponse<GetUrls['/admin/headlines/']>['banners']
export type GetHeadlinesId = RequestResponse<GetUrls['/admin/headlines/{id}']>

// query params
export type GetHeadlinesQueryParams = RequestParams<GetUrls['/admin/headlines/']>['query']

// bodies
export type PostHeadlinesBody = RequestPayload<PostUrls['/admin/headlines/']>
export type PutHeadlinesIdBody = RequestPayload<PutUrls['/admin/headlines/{id}']>

export enum GET_BANNERS {
	START = 'GET_BANNERS_START',
	DONE = 'GET_BANNERS_DONE',
	FAILED = 'GET_BANNERS_FAILED'
}

export enum GET_BANNER {
	START = 'GET_BANNER_START',
	DONE = 'GET_BANNER_DONE',
	FAILED = 'GET_BANNER_FAILED'
}

export enum UPDATE_BANNER {
	START = 'UPDATE_BANNER_START',
	DONE = 'UPDATE_BANNER_DONE',
	FAILED = 'UPDATE_BANNER_FAILED'
}

export enum CREATE_BANNER {
	START = 'CREATE_BANNER_START',
	DONE = 'CREATE_BANNER_DONE',
	FAILED = 'CREATE_BANNER_FAILED'
}

export enum DELETE_BANNER {
	START = 'DELETE_BANNER_START',
	DONE = 'DELETE_BANNER_DONE',
	FAILED = 'DELETE_BANNER_FAILED'
}
