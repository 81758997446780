// utils
import { GetUrls, PostUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetSubscriptionsPayload = RequestResponse<GetUrls['/admin/subscriptions/']>

// query params
export type GetSubscriptionsQueryParams = RequestParams<GetUrls['/admin/subscriptions/']>['query']
export type GetUsersUserIdSubscriptionsTierQueryParams = RequestParams<GetUrls['/admin/users/{userID}/subscriptions/tier']>['query']

// bodies
export type PostUsersUserIdSubscriptionsTierBody = RequestPayload<PostUrls['/admin/users/{userID}/subscriptions/tier']>

export enum GET_USER_SUBSCRIPTIONS {
	START = 'GET_USER_SUBSCRIPTIONS_START',
	DONE = 'GET_USER_SUBSCRIPTIONS_DONE',
	FAILED = 'GET_USER_SUBSCRIPTIONS_FAILED'
}

export enum GET_USER_SUBSCRIPTIONS_TIER_VALIDATE {
	START = 'GET_USER_SUBSCRIPTIONS_TIER_VALIDATE_START',
	DONE = 'GET_USER_SUBSCRIPTIONS_TIER_VALIDATE_DONE',
	FAILED = 'GET_USER_SUBSCRIPTIONS_TIER_VALIDATE_FAILED'
}

export enum GET_USER_SUBSCRIPTIONS_TIER {
	START = 'GET_USER_SUBSCRIPTIONS_TIER_START',
	DONE = 'GET_USER_SUBSCRIPTIONS_TIER_DONE',
	FAILED = 'GET_USER_SUBSCRIPTIONS_TIER_FAILED'
}

export enum CHANGE_USER_SUBSCRIPTIONS_TIER {
	START = 'CHANGE_USER_SUBSCRIPTIONS_TIER_START',
	DONE = 'CHANGE_USER_SUBSCRIPTIONS_TIER_DONE',
	FAILED = 'CHANGE_USER_SUBSCRIPTIONS_TIER_FAILED'
}
