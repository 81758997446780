export enum GET_LIST {
	START = 'GET_LIST_START',
	FAILED = 'GET_LIST_FAILED',
	DONE = 'GET_LIST_DONE'
}

export enum GET_LIST_ITEM {
	START = 'GET_LIST_ITEM_START',
	FAILED = 'GET_LIST_ITEM_FAILED',
	DONE = 'GET_LIST_ITEM_DONE'
}
