// types
import { GET_TRANSLATIONS } from './types'

export const initState = {
	translations: {
		rawList: [],
		tableList: [],
		context: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_TRANSLATIONS.START:
			return {
				...initState,
				...state,
				translations: {
					...state.translations,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_TRANSLATIONS.DONE:
			return {
				...initState,
				...state,
				translations: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_TRANSLATIONS.FAILED:
			return {
				...state,
				translations: {
					...state.translations,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
