import { useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createPromoCodes, getPromoCode, updatePromoCode } from '../../redux/promoCodes/actions'

// utils
import { FORMS, PROMOCODE_LIFESPAN, PROMOCODE_STATE, PROMOCODE_TYPE } from '../../utils/enums'

// types
import { IComputedMatch } from '../../types/interfaces'
import { PromoCodeFormValues } from './forms/validatePromoCodeForm'
import { GetAdminPromoCodesIdPayload, PostPromoCodesBody, PutPromoCodesCodeIdBody } from '../../redux/promoCodes/types'

// forms
import PromoCodeForm from './forms/PromoCodeForm'

type DetailPromoCodeProps = {
	computedMatch: IComputedMatch
}

const DetailPromoCode = ({ computedMatch }: DetailPromoCodeProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const detail = useSelector((state: RootState) => state.promoCodes?.detail)

	// constants
	const { id } = computedMatch.params

	const isLoading = detail?.isLoading

	const initEmptyDetailForm = useCallback(() => {
		dispatch(
			initialize(FORMS.PROMOCODE_FORM, {
				state: PROMOCODE_STATE.ACTIVE,
				lifespan: PROMOCODE_LIFESPAN.LONG_TERM,
				forNewcomers: false
			})
		)
	}, [dispatch])

	const initDetailForm = useCallback(
		(data: GetAdminPromoCodesIdPayload) => {
			const argument = PROMOCODE_TYPE.DISCOUNT === get(data, 'type') ? get(data, 'argument') * 100 : get(data, 'argument')

			const initValues = {
				id: get(data, 'id'),
				code: get(data, 'code'),
				state: get(data, 'state') === PROMOCODE_STATE.ACTIVE,
				type: get(data, 'type'),
				premiumTierAllowed: data?.premiumTierAllowed || false,
				vipTierAllowed: data?.vipTierAllowed || false,
				lifespan: get(data, 'lifespan') || PROMOCODE_LIFESPAN.LONG_TERM,
				argument: parseInt(argument as any, 10),
				updatedAt: dayjs(get(data, 'updatedAt')).format('H:mm:ss D. MMM YYYY '),
				forNewcomers: get(data, 'forNewcomers'),
				maxUsageOfPromoCode: get(data, 'maxUsageOfPromoCode', null),
				validFrom: get(data, 'validFrom'),
				validTo: get(data, 'validTo'),
				sendTransactionalEmail: data?.sendTransactionalEmail || false
			}

			dispatch(initialize(FORMS.PROMOCODE_FORM, initValues))
		},
		[dispatch]
	)

	useEffect(() => {
		if (id) {
			dispatch(getPromoCode(id, (data) => initDetailForm(data)))
		} else {
			initEmptyDetailForm()
		}
	}, [computedMatch, dispatch, id, initDetailForm, initEmptyDetailForm])

	const handleCreate = (body: PostPromoCodesBody) =>
		dispatch(createPromoCodes(body, (promoCodeId) => history.push(`${t('paths:promoCodeDetail|path')}/${promoCodeId}`)))

	const handleUpdate = (body: PutPromoCodesCodeIdBody) =>
		dispatch(updatePromoCode(id, body, () => dispatch(getPromoCode(id, (data) => initDetailForm(data)))))

	const handleSubmit = (values: PromoCodeFormValues) => {
		const argument = PROMOCODE_TYPE.DISCOUNT === get(values, 'type') ? get(values, 'argument') / 100 : parseInt(get(values, 'argument'), 10)

		const body = {
			code: get(values, 'code'),
			state: get(values, 'state') ? PROMOCODE_STATE.ACTIVE : PROMOCODE_STATE.INACTIVE,
			type: get(values, 'type'),
			premiumTierAllowed: values?.premiumTierAllowed || false,
			vipTierAllowed: values?.vipTierAllowed || false,
			lifespan: get(values, 'lifespan'),
			forNewcomers: get(values, 'forNewcomers'),
			maxUsageOfPromoCode: parseInt(values?.maxUsageOfPromoCode as any, 10),
			validFrom: dayjs(get(values, 'validFrom')).format('YYYY-MM-DD'),
			validTo: dayjs(get(values, 'validTo')).format('YYYY-MM-DD'),
			sendTransactionalEmail: values?.sendTransactionalEmail || false,
			argument
		}

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<PromoCodeForm onSubmit={handleSubmit} isCreate={!id} />
			</Spin>
		</div>
	)
}

export default DetailPromoCode
