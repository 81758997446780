// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// types
import { ArrElement } from '../../types/types'

// payloads
export type GetPreferencesPayload = RequestResponse<GetUrls['/admin/preferences/']>['preferences']
export type GetPreferencesListItemPayload = ArrElement<GetPreferencesPayload>
export type GetPreferencesIdPayload = RequestResponse<GetUrls['/admin/preferences/{id}']>

// query params
export type GetPreferencesQueryParams = RequestParams<GetUrls['/admin/preferences/']>['query']

// bodies
export type PostPreferencesBody = RequestPayload<PostUrls['/admin/preferences/']>
export type PutPreferencesPreferenceIdBody = RequestPayload<PutUrls['/admin/preferences/{preferenceID}']>

export enum GET_PREFERENCES {
	START = 'GET_PREFERENCES_START',
	DONE = 'GET_PREFERENCES_DONE',
	FAILED = 'LOAD_PREFERENCES_FAILED'
}

export enum GET_PREFERENCE {
	START = 'GET_PREFERENCE_START',
	DONE = 'GET_PREFERENCE_DONE',
	FAILED = 'GET_PREFERENCE_FAILED'
}

export enum DELETE_PREFERENCE {
	START = 'DELETE_PREFERENCE_START',
	DONE = 'DELETE_PREFERENCE_DONE',
	FAILED = 'DELETE_PREFERENCE_FAILED'
}

export enum CREATE_PREFERENCE {
	START = 'CREATE_PREFERENCE_START',
	DONE = 'CREATE_PREFERENCE_DONE',
	FAILED = 'CREATE_PREFERENCE_FAILED'
}

export enum UPDATE_PREFERENCE {
	START = 'UPDATE_PREFERENCE_START',
	DONE = 'UPDATE_PREFERENCE_DONE',
	FAILED = 'UPDATE_PREFERENCE_FAILED'
}
