import i18next from 'i18next'
import { FormErrors } from 'redux-form'

export type ThumbnailGIFFormValues = {
	start: number
	duration: number
}

export default (values: ThumbnailGIFFormValues) => {
	const errors: FormErrors<ThumbnailGIFFormValues> = {}

	if (!values.start) {
		errors.start = i18next.t('Toto pole je povinné')
	}

	if (values.start && values.start > 3600) {
		errors.start = i18next.t('Maximalna hodnota je 3600')
	}
	if (values.start && values.start < 1) {
		errors.start = i18next.t('Minimalna hodnota je 1')
	}

	if (!values.duration) {
		errors.duration = i18next.t('Toto pole je povinné')
	}

	if (values.duration && values.duration > 6) {
		errors.duration = i18next.t('Maximalna hodnota je 6')
	}

	if (values.duration && values.duration < 1) {
		errors.duration = i18next.t('Minimalna hodnota je 1')
	}

	return errors
}
