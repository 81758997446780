import { get, mapValues } from 'lodash'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'
import { ENDPOINTS } from '../../utils/enums'
import {
	GET_DOWNLOADS,
	GET_DOWNLOAD,
	CREATE_DOWNLOAD,
	UPDATE_DOWNLOAD,
	DELETE_DOWNLOAD,
	GetDownloadsQueryParams,
	GetDownloadsPayload,
	GetDownloadsIdPayload,
	PostDownloadsBody,
	PutDownloadsIdBody
} from './types'

export const getDownloads =
	(params: GetDownloadsQueryParams, onSuccess?: (args: GetDownloadsPayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		try {
			dispatch({ type: GET_DOWNLOADS.START })
			const queries = {
				limit: 20,
				page: 1,
				...params
			}
			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.GET_ADMIN_DOWNLOADS, normalizeQueryParams)
			const payload = {
				list: data.downloads,
				context: data.context
			}
			dispatch({ type: GET_DOWNLOADS.DONE, payload })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_DOWNLOADS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getDownload = (id: number, onSuccess?: (args: GetDownloadsIdPayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: GET_DOWNLOAD.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_DOWNLOAD(id))
		dispatch({ type: GET_DOWNLOAD.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_DOWNLOAD.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createDownload = (body: PostDownloadsBody, onSuccess?: (id: number) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: CREATE_DOWNLOAD.START })
	try {
		const { data } = await postReq(ENDPOINTS.CREATE_DOWNLOAD, undefined, body)
		dispatch({ type: CREATE_DOWNLOAD.DONE })
		return onSuccess && onSuccess(get(data, 'data.id'))
	} catch (error) {
		dispatch({ type: CREATE_DOWNLOAD.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateDownload = (id: number, body: PutDownloadsIdBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_DOWNLOAD.START })
	try {
		await putReq(ENDPOINTS.UPDATE_DOWNLOAD(id), undefined, body)
		dispatch({ type: UPDATE_DOWNLOAD.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_DOWNLOAD.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteDownload = (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_DOWNLOAD.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_DOWNLOAD(id))
		dispatch({ type: DELETE_DOWNLOAD.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_DOWNLOAD.FAILED })
		return onFailure && onFailure(error)
	}
}
