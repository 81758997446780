import { GET_BANNERS, GET_BANNER } from './types'

export const initState = {
	list: {
		tableList: [],
		context: {},
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_BANNERS.START:
			return {
				...initState,
				...state,
				list: {
					...state.list,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_BANNERS.DONE:
			return {
				...initState,
				...state,
				list: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_BANNERS.FAILED:
			return {
				...state,
				list: {
					...state.list,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_BANNER.START:
			return {
				...initState,
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_BANNER.DONE:
			return {
				...initState,
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_BANNER.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
