import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createPreference, getPreference, updatePreference } from '../../redux/preferences/actions'

// utils
import { FORMS, LANGUAGES, PREFERENCE_TYPE } from '../../utils/enums'

// types
import { IComputedMatch } from '../../types/interfaces'
import { PreferenceFormValues } from './forms/validatePreferenceForm'
import { GetPreferencesIdPayload, PostPreferencesBody, PutPreferencesPreferenceIdBody } from '../../redux/preferences/types'

// form
import PreferenceForm from './forms/PreferenceForm'

type DetailPreferenceProps = {
	computedMatch: IComputedMatch
}

const DetailPreference = ({ computedMatch }: DetailPreferenceProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const detail = useSelector((state: RootState) => state.preferences?.detail)

	// constants
	const { id } = computedMatch.params
	const isLoading = detail?.isLoading

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {
			order: 1
		}

		dispatch(initialize(FORMS.PREFERENCE_FORM, initValues))
	}, [dispatch])

	const initDetailForm = useCallback(
		(data: GetPreferencesIdPayload) => {
			const langData = data?.translations
			const langValues: any = {}

			const initValues = {
				id: data?.id,
				type: data?.type,
				updatedAt: dayjs(data?.updatedAt).format('H:mm:ss D. MMM YYYY '),
				order: data?.order || 1
			}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`title_${item}`] = lang?.title
				langValues[`description_${item}`] = lang?.description
			})

			dispatch(
				initialize(FORMS.PREFERENCE_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch]
	)

	useEffect(() => {
		if (id) {
			dispatch(getPreference(id, (data) => initDetailForm(data)))
		} else {
			initEmptyDetailForm()
		}

		// eslint-disable-next-line no-use-before-define
	}, [computedMatch, dispatch, id, initDetailForm, initEmptyDetailForm])

	const handleCreate = (body: PostPreferencesBody) =>
		dispatch(createPreference(body, (preferenceID) => history.push(`${t('paths:preference|path')}/${preferenceID}`)))

	const handleUpdate = (body: PutPreferencesPreferenceIdBody) =>
		dispatch(updatePreference(id, body, () => dispatch(getPreference(id, (data) => initDetailForm(data)))))

	const handleSubmit = (values: PreferenceFormValues) => {
		const body: PostPreferencesBody | PutPreferencesPreferenceIdBody = {
			translations: [],
			type: values?.type as PREFERENCE_TYPE,
			order: values?.order
		}

		each(LANGUAGES, (item) => {
			const title = get(values, `title_${item}`)
			const description = get(values, `description_${item}`)

			if (title && description) {
				const translation: any = {
					title: get(values, `title_${item}`),
					description: get(values, `description_${item}`),
					language: item
				}

				body.translations.push(translation)
			}
		})

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<PreferenceForm onSubmit={handleSubmit} isCreate={!id} />
			</Spin>
		</div>
	)
}

export default DetailPreference
