import { RESET_FILTERS, SET_FILTERS } from './types'

export const resetFilters = () => (dispatch: any) => {
	dispatch({
		type: RESET_FILTERS
	})
}

export const setFilters = (page: any, filters?: any) => (dispatch: any) => {
	dispatch({
		type: SET_FILTERS,
		payload: {
			page,
			filters
		}
	})
}
