import { map, mapValues } from 'lodash'
import { deleteReq, getReq, patchReq, postReq } from '../../utils/request'
import { EMPTY_VALUE, ENDPOINTS } from '../../utils/enums'
import {
	GET_USERS,
	GET_DETAIL_USER,
	DELETE_USER,
	UPDATE_USER,
	GET_USER_PROGRAM,
	GET_USER_PROGRAMS,
	UPDATE_USER_PROGRAM,
	DELETE_USER_PROGRAM,
	CREATE_USER_PROGRAM,
	GET_USER_USER_AGENTS,
	GetUsersQueryParams,
	GetUsersIdPayload,
	PatchUsersIdBody,
	PatchUsersUserIdProgramsUserProgramIdBody,
	PostUsersUserIdProgramsBody,
	GetUsersUserIdUserAgentsQueryParams
} from './types'

export const getUsers = (params: GetUsersQueryParams) => async (dispatch: any) => {
	dispatch({ type: GET_USERS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_USERS, normalizeQueryParams)

		const tableList = map(data.users, (user) => ({
			...user,
			fullName: !user.firstName && !user.lastName ? EMPTY_VALUE : `${user.firstName || ''} ${user.lastName || ''}`.trim()
		}))
		dispatch({
			type: GET_USERS.DONE,
			payload: {
				tableList,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_USERS.FAILED })
		Promise.reject(error)
	}
}

export const getUser = (id: number, onSuccess?: (args: GetUsersIdPayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: GET_DETAIL_USER.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_USER(id))
		dispatch({ type: GET_DETAIL_USER.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_DETAIL_USER.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteUser = (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_USER.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_USER(id))
		dispatch({ type: DELETE_USER.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_USER.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateUser = (id: number, body: PatchUsersIdBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_USER.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => query || undefined)
		await patchReq(ENDPOINTS.UPDATE_USER(id), undefined, normalizeQueryParams)
		dispatch({ type: UPDATE_USER.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_USER.FAILED })
		return onFailure && onFailure(error)
	}
}

export const getUserPrograms = (userID: number, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: GET_USER_PROGRAMS.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_USER_PROGRAMS(userID))
		dispatch({
			type: GET_USER_PROGRAMS.DONE,
			payload: data
		})
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: GET_USER_PROGRAMS.FAILED })
		return onFailure && onFailure()
	}
}

export const getUserProgram = (userID: number, programID: number, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: GET_USER_PROGRAM.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_USER_PROGRAM(userID, programID))
		dispatch({ type: GET_USER_PROGRAM.DONE, payload: data })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: GET_USER_PROGRAM.FAILED })
		return onFailure && onFailure()
	}
}

export const deleteUserProgram = (userID: number, programID: number, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_USER_PROGRAM.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_USER_PROGRAM(userID, programID))
		dispatch({ type: DELETE_USER_PROGRAM.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_USER_PROGRAM.FAILED })
		return onFailure && onFailure()
	}
}

export const updateUserProgram =
	(userID: number, programID: number, body: PatchUsersUserIdProgramsUserProgramIdBody, onSuccess?: () => void, onFailure?: () => void) =>
	async (dispatch: any) => {
		dispatch({ type: UPDATE_USER_PROGRAM.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query)
			await patchReq(ENDPOINTS.GET_USER_PROGRAM(userID, programID), undefined, normalizeQueryParams)
			dispatch({ type: UPDATE_USER_PROGRAM.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_USER_PROGRAM.FAILED })
			return onFailure && onFailure()
		}
	}

export const createUserProgram =
	(userID: number, body: PostUsersUserIdProgramsBody, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_USER_PROGRAM.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query)
			await postReq(ENDPOINTS.GET_USER_PROGRAMS(userID), undefined, normalizeQueryParams)
			dispatch({ type: CREATE_USER_PROGRAM.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: CREATE_USER_PROGRAM.FAILED })
			return onFailure && onFailure()
		}
	}

export const getUserUserAgents =
	(userID: number, params?: GetUsersUserIdUserAgentsQueryParams, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
		dispatch({ type: GET_USER_USER_AGENTS.START })
		try {
			const queries = {
				limit: 20,
				page: 1,
				userID,
				...params
			}
			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.GET_USER_USER_AGENTS(userID), normalizeQueryParams)

			dispatch({
				type: GET_USER_USER_AGENTS.DONE,
				payload: data
			})
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: GET_USER_USER_AGENTS.FAILED })
			return onFailure && onFailure()
		}
	}
