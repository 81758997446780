// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payload
export type GetBlogCategoriesPayload = RequestResponse<GetUrls['/admin/blog-categories/']>['blogCategories']
export type GetBlogCategoriesIdPayload = RequestResponse<GetUrls['/admin/blog-categories/{id}']>

// query params
export type GetBlogCategoriesQueryParams = RequestParams<GetUrls['/admin/blog-categories/']>['query']

// bodies
export type PostBlogCategoriesBody = RequestPayload<PostUrls['/admin/blog-categories/']>
export type PutBlogCategoriesIdBody = RequestPayload<PutUrls['/admin/blog-categories/{id}']>

export enum GET_BLOG_CATEGORIES {
	START = 'GET_BLOG_CATEGORIES_START',
	DONE = 'GET_BLOG_CATEGORIES_DONE',
	FAILED = 'GET_BLOG_CATEGORIES_FAILED'
}

export enum GET_BLOG_CATEGORY {
	START = 'GET_BLOG_CATEGORY_START',
	DONE = 'GET_BLOG_CATEGORY_DONE',
	FAILED = 'GET_BLOG_CATEGORY_FAILED'
}

export enum UPDATE_BLOG_CATEGORY {
	START = 'UPDATE_BLOG_CATEGORY_START',
	DONE = 'UPDATE_BLOG_CATEGORY_DONE',
	FAILED = 'UPDATE_BLOG_CATEGORY_FAILED'
}

export enum CREATE_BLOG_CATEGORY {
	START = 'CREATE_BLOG_CATEGORY_START',
	DONE = 'CREATE_BLOG_CATEGORY_DONE',
	FAILED = 'CREATE_BLOG_CATEGORY_FAILED'
}

export enum DELETE_BLOG_CATEGORY {
	START = 'DELETE_BLOG_CATEGORY_START',
	DONE = 'DELETE_BLOG_CATEGORY_DONE',
	FAILED = 'DELETE_BLOG_CATEGORY_FAILED'
}
