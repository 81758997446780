import i18next from 'i18next'
import each from 'lodash/each'
import get from 'lodash/get'
import { FormErrors } from 'redux-form'
import { VIDEO_DIFFICULTY, EXERCISE_BODY_PART, LANGUAGE, LANGUAGES, VIDEO_MEDIUM_DIFFICULTY, slugErrorText } from '../../../utils/enums'
import { slugRegex, urlRegEx } from '../../../utils/regex'

export type VideoFormValues = {
	id: number
	language: LANGUAGE
	name_sk: string
	name_cz?: string
	name_en?: string
	urlSlug_sk: string
	urlSlug_cz?: string
	urlSlug_en?: string
	url_sk: string
	url_cz?: string
	url_en?: string
	briefDescription_sk: string
	briefDescription_cz?: string
	briefDescription_en?: string
	detailedDescription_sk: string
	detailedDescription_cz?: string
	detailedDescription_en?: string
	duration_sk: string
	duration_cz?: string
	duration_en?: string
	difficulty: VIDEO_DIFFICULTY
	mediumDifficulty?: VIDEO_MEDIUM_DIFFICULTY
	image: string
	calories: { key: string; weightFrom: string; weightTo: string; calories: string }[]
	order: number
	bodyParts: EXERCISE_BODY_PART[]
}

export default (values: VideoFormValues) => {
	const errors: FormErrors<VideoFormValues> = {}

	if (!values.language) {
		errors.language = i18next.t('Toto pole je povinné')
	}

	if (!values.name_sk) {
		errors.name_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.urlSlug_sk) {
		errors.urlSlug_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.url_sk) {
		errors.url_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.briefDescription_sk) {
		errors.briefDescription_sk = i18next.t('Toto pole je povinné')
	}

	if (!values.detailedDescription_sk) {
		errors.detailedDescription_sk = i18next.t('Toto pole je povinné')
	}

	each(LANGUAGES, (item) => {
		const name = get(values, `name_${item}`)
		const urlSlug = get(values, `name_${item}`)
		const briefDescription = get(values, `name_${item}`)
		const detailedDescription = get(values, `name_${item}`)

		if (name || urlSlug || briefDescription || detailedDescription) {
			if (!values[`name_${item}`]) {
				errors[`name_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`urlSlug_${item}`]) {
				errors[`urlSlug_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (values[`urlSlug_${item}`] && !slugRegex.test(values[`urlSlug_${item}`] as string)) {
				errors[`urlSlug_${item}`] = i18next.t(slugErrorText)
			}

			if (values[`url_${item}`] && !urlRegEx.test(values[`url_${item}`] as string)) {
				errors[`url_${item}`] = i18next.t('Neplatná adresa')
			}

			if (!values[`briefDescription_${item}`]) {
				errors[`briefDescription_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`detailedDescription_${item}`]) {
				errors[`detailedDescription_${item}`] = i18next.t('Toto pole je povinné')
			}

			if (!values[`duration_${item}`]) {
				errors[`duration_${item}`] = i18next.t('Toto pole je povinné')
			}
		}
	})

	if (!values.difficulty) {
		errors.difficulty = i18next.t('Toto pole je povinné')
	}

	if (!values.image) {
		errors.image = i18next.t('Toto pole je povinné')
	}

	if (!values.calories && values.difficulty !== VIDEO_DIFFICULTY.EDUCATIONAL) {
		errors.calories = i18next.t('Toto pole je povinné')
	}

	if (values.calories && get(values, 'calories.length', 0) === 0 && values.difficulty !== VIDEO_DIFFICULTY.EDUCATIONAL) {
		errors.calories = i18next.t('Tabuľka kalórií musí byť vyplnená')
	}

	if (values.calories && get(values, 'calories.length', 0) > 0) {
		each(values.calories, (item) => {
			if (parseInt(item.weightFrom, 10) > parseInt(item.weightTo, 10)) {
				errors.calories = i18next.t('Hodnota hmotnosti "Od" je väčšia ako "Do"')
			}

			if (item.calories === '') {
				errors.calories = i18next.t('Chýba informácia o kalóriách')
			}

			if (item.weightTo === '') {
				errors.calories = i18next.t('Chýba informácia o hmotnosti "Do"')
			}

			if (item.weightFrom === '') {
				errors.calories = i18next.t('Chýba informácia o hmotnosti "Od"')
			}
		})
	}

	if (values.order && values.order < 1) {
		errors.order = i18next.t('Minimálna hodnota poradia je 1')
	}

	return errors
}
