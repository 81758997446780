import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { useTranslation } from 'react-i18next'

// utils
import { FORMS, CURRENCY, PAYMENT_CURRENCY_LABEL, SUBSCRIPTION_TYPE, PAYMENT_TYPE, SUBSCRIPTION_TIER } from '../../../utils/enums'
import { formatPaymentType } from '../../../utils/helpers'

// forms
import validatePaymentForm, { CustomPaymentFormValues } from './validateCustomPaymentForm'

// atoms
import DateField from '../../../atoms/form/DateField'
import SelectField from '../../../atoms/form/SelectField'
import TextAreaField from '../../../atoms/form/TextAreaField'
import TextField from '../../../atoms/form/TextField'
import SwitchField from '../../../atoms/form/SwitchField'

type CustomPaymentFormProps = {
	onSubmit: (args: CustomPaymentFormValues) => void
}

const CustomPaymentForm = ({ handleSubmit, onSubmit }: CustomPaymentFormProps & InjectedFormProps<CustomPaymentFormValues, CustomPaymentFormProps>) => {
	const { t } = useTranslation()

	// constants
	const subscriptionTypes = [
		{ value: SUBSCRIPTION_TYPE.ONETIME_MONTH, label: formatPaymentType(SUBSCRIPTION_TYPE.ONETIME_MONTH) },
		{ value: SUBSCRIPTION_TYPE.QUARTER, label: formatPaymentType(PAYMENT_TYPE.ONETIME_QUARTER) },
		{ value: SUBSCRIPTION_TYPE.YEAR, label: formatPaymentType(PAYMENT_TYPE.ONETIME_YEAR) }
	]

	const subscriptionTiers = [
		{ value: SUBSCRIPTION_TIER.PREMIUM, label: t('Premium') },
		{ value: SUBSCRIPTION_TIER.VIP, label: t('VIP') }
	]

	const currencies = [
		{ value: CURRENCY.EUR, label: PAYMENT_CURRENCY_LABEL.EUR },
		{ value: CURRENCY.CZK, label: PAYMENT_CURRENCY_LABEL.CZK }
	]

	return (
		<form className={'add-membership-form'} onSubmit={handleSubmit(onSubmit)}>
			<Field name={'subscriptionType'} component={SelectField} options={subscriptionTypes} label={t('Trvanie členstva')} required />
			<Field name={'subscriptionTier'} component={SelectField} options={subscriptionTiers} label={t('Typ členstva')} required />
			<Field name={'activeFrom'} component={DateField} label={t('Začiatok platnosti')} dttmFormat={'D.M.YYYY HH:mm'} showTime required />
			<Field name={'currency'} component={SelectField} options={currencies} label={t('Mena')} required />
			<Field name={'price'} component={TextField} type={'number'} label={t('Cena')} required />
			<Field name={'note'} component={TextAreaField} label={t('Poznámka')} />
			<Field name={'sendEmail'} component={SwitchField} label={t('Odoslať e-mail')} required />
		</form>
	)
}

export default reduxForm<CustomPaymentFormValues, CustomPaymentFormProps>({
	form: FORMS.CUSTOM_PAYMENT_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validatePaymentForm
})(CustomPaymentForm)
