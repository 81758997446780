import ceil from 'lodash/ceil'
import dayjs from 'dayjs'
import get from 'lodash/get'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import {
	GET_INSTRUCTORS,
	GET_INSTRUCTOR,
	DELETE_INSTRUCTOR,
	CREATE_INSTRUCTOR,
	UPDATE_INSTRUCTOR,
	GetTrainersIdPayload,
	GetTrainersQueryParams,
	PostTrainersBody,
	PutTrainersIdBody
} from './types'

export const getInstructors = (params: GetTrainersQueryParams) => async (dispatch: any) => {
	try {
		dispatch({ type: GET_INSTRUCTORS.START })
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_INSTRUCTORS, normalizeQueryParams)

		const totalPages = ceil(get(data, 'context.totalCount', 0) / get(data, 'context.count', 0))
		const isLastPage = totalPages === get(data, 'context.page', 0)

		const tableList = map(data.trainers, (item) => ({
			key: get(item, 'id'),
			name: get(item, 'name'),
			roles: get(item, 'roles'),
			briefDescription: get(item, 'briefDescription'),
			order: get(item, 'order'),
			updatedAt: dayjs(get(item, 'updatedAt')),
			urlSlug: get(item, 'urlSlug'),
			isVisibleOnLandingPage: get(item, 'isVisibleOnLandingPage'),
			isPublished: get(item, 'isPublished')
		}))

		dispatch({
			type: GET_INSTRUCTORS.DONE,
			payload: {
				rawList: data.trainers,
				tableList,
				context: data.context,
				isLastPage
			}
		})
	} catch (error) {
		dispatch({ type: GET_INSTRUCTORS.FAILED })
		Promise.reject(error)
	}
}

export const getInstructor = (id: number, onSuccess?: (args: GetTrainersIdPayload) => void, onFailure?: (args: any) => any) => async (dispatch: any) => {
	dispatch({ type: GET_INSTRUCTOR.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_INSTRUCTOR(id))
		dispatch({ type: GET_INSTRUCTOR.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_INSTRUCTOR.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteInstructor = (id: number, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_INSTRUCTOR.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_INSTRUCTOR(id))
		dispatch({ type: DELETE_INSTRUCTOR.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_INSTRUCTOR.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createInstructor = (body: PostTrainersBody, onSuccess?: (args: number) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: CREATE_INSTRUCTOR.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => query || undefined)
		const { data } = await postReq(ENDPOINTS.CREATE_INSTRUCTOR, undefined, normalizeQueryParams)
		dispatch({ type: CREATE_INSTRUCTOR.DONE })
		return onSuccess && onSuccess(get(data, 'data.id'))
	} catch (error) {
		dispatch({ type: CREATE_INSTRUCTOR.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateInstructor = (id: number, body: PutTrainersIdBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_INSTRUCTOR.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => query || undefined)
		await putReq(ENDPOINTS.UPDATE_INSTRUCTOR(id), undefined, normalizeQueryParams)
		dispatch({ type: UPDATE_INSTRUCTOR.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_INSTRUCTOR.FAILED })
		return onFailure && onFailure(error)
	}
}
