import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, putReq } from '../../utils/request'

// types
import {
	CHANGE_CHALLENGES_ORDER,
	CHANGE_PARTNERS_ORDER,
	CHANGE_TESTIMONIALS_ORDER,
	CREATE_CHALLENGE_RECORD,
	CREATE_PARTNER_RECORD,
	CREATE_TESTIMONIAL_RECORD,
	GET_LANDING_PAGE,
	REMOVE_CHALLENGE_RECORD,
	REMOVE_PARTNER_RECORD,
	REMOVE_TESTIMONIAL_RECORD,
	UPDATE_CHALLENGE_RECORD,
	UPDATE_LANDING_PAGE,
	UPDATE_PARTNER_RECORD,
	UPDATE_TESTIMONIAL_RECORD,
	UPDATE_PAGE_METADATA,
	GetLandingPayload
} from './types'

export const getLandingPage = (onSuccess?: (args: GetLandingPayload) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: GET_LANDING_PAGE.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_LANDING_PAGE)
		dispatch({ type: GET_LANDING_PAGE.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_LANDING_PAGE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updatePage = (id: number, values: any, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_LANDING_PAGE.START })
	try {
		const normalizeQueryParams = mapValues(values, (query) => {
			if (query === null) {
				return undefined
			}

			return query
		})
		await putReq(ENDPOINTS.UPDATE_PAGE(id), undefined, normalizeQueryParams)
		dispatch({ type: UPDATE_LANDING_PAGE.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_LANDING_PAGE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateChallenge = (id: number, values: any) => async (dispatch: any) => dispatch({ type: UPDATE_CHALLENGE_RECORD, payload: { id, values } })
export const createChallenge = (values: any) => async (dispatch: any) => dispatch({ type: CREATE_CHALLENGE_RECORD, payload: values })
export const updatePageMetaData = (values: any) => async (dispatch: any) => dispatch({ type: UPDATE_PAGE_METADATA, payload: values })
export const removeChallenge = (id: number) => async (dispatch: any) => dispatch({ type: REMOVE_CHALLENGE_RECORD, payload: id })
export const updatePartner = (id: number, values: any) => async (dispatch: any) => dispatch({ type: UPDATE_PARTNER_RECORD, payload: { id, values } })
export const createPartner = (values: any) => async (dispatch: any) => dispatch({ type: CREATE_PARTNER_RECORD, payload: values })
export const removePartner = (id: number) => async (dispatch: any) => dispatch({ type: REMOVE_PARTNER_RECORD, payload: id })
export const updateTestimonial = (id: number, values: any) => async (dispatch: any) => dispatch({ type: UPDATE_TESTIMONIAL_RECORD, payload: { id, values } })
export const createTestimonial = (values: any) => async (dispatch: any) => dispatch({ type: CREATE_TESTIMONIAL_RECORD, payload: values })
export const removeTestimonial = (id: number) => async (dispatch: any) => dispatch({ type: REMOVE_TESTIMONIAL_RECORD, payload: id })
export const changeTestimonialsOrder = (array: any) => async (dispatch: any) => dispatch({ type: CHANGE_TESTIMONIALS_ORDER, payload: array })
export const changeChallengesOrder = (array: any) => async (dispatch: any) => dispatch({ type: CHANGE_CHALLENGES_ORDER, payload: array })
export const changePartnersOrder = (array: any) => async (dispatch: any) => dispatch({ type: CHANGE_PARTNERS_ORDER, payload: array })
