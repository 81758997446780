import dayjs from 'dayjs'
import get from 'lodash/get'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { deleteReq, getReq, postReq, putReq } from '../../utils/request'

// types
import {
	GET_PREFERENCES,
	GET_PREFERENCE,
	DELETE_PREFERENCE,
	CREATE_PREFERENCE,
	UPDATE_PREFERENCE,
	GetPreferencesIdPayload,
	GetPreferencesQueryParams,
	PostPreferencesBody,
	PutPreferencesPreferenceIdBody
} from './types'

export const getPreferences = (params: GetPreferencesQueryParams) => async (dispatch: any) => {
	dispatch({ type: GET_PREFERENCES.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_PREFERENCES, normalizeQueryParams)

		const tableList = map(data.preferences, (item) => ({
			key: get(item, 'id'),
			description: get(item, 'description'),
			order: get(item, 'order'),
			title: get(item, 'title'),
			type: get(item, 'type'),
			updatedAt: dayjs(get(item, 'updatedAt')).format('D. MMM YYYY HH:mm')
		}))

		dispatch({
			type: GET_PREFERENCES.DONE,
			payload: {
				tableList,
				filterOptions: data.filterOptions,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_PREFERENCES.FAILED })
		Promise.reject(error)
	}
}

export const getPreference =
	(id: number, onSuccess?: (args: GetPreferencesIdPayload) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: GET_PREFERENCE.START })
		try {
			const { data } = await getReq(ENDPOINTS.GET_PREFERENCE(id))
			dispatch({ type: GET_PREFERENCE.DONE, payload: data })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_PREFERENCE.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const deletePreference = (id: number, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_PREFERENCE.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_PREFERENCE(id))
		dispatch({ type: DELETE_PREFERENCE.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_PREFERENCE.FAILED })
		return onFailure && onFailure(error)
	}
}

export const createPreference =
	(body: PostPreferencesBody, onSuccess?: (id: number) => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_PREFERENCE.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			const { data } = await postReq(ENDPOINTS.CREATE_PREFERENCE, undefined, normalizeQueryParams)
			dispatch({ type: CREATE_PREFERENCE.DONE })
			return onSuccess && onSuccess(get(data, 'data.id'))
		} catch (error) {
			dispatch({ type: CREATE_PREFERENCE.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const updatePreference =
	(id: number, body: PutPreferencesPreferenceIdBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: UPDATE_PREFERENCE.START })
		try {
			const normalizeQueryParams = mapValues(body, (query) => query || undefined)
			await putReq(ENDPOINTS.UPDATE_PREFERENCE(id), undefined, normalizeQueryParams)
			dispatch({ type: UPDATE_PREFERENCE.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: UPDATE_PREFERENCE.FAILED })
			return onFailure && onFailure(error)
		}
	}
