import { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import dayjs from 'dayjs'
import debounce from 'lodash/debounce'
import map from 'lodash/map'
import { Button, Col, Empty, Form, Input, Menu, Popconfirm, Row, Select, Table } from 'antd'
import { CloseCircleOutlined, DeleteOutlined, LinkOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { getExercises, deleteExercise } from '../../redux/exercises/actions'
import { getPrograms } from '../../redux/select/actions'
import { getSettings } from '../../redux/settings/settingsActions'
import { setFilters } from '../../redux/filters/actions'

// utils
import { VIDEO_DIFFICULTY, EMPTY_VALUE, INPUT_MAX_LENGTH, VIDEO_MEDIUM_DIFFICULTY } from '../../utils/enums'
import { getVideoDifficultyOptions, getVideoMediumDifficultyOptions, setFiltersForPage } from '../../utils/helpers'

// types
import { GetExercisesPayload, GetExercisesQueryParams } from '../../redux/exercises/types'

// atoms
import BooleanRepresent from '../../atoms/BooleanRepresent'
import DateRepresent from '../../atoms/DateRepresent'
import OrderRepresent from '../../atoms/OrderRepresent'
import { ArrElement } from '../../types/types'

const PAGE_SIZE = 20
let searchCancelToken

const { Option } = Select

enum FILTER_TYPE {
	EXERCISE_ALL = 'ALL',
	PROGRAM_EXERCISE = 'PROGRAM_EXERCISE',
	CHALLENGE_EXERCISE = 'CHALLENGE_EXERCISE',
	LIVESTREAM = 'LIVESTREAM'
}

const Exercises = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const dataSource = useSelector((state: RootState) => state.exercises?.list?.tableList)
	const isLoadingList = useSelector((state: RootState) => state.exercises?.list?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.exercises?.detail?.isLoading)
	const context = useSelector((state: RootState) => state.exercises?.list?.context)
	const programs = useSelector((state: RootState) => state.select?.programs?.list) || []

	// states
	const [filterType, setFilterType] = useState(FILTER_TYPE.EXERCISE_ALL)
	const [filter, setFilter] = useState(setFiltersForPage(t('paths:exercises|key'), persistFilter)) as any

	// constants
	const columns = [
		{
			title: t('Zhliadnutia'),
			dataIndex: 'views',
			key: 'views',
			align: 'center',
			sorter: true,
			width: 60,
			render: (value: GetExercisesPayload[0]['views']) => <OrderRepresent value={value} tooltip={t('Zhliadnutia')} />
		},
		{
			title: t('Obľúbené'),
			dataIndex: 'favouriteCount',
			key: 'favouriteCount',
			align: 'center',
			sorter: true,
			width: 60,
			render: (value: GetExercisesPayload[0]['favouriteCount']) => <OrderRepresent value={value} tooltip={t('Obľubené')} />
		},
		{
			title: t('Názov'),
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			ellipsis: true,
			className: 'col-exercise-name',
			render: (value: GetExercisesPayload[0]['name']) => value || EMPTY_VALUE
		},
		filterType === FILTER_TYPE.LIVESTREAM
			? {
					title: t('Dátum živeho prenosu'),
					dataIndex: 'liveStreamDate',
					key: 'liveStreamDate',
					ellipsis: true,
					render: (value: GetExercisesPayload[0]['liveStreamDate']) => <DateRepresent value={value} />
				}
			: {},
		{
			title: t('Náročnosť'),
			dataIndex: 'difficulty',
			key: 'difficulty',
			render: (value: ArrElement<GetExercisesPayload>['difficulty'], record: ArrElement<GetExercisesPayload>) => {
				switch (value) {
					case VIDEO_DIFFICULTY.EDUCATIONAL:
						return t('Náučná')
					case VIDEO_DIFFICULTY.EASY:
						return t('Ľahká')
					case VIDEO_DIFFICULTY.MEDIUM:
						if (record?.mediumDifficulty === VIDEO_MEDIUM_DIFFICULTY.LIGHT) return t('Stredne ľahká')
						if (record?.mediumDifficulty === VIDEO_MEDIUM_DIFFICULTY.HARD) return t('Stredne ťažká')
						return t('Stredná')
					case VIDEO_DIFFICULTY.HARD:
						return t('Náročná')
					default:
						return '---'
				}
			}
		},
		{
			title: t('Program'),
			dataIndex: 'program',
			key: 'program',
			ellipsis: true,
			render: (value: GetExercisesPayload[0]['program']) => value?.name || '---'
		},
		{
			title: t('Poradie'),
			dataIndex: 'order',
			sorter: true,
			key: 'order',
			align: 'center',
			width: 60,
			render: (value: GetExercisesPayload[0]['order']) => <OrderRepresent value={value} tooltip={t('Poradie')} />
		},
		{
			title: t('Publikované'),
			dataIndex: 'isPublished',
			sorter: true,
			key: 'isPublished',
			width: 110,
			align: 'center',
			render: (state: GetExercisesPayload[0]['isPublished'], record: GetExercisesPayload[0]) => (
				<BooleanRepresent isTrue={state && dayjs(new Date()) >= dayjs(record.publishDate)} />
			)
		},
		{
			title: t('Dátum poslednej úpravy'),
			dataIndex: 'updatedAt',
			sorter: true,
			key: 'updatedAt',
			width: 210,
			render: (value: GetExercisesPayload[0]['updatedAt']) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (_: any, record: any) => (
				<Row align={'middle'} gutter={8} wrap={false}>
					<Col>
						<Link to={`${t('paths:exercise|path')}/${record?.key}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</Col>
					<Col>
						<Popconfirm
							title={t('Skutočne chcete vymazať záznam?')}
							icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
							cancelText={t('Zrušiť')}
							okText={t('Vymazať')}
							onConfirm={(e: any) => {
								e.stopPropagation()
								dispatch(
									deleteExercise(record.key, () => {
										dispatch(
											getExercises({
												limit: PAGE_SIZE,
												page: 1,
												...filter
											})
										)
									})
								)
							}}
							onCancel={(e: any) => e.stopPropagation()}
							okButtonProps={{
								size: 'small',
								danger: true
							}}
							cancelButtonProps={{
								size: 'small',
								ghost: true
							}}
						>
							<Button type={'primary'} icon={<DeleteOutlined />} onClick={(e) => e.stopPropagation()} danger />
						</Popconfirm>
					</Col>
				</Row>
			)
		}
	]

	useEffect(() => {
		const body: GetExercisesQueryParams = {
			limit: PAGE_SIZE,
			page: 1,
			...filter,
			isLiveStream: filterType === FILTER_TYPE.LIVESTREAM,
			...(filterType !== FILTER_TYPE.LIVESTREAM && { type: filterType }),
			programs: filter?.programs
		}

		searchCancelToken = axios.CancelToken.source()
		dispatch(getExercises(body, searchCancelToken))

		dispatch(setFilters(t('paths:exercises|key'), body))
	}, [filter, filterType, dispatch, t])

	useEffect(() => {
		dispatch(
			getPrograms({
				limit: 1000000
			})
		)
		dispatch(getSettings())
	}, [dispatch])

	const debounced = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[]
	)

	const handleOnChange = (e: any) => debounced(e.target.value)

	const onChangeLSSwitch = (value: FILTER_TYPE) => setFilterType(value)

	const handleTableChange = (pagination: any, filters: object[], sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Input.Search defaultValue={filter?.search} style={{ width: 300 }} maxLength={INPUT_MAX_LENGTH} onChange={handleOnChange} allowClear />
				</Form.Item>
				<Form.Item>
					<Button icon={<PlusCircleOutlined />} href={t('paths:exerciseCreate|path')} type={'primary'}>
						{t('Pridať cvičenie')}
					</Button>
				</Form.Item>
			</div>
			<div className={'flex'} style={{ flexWrap: 'wrap' }}>
				<Form.Item>
					<Select
						style={{ width: 200, marginRight: 20 }}
						onChange={(value) => setFilter({ ...filter, programs: value, page: 1 })}
						placeholder={t('Výzvy a programy')}
						value={filter.programs}
						mode={'multiple'}
						filterOption={(input: any, option: any) => option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
					>
						{map(programs, (program: GetExercisesPayload[0]['program'], index: number) => (
							<Option key={`program-filter-${index}`} value={program?.id}>
								{program?.name}
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item>
					<Select
						style={{ width: 150, marginRight: 20 }}
						onChange={(value) => setFilter({ ...filter, difficulty: value, mediumDifficulty: undefined, page: 1 })}
						placeholder={t('Obtiažnosť')}
						value={filter.difficulty}
						options={getVideoDifficultyOptions(t)}
						allowClear
					/>
				</Form.Item>
				{filter?.difficulty === VIDEO_DIFFICULTY.MEDIUM && (
					<Form.Item>
						<Select
							style={{ width: 220, marginRight: 20 }}
							onChange={(value) => setFilter({ ...filter, mediumDifficulty: value, page: 1 })}
							placeholder={t('Úroveň strednej obtiažnosti')}
							value={filter.mediumDifficulty}
							options={getVideoMediumDifficultyOptions(t)}
							allowClear
						/>
					</Form.Item>
				)}
				<Form.Item>
					<Button style={{ marginBottom: 20 }} icon={<CloseCircleOutlined />} onClick={() => setFilter({})}>
						{t('Zrušiť filter')}
					</Button>
				</Form.Item>
			</div>
			<Menu
				style={{ marginTop: -20 }}
				onClick={(menu) => {
					onChangeLSSwitch(menu.key as FILTER_TYPE)
				}}
				selectedKeys={[filterType === FILTER_TYPE.LIVESTREAM ? FILTER_TYPE.LIVESTREAM : FILTER_TYPE.EXERCISE_ALL]}
				mode={'horizontal'}
				items={[
					{
						key: FILTER_TYPE.EXERCISE_ALL,
						label: t('Cvičenia')
					},
					{
						key: FILTER_TYPE.LIVESTREAM,
						label: t('Živé prenosy')
					}
				]}
			/>
			{filterType !== FILTER_TYPE.LIVESTREAM && (
				<Menu
					onClick={(menu) => {
						onChangeLSSwitch(menu.key as FILTER_TYPE)
					}}
					selectedKeys={[filterType]}
					mode={'horizontal'}
					items={[
						{
							key: FILTER_TYPE.EXERCISE_ALL,
							label: t('Všetky cvičenia')
						},
						{
							key: FILTER_TYPE.PROGRAM_EXERCISE,
							label: t('Cvičenia z programov')
						},
						{
							key: FILTER_TYPE.CHALLENGE_EXERCISE,
							label: t('Cvičenia z výziev')
						}
					]}
				/>
			)}
			<Table
				className={'general-table exercise-list-table'}
				columns={columns as any}
				dataSource={dataSource}
				onChange={handleTableChange as any}
				showSorterTooltip={false}
				scroll={{ x: 'max-content' }}
				pagination={{
					pageSize: PAGE_SIZE,
					total: context?.totalCount,
					current: context?.page,
					showSizeChanger: false
				}}
				loading={isLoadingList || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:exercise|path')}/${record?.key}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

export default Exercises
