import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Modal, Row } from 'antd'
import { useTranslation } from 'react-i18next'

// utils
import { FORMS } from '../../../utils/enums'
import { onlyNumbers } from '../../../utils/helpers'

// validators
import validateGIFForm, { ThumbnailGIFFormValues } from './validateGIFForm'

// components
import TextField from '../../../atoms/form/TextField'

type ThumbnailGIFFormProps = {
	isVisible: boolean
	setIsModalVisible: (value: boolean) => void
}

const ThumbnailGIFForm = ({
	handleSubmit,
	isVisible,
	setIsModalVisible
}: ThumbnailGIFFormProps & InjectedFormProps<ThumbnailGIFFormValues, ThumbnailGIFFormProps>) => {
	const { t } = useTranslation()

	return (
		<Modal
			visible={isVisible}
			title={t('Generovanie animovaného thumbnailu')}
			okText={t('Generovať')}
			cancelText={t('Zavrieť')}
			className={'thumbnail-generator-modal'}
			width={400}
			onCancel={() => setIsModalVisible(false)}
			onOk={handleSubmit}
		>
			<p>{t('Zadajte v sekundách časovú stopu začiatku a trvanie animácie (maximálne 6 sekúnd)')}</p>
			<form onSubmit={handleSubmit}>
				<Row style={{ flexWrap: 'nowrap' }}>
					<Field
						name={'start'}
						type={'number'}
						component={TextField}
						label={t('Začiatok (sec)')}
						required
						onKeyDown={(e: any) => onlyNumbers(e, true)}
						step={0.01}
					/>

					<Field
						name={'duration'}
						type={'number'}
						component={TextField}
						label={t('Trvanie (sec)')}
						required
						onKeyDown={(e: any) => onlyNumbers(e, true)}
						step={0.01}
					/>
				</Row>
			</form>
			<p className={'hint'}>{t('Generovanie GIF thumbnailu môže trvať niekolko minút, prosím nevypínajte stránku počas generovania')}</p>
			<p className={'hint'}>{t('V prípade ukončenia procesu počas generácie bude nutné odstrániť thumbnail priamo na stránkach vimeo')}</p>
		</Modal>
	)
}

export default reduxForm<ThumbnailGIFFormValues, ThumbnailGIFFormProps>({
	form: FORMS.GENERATE_GIF_THUMBNAIL,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateGIFForm
})(ThumbnailGIFForm)
