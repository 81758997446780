import get from 'lodash/get'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { postReq, getReq, patchReq, deleteReq, putReq } from '../../utils/request'
import { setAccessToken } from '../../utils/auth'

// types
import { PostPaymentsUserIdInvoice } from '../payments/types'
import {
	CHANGE_PASSWORD,
	ADD_FREE_SUBSCRIPTION,
	TRANSFER_SUBSCRIPTION,
	GET_PROFILE,
	LOGOUT_USER,
	SET_NEW_PASSWORD_DONE,
	CREATE_CUSTOM_PAYMENT,
	DELETE_SUBSCRIPTION,
	GENERATE_TEMP_PASSWORD,
	GetProfilePayload,
	PostAuthorizationLoginPayload,
	CANCEL_SUBSCRIPTION,
	PostAuthorizationLoginBody,
	PostAuthorizationRegistrationConfirmBody,
	PatchAuthorizationPasswordBody,
	PostAuthorizationChangePasswordBody,
	PutUsersUserIdPasswordBody,
	PostUsersIdSubscriptionsBody,
	PutUsersUserIdSubscriptionsTransferBody
} from './userTypes'

export const getUser = (onSuccess?: (args: GetProfilePayload) => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: GET_PROFILE.START })
	try {
		const { data } = await getReq(ENDPOINTS.PROFILE)
		dispatch({ type: GET_PROFILE.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_PROFILE.FAILED })
		return onFailure && onFailure()
	}
}

export const loginUser =
	(body: PostAuthorizationLoginBody, onSuccess?: (args: PostAuthorizationLoginPayload) => void, onFailure?: () => void) => async (dispatch: any) => {
		try {
			const { data } = await postReq(ENDPOINTS.LOGIN, undefined, body, undefined)
			setAccessToken(get(data, 'data.accessToken'))
			dispatch(getUser())
			return onSuccess && onSuccess(data)
		} catch (error) {
			return onFailure && onFailure()
		}
	}

export const setNewPassword =
	(body: PostAuthorizationRegistrationConfirmBody, token: string, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
		try {
			await postReq(ENDPOINTS.REGISTRATION_CONFIRM, undefined, body, { Authorization: `Bearer ${token}` } as any)
			dispatch({ type: SET_NEW_PASSWORD_DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			return onFailure && onFailure()
		}
	}

export const resetPassword = (body: PatchAuthorizationPasswordBody, token: string, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
	try {
		await patchReq(ENDPOINTS.RESET_PASSWORD, undefined, body, { Authorization: `Bearer ${token}` } as any)
		dispatch({ type: SET_NEW_PASSWORD_DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		return onFailure && onFailure()
	}
}

export const changePassword = (body: PostAuthorizationChangePasswordBody, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: CHANGE_PASSWORD.START })
	try {
		await postReq(ENDPOINTS.ADMIN_CHANGE_PASSWORD, undefined, body)
		dispatch({ type: CHANGE_PASSWORD.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: CHANGE_PASSWORD.FAILED })
		return onFailure && onFailure()
	}
}

export const changeUserPasswordByAdmin =
	(userID: number, body: PutUsersUserIdPasswordBody, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
		dispatch({ type: CHANGE_PASSWORD.START })
		try {
			await putReq(ENDPOINTS.ADMIN_CHANGE_USER_PASSWORD(userID), undefined, body)
			dispatch({ type: CHANGE_PASSWORD.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: CHANGE_PASSWORD.FAILED })
			return onFailure && onFailure()
		}
	}

export const addSubscription =
	(body: PostUsersIdSubscriptionsBody & { userID: number }, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
		dispatch({ type: ADD_FREE_SUBSCRIPTION.START })
		try {
			await postReq(ENDPOINTS.ADD_SUBSCRIPTION(body.userID), undefined, body)
			dispatch({ type: ADD_FREE_SUBSCRIPTION.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: ADD_FREE_SUBSCRIPTION.FAILED })
			return onFailure && onFailure()
		}
	}

export const transferSubscription =
	(userID: number, body: PutUsersUserIdSubscriptionsTransferBody, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
		dispatch({ type: TRANSFER_SUBSCRIPTION.START })
		try {
			await putReq(ENDPOINTS.TRANSFER_SUBSCRIPTION(userID), undefined, body)
			dispatch({ type: TRANSFER_SUBSCRIPTION.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: TRANSFER_SUBSCRIPTION.FAILED })
			return onFailure && onFailure()
		}
	}

export const createPayment =
	(userID: number, body: PostPaymentsUserIdInvoice, onSuccess?: (args: string) => void, onFailure?: () => void) => async (dispatch: any) => {
		dispatch({ type: CREATE_CUSTOM_PAYMENT.START })
		try {
			const { data } = await postReq(ENDPOINTS.CREATE_CUSTOM_PAYMENT(userID), undefined, body)
			dispatch({ type: CREATE_CUSTOM_PAYMENT.DONE })
			return onSuccess && onSuccess(data?.data?.url)
		} catch (error) {
			dispatch({ type: CREATE_CUSTOM_PAYMENT.FAILED })
			return onFailure && onFailure()
		}
	}

export const cancelSubscription = (userId: number, subscriptionId: number, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: CANCEL_SUBSCRIPTION.START })
	try {
		await postReq(ENDPOINTS.CANCEL_SUBSCRIPTION(userId, subscriptionId))
		dispatch({ type: CANCEL_SUBSCRIPTION.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: CANCEL_SUBSCRIPTION.FAILED })
		return onFailure && onFailure()
	}
}

export const deleteSubscription = (userId: number, subscriptionId: number, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_SUBSCRIPTION.START })
	try {
		await deleteReq(ENDPOINTS.REMOVE_SUBSCRIPTION(userId, subscriptionId))
		dispatch({ type: DELETE_SUBSCRIPTION.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_SUBSCRIPTION.FAILED })
		return onFailure && onFailure()
	}
}

export const logoutUser = (onSuccess?: () => void) => async (dispatch: any) => {
	dispatch({ type: LOGOUT_USER })
	return onSuccess && onSuccess()
}

export const generateTempPassword = (userID: number, onSuccess?: (args: string) => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: GENERATE_TEMP_PASSWORD.START })
	try {
		const { data } = await postReq(ENDPOINTS.SWITCH_USER(userID))
		dispatch({ type: GENERATE_TEMP_PASSWORD.DONE })
		return onSuccess && onSuccess(data.data.password)
	} catch (error) {
		dispatch({ type: GENERATE_TEMP_PASSWORD.FAILED })
		return onFailure && onFailure()
	}
}
