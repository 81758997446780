import dayjs from 'dayjs'
import map from 'lodash/map'
import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, postReq, putReq, deleteReq } from '../../utils/request'

// types
import {
	GET_BLOG_POSTS,
	GET_BLOG_POST,
	DELETE_BLOG_POST,
	CREATE_BLOG_POST,
	UPDATE_BLOG_POST,
	RESTORE_BLOG_POST,
	DUPLICATE_BLOG_POST,
	GetBlogPostsIdPayload,
	PostBlogPostsIdDuplicatePayload,
	PostBlogPostsIdRestorePayload,
	GetBlogPostsQueryParams,
	PostBlogPostsBody,
	PutBlogPostsIdBody
} from './types'

export const getBlogPosts = (params: GetBlogPostsQueryParams) => async (dispatch: any) => {
	dispatch({ type: GET_BLOG_POSTS.START })
	try {
		const queries = {
			limit: 20,
			page: 1,
			...params
		}

		const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_BLOG_POSTS, normalizeQueryParams)

		const tableList = map(data.blogPosts, (item) => ({
			key: item?.id,
			title: item?.title,
			author: item?.author,
			type: item?.type,
			isPublished: item?.isPublished,
			isDeleted: item?.isDeleted || false,
			category: map(item?.category, (category) => category.name),
			publishedAt: item?.publishedAt === 0 ? null : dayjs(item?.publishedAt),
			updatedAt: dayjs(item?.updatedAt),
			language: item?.language
		}))

		dispatch({
			type: GET_BLOG_POSTS.DONE,
			payload: {
				tableList,
				context: data.context
			}
		})
	} catch (error) {
		dispatch({ type: GET_BLOG_POSTS.FAILED })
		Promise.reject(error)
	}
}

export const getBlogPost = (id: number, onSuccess?: (args: GetBlogPostsIdPayload) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: GET_BLOG_POST.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_ADMIN_BLOG_POST(id))
		dispatch({ type: GET_BLOG_POST.DONE, payload: data })
		return onSuccess && onSuccess(data)
	} catch (error) {
		dispatch({ type: GET_BLOG_POST.FAILED })
		return onFailure && onFailure(error)
	}
}

export const deleteBlogPost = (id: number, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: DELETE_BLOG_POST.START })
	try {
		await deleteReq(ENDPOINTS.DELETE_BLOG_POST(id))
		dispatch({ type: DELETE_BLOG_POST.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: DELETE_BLOG_POST.FAILED })
		return onFailure && onFailure(error)
	}
}

export const duplicateBlogPost =
	(id: number, onSuccess?: (args: PostBlogPostsIdDuplicatePayload) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: DUPLICATE_BLOG_POST.START })
		try {
			const { data } = await postReq(ENDPOINTS.DUPLICATE_BLOG_POST(id))
			dispatch({ type: DUPLICATE_BLOG_POST.DONE })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: DUPLICATE_BLOG_POST.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const restoreBlogPost =
	(id: number, onSuccess?: (args: PostBlogPostsIdRestorePayload) => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
		dispatch({ type: RESTORE_BLOG_POST.START })
		try {
			const { data } = await postReq(ENDPOINTS.RESTORE_BLOG_POST(id))
			dispatch({ type: RESTORE_BLOG_POST.DONE })
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: RESTORE_BLOG_POST.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const createBlogPost = (body: PostBlogPostsBody, onSuccess?: (args: number) => any, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: CREATE_BLOG_POST.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => {
			if (query === null) {
				return undefined
			}

			return query
		})

		const { data } = await postReq(ENDPOINTS.CREATE_BLOG_POST, undefined, normalizeQueryParams)
		dispatch({ type: CREATE_BLOG_POST.DONE })
		return onSuccess && onSuccess(data?.data?.id)
	} catch (error) {
		dispatch({ type: CREATE_BLOG_POST.FAILED })
		return onFailure && onFailure(error)
	}
}

export const updateBlogPost = (id: number, body: PutBlogPostsIdBody, onSuccess?: () => void, onFailure?: (args: any) => void) => async (dispatch: any) => {
	dispatch({ type: UPDATE_BLOG_POST.START })
	try {
		const normalizeQueryParams = mapValues(body, (query) => {
			if (query === null) {
				return undefined
			}

			return query
		})

		await putReq(ENDPOINTS.UPDATE_BLOG_POST(id), undefined, normalizeQueryParams)
		dispatch({ type: UPDATE_BLOG_POST.DONE })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: UPDATE_BLOG_POST.FAILED })
		return onFailure && onFailure(error)
	}
}
