// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetTargetPayload = RequestResponse<GetUrls['/admin/target/']>['targets']
export type GetTargetIdPayload = RequestResponse<GetUrls['/admin/target/{id}']>

// query params
export type GetTargetQueryParams = RequestParams<GetUrls['/admin/target/']>['query']

// bodies
export type PostTargetBody = RequestPayload<PostUrls['/admin/target/']>
export type PutTargetIdBody = RequestPayload<PutUrls['/admin/target/{id}']>

export enum GET_EXERCISE_TARGETS {
	START = 'GET_EXERCISE_TARGETS_START',
	DONE = 'GET_EXERCISE_TARGETS_DONE',
	FAILED = 'GET_EXERCISE_TARGETS_FAILED'
}

export enum GET_EXERCISE_TARGET {
	START = 'GET_EXERCISE_TARGET_START',
	DONE = 'GET_EXERCISE_TARGET_DONE',
	FAILED = 'GET_EXERCISE_TARGET_FAILED'
}

export enum DELETE_EXERCISE_TARGET {
	START = 'DELETE_EXERCISE_TARGET_START',
	DONE = 'DELETE_EXERCISE_TARGET_DONE',
	FAILED = 'DELETE_EXERCISE_TARGET_FAILED'
}

export enum CREATE_EXERCISE_TARGET {
	START = 'CREATE_EXERCISE_TARGET_START',
	DONE = 'CREATE_EXERCISE_TARGET_DONE',
	FAILED = 'CREATE_EXERCISE_TARGET_FAILED'
}

export enum UPDATE_EXERCISE_TARGET {
	START = 'UPDATE_EXERCISE_TARGET_START',
	DONE = 'UPDATE_EXERCISE_TARGET_DONE',
	FAILED = 'UPDATE_EXERCISE_TARGET_FAILED'
}
