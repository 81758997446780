import { useCallback, useEffect, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import { Spin } from 'antd'
import { change, getFormValues, initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createPage, getPage, updatePage } from '../../redux/pages/actions'

// utils
import { FORMS, LANGUAGE, LANGUAGES, PAGE_TYPE } from '../../utils/enums'
import { ITEM_TYPES } from '../../components/articleBuilder/enums/enums'

// types
import { GetPagesPageIdPayload, PostPagesBody, PutPagesPageIdBody } from '../../redux/pages/types'
import { IComputedMatch } from '../../types/interfaces'
import { PageFormValues } from './forms/validatePageForm'

// forms
import PageForm from './forms/PageForm'

// components
import { deserializeHTML } from '../../components/articleBuilder/customComponents/WYSIWYG'

type DetailPageProps = {
	computedMatch: IComputedMatch
}

const DetailPage = ({ computedMatch }: DetailPageProps) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.PAGE_FORM)(state)) as PageFormValues
	const detail = useSelector((state: RootState) => state.pages?.detail)

	// states
	const [areaState, setAreaState] = useState<any>(null)

	// constants
	const { id } = computedMatch.params
	const isLoading = detail?.isLoading

	useEffect(() => {
		if (!fieldValues?.contentUpdate) {
			dispatch(change(FORMS.PAGE_FORM, 'contentUpdate', true))
		}
	}, [areaState, dispatch, fieldValues])

	const initDetailForm = useCallback(
		(initData: GetPagesPageIdPayload) => {
			const data = initData || detail?.data
			const langData = initData?.pageMetadata?.translations

			const initValues = {
				...data,
				image: get(data, 'image'),
				language: get(data, 'language'),
				isPublished: get(data, 'isPublished'),
				updatedAt: dayjs(get(data, 'updatedAt')).format('H:mm:ss D. MMM YYYY '),
				contentUpdate: false
			}

			const langValues: any = {}
			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`title_${item}`] = get(lang, 'title')
				langValues[`description_${item}`] = get(lang, 'description')
				langValues[`ogTitle_${item}`] = get(lang, 'ogTitle')
				langValues[`ogDescription_${item}`] = get(lang, 'ogDescription')
			})

			delete initValues.content

			const content = cloneDeep(get(data, 'content'))
			if (content?.items) {
				forEach(content.items, (item: any) => {
					if (item?.type === ITEM_TYPES.TEXT && item?.content?.editor) {
						// eslint-disable-next-line no-param-reassign
						item.content.editor = deserializeHTML(get(item, 'content.html', ''))
					}
				})
			}

			dispatch(initialize(FORMS.PAGE_FORM, { ...initValues, ...langValues }))
			setAreaState(content)
		},
		[dispatch, detail]
	)

	const initEmptyForm = useCallback(() => {
		dispatch(initialize(FORMS.PAGE_FORM, { contentUpdate: false }))
	}, [dispatch])

	useEffect(() => {
		if (id) {
			dispatch(getPage(id, (data) => initDetailForm(data)))
		} else {
			initEmptyForm()
		}
	}, [dispatch, id])

	const handleCreate = (body: PostPagesBody) => dispatch(createPage(body, (pageID) => history.push(`${t('paths:pageDetail|path')}/${pageID}`)))

	const handleUpdate = (body: PutPagesPageIdBody) => dispatch(updatePage(id, body, () => dispatch(getPage(id, (data) => initDetailForm(data)))))

	const handleSubmit = (values: PageFormValues) => {
		const body: any = {
			title: values?.title,
			language: values?.language,
			urlSlug: values?.urlSlug,
			isPublished: values?.isPublished || false,
			image: values?.image,
			content: areaState,
			pageMetadata: {
				translations: []
			}
		}

		each(LANGUAGES, (item: LANGUAGE) => {
			if (get(values, `title_${item}`) || get(values, `ogTitle_${item}`) || get(values, `description_${item}`) || get(values, `ogDescription_${item}`)) {
				body.pageMetadata.translations.push({
					title: get(values, `title_${item}`),
					ogTitle: get(values, `ogTitle_${item}`),
					description: get(values, `description_${item}`),
					ogDescription: get(values, `ogDescription_${item}`),
					language: item
				})
			}
		})

		if (body.image) {
			body.imageTitle = get(values, 'imageTitle')
		}

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<PageForm
					onSubmit={handleSubmit}
					onUpdateForm={(areaData) => setAreaState(areaData)}
					isCreate={!id}
					pageBuilderData={areaState}
					editableSlug={get(detail, 'data.pageType') === PAGE_TYPE.DYNAMIC || !id}
				/>
			</Spin>
		</div>
	)
}

export default DetailPage
