import find from 'lodash/find'
import i18next from 'i18next'
import { Button, Empty, Popconfirm, Table } from 'antd'
import { DeleteOutlined, MenuOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { changeTestimonialsOrder, removeTestimonial } from '../../../redux/landing/actions'

// utils
import { EMPTY_VALUE, LANDING_STATES, LANGUAGE } from '../../../utils/enums'

// types
import { ILandingModalState } from '../../../types/interfaces'
import { GetLandingPayload } from '../../../redux/landing/types'

const SortHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />)
const SortItem = SortableElement((props: any) => <tr {...props} />)
const SortContainer = SortableContainer((props: any) => <tbody {...props} />)

type TestimonialsTableProps = {
	setModalState: (args: ILandingModalState) => void
	testimonials?: any[]
	isLoading?: boolean
}

const TestimonialsTable = ({ setModalState, testimonials = [], isLoading = true }: TestimonialsTableProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const columns = [
		{
			dataIndex: 'sort',
			width: 30,
			className: 'drag-visible',
			render: () => <SortHandle />
		},
		{
			title: i18next.t('Názov'),
			dataIndex: 'translations',
			key: 'translations',
			ellipsis: true,
			render: (_: any, record: NonNullable<GetLandingPayload['testimonials']>[0]) =>
				find(record?.translations, ['language', LANGUAGE.SK])?.title || EMPTY_VALUE
		},
		{
			title: i18next.t('Zákazník'),
			dataIndex: 'customerName',
			key: 'customerName',
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (_: any, record: NonNullable<GetLandingPayload['testimonials']>[0]) => (
				<Popconfirm
					title={t('Skutočne chcete vymazať záznam?')}
					icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
					cancelText={t('Zrušiť')}
					okText={t('Vymazať')}
					onConfirm={(e: any) => {
						e.stopPropagation()
						dispatch(removeTestimonial(record.id))
					}}
					onCancel={(e: any) => e.stopPropagation()}
					okButtonProps={{
						size: 'small',
						type: 'primary',
						danger: true
					}}
					cancelButtonProps={{
						size: 'small',
						type: 'ghost'
					}}
				>
					<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
				</Popconfirm>
			)
		}
	]

	const onSortEnd = ({ oldIndex, newIndex }: any) => {
		if (oldIndex !== newIndex) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const newData = arrayMoveImmutable([].concat(testimonials), oldIndex, newIndex).filter((el) => !!el)
			dispatch(changeTestimonialsOrder(newData))
		}
	}

	// eslint-disable-next-line react/prop-types
	const DraggableBodyRow = ({ ...restProps }) => {
		const index = testimonials.findIndex((x: any) => x.order === restProps['data-row-key'])
		return <SortItem index={index} {...restProps} />
	}

	const DraggableContainer = (props: any) => <SortContainer useDragHandle helperClass={'row-dragging'} onSortEnd={onSortEnd} {...props} />

	return (
		<div className={'page-wrapper'}>
			<Table
				className={'general-table'}
				columns={columns as any}
				dataSource={testimonials}
				loading={isLoading}
				rowKey={'order'}
				style={{ marginTop: 10 }}
				onRow={(record) => ({
					onClick: () => setModalState({ open: LANDING_STATES.TESTIMONIAL_EDIT, id: record?.id })
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
				pagination={false}
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow
					}
				}}
			/>
		</div>
	)
}

export default TestimonialsTable
