// utils
import { GetUrls, PatchUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetTranslationsPayload = RequestResponse<GetUrls['/admin/translations/']>['translations']

// query params
export type GetTranslationsQueryParams = RequestParams<GetUrls['/admin/translations/']>['query']

// bodies
export type PatchTranslationsBody = RequestPayload<PatchUrls['/admin/translations/']>

export enum GET_TRANSLATIONS {
	START = 'GET_TRANSLATIONS_START',
	DONE = 'GET_TRANSLATIONS_DONE',
	FAILED = 'GET_TRANSLATIONS_FAILED'
}

export enum UPDATE_TRANSLATION {
	START = 'UPDATE_TRANSLATION_START',
	DONE = 'UPDATE_TRANSLATION_DONE',
	FAILED = 'UPDATE_TRANSLATION_FAILED'
}
