import debounce from 'lodash/debounce'
import get from 'lodash/get'
import i18next from 'i18next'
import { Button, Empty, Input, Table, Tooltip } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { SendOutlined } from '@ant-design/icons'
import { change, getFormValues } from 'redux-form'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DonationFormValues } from './forms/validateDonationForm'
import { GetSubscriptionsDonations } from '../../redux/donations/types'
import { FORMS, INPUT_MAX_LENGTH } from '../../utils/enums'
import { RootState } from '../../redux'
import { getDonations, resendDonation } from '../../redux/donations/actions'
import { setFilters } from '../../redux/filters/actions'
import { setFiltersForPage } from '../../utils/helpers'
import ResendModalForm from './forms/DonationResendEmailForm'
import DateRepresent from '../../atoms/DateRepresent'

const PAGE_SIZE = 20

const Donations = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.DONATION_EMAIL_FORM)(state)) as DonationFormValues
	const persistFilter = useSelector((state: RootState) => state.filters)
	const dataSource = useSelector((state: RootState) => state.donations?.list?.donations)
	const isLoadingList = useSelector((state: RootState) => state.donations?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.donations?.detail?.isLoading)
	const context = useSelector((state: RootState) => state.donations?.list?.context)

	// states
	const [filter, setFilter] = useState<any>(setFiltersForPage(t('paths:donations|key'), persistFilter))

	const [modalState, setModalState] = useState<{ visible: boolean; id: number | null }>({
		visible: false,
		id: null
	})

	const columns = [
		{
			title: i18next.t('Darca'),
			dataIndex: 'donorEmail',
			key: 'donorEmail',
			render: (value: string, row: NonNullable<GetSubscriptionsDonations>[0]) => (
				<div>
					<span>{row?.donorName}</span>
					<br />
					<strong>{value}</strong>
				</div>
			)
		},
		{
			title: i18next.t('Obdarovaný'),
			dataIndex: 'recipientEmail',
			key: 'recipientEmail',
			render: (value: string, row: NonNullable<GetSubscriptionsDonations>[0]) => (
				<div>
					<span>{row?.recipientName}</span>
					<br />
					<strong>{value}</strong>
				</div>
			)
		},
		{
			title: i18next.t('Zakúpený'),
			dataIndex: 'purchasedAt',
			key: 'purchasedAt',
			width: 150,
			render: (value: string) => <DateRepresent value={value} />
		},
		{
			title: i18next.t('Uplatnený'),
			dataIndex: 'activatedAt',
			key: 'activatedAt',
			width: 150,
			render: (value: string, row: NonNullable<GetSubscriptionsDonations>[0]) =>
				row?.isUsed ? <DateRepresent value={value} /> : <DateRepresent text={t('Neuplatnený')} color={'red'} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 60,
			render: (_: any, row: NonNullable<GetSubscriptionsDonations>[0]) => (
				<span style={{ marginRight: '5px' }}>
					<Tooltip title={row?.isUsed ? t('Po uplatnení promo kódu už nie je možné opätovne odoslať e-mail') : null} placement={'bottomLeft'}>
						<Link to={`${t('paths:donations|path')}/${get(row, 'id')}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button
								icon={<SendOutlined />}
								type={'primary'}
								disabled={row?.isUsed}
								onClick={(e) => {
									e.stopPropagation()
									e.preventDefault()
									dispatch(change(FORMS.DONATION_EMAIL_FORM, 'activationEmail', get(row, 'recipientEmail')))
									setModalState({
										visible: true,
										id: row?.id
									})
								}}
							/>
						</Link>
					</Tooltip>
				</span>
			)
		}
	]

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}
		dispatch(getDonations(body))

		dispatch(setFilters(t('paths:donations|key'), body))
	}, [filter, dispatch, t])

	const resendEmail = async () => {
		if (modalState?.id) {
			dispatch(resendDonation(modalState.id, { email: fieldValues?.activationEmail } as any))
		}
		setModalState({ visible: false, id: null })
	}

	const debounced = useCallback(
		debounce((searchTerm) => setFilter({ ...filter, search: searchTerm, page: 1 }), 300),
		[filter]
	)

	const handleOnChange = (e: any) => {
		debounced(e.target.value)
	}

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-between'} style={{ marginBottom: 24 }}>
				<Input.Search
					onChange={handleOnChange}
					defaultValue={filter?.search}
					style={{ width: 520 }}
					allowClear
					maxLength={INPUT_MAX_LENGTH}
					placeholder={t('Zadaj e-mail darcu, alebo e-mail obdarovanej')}
				/>
			</div>
			<Table
				className={'general-table'}
				columns={columns as any}
				dataSource={dataSource}
				onChange={handleTableChange}
				showSorterTooltip={false}
				pagination={{
					pageSize: PAGE_SIZE,
					total: get(context, 'totalCount'),
					current: get(context, 'page'),
					showSizeChanger: false
				}}
				loading={isLoadingList || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:donations|path')}/${get(record, 'id')}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
			<ResendModalForm modalState={modalState} setModal={setModalState} onSubmit={resendEmail} />
		</div>
	)
}

Donations.propTypes = {}

export default Donations
