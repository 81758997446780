// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// types
import { ArrElement } from '../../types/types'

// payloads
export type GetAdminProgramsPayload = RequestResponse<GetUrls['/admin/programs/']>['programs']
export type GetProgramsListItemPayload = ArrElement<GetAdminProgramsPayload>
export type GetProgramsIdPayload = RequestResponse<GetUrls['/admin/programs/{id}']>
export type GetChallengesIdPayload = RequestResponse<GetUrls['/admin/challenges/{id}']>

// query params
export type GetProgramsQueryParams = RequestParams<GetUrls['/admin/programs/']>['query']
export type GetAdminProgramsIdUsersQueryParams = RequestParams<GetUrls['/admin/programs/{id}/users']>['query']

// bodies
export type PostProgramsBody = RequestPayload<PostUrls['/admin/programs/']>
export type PostChallengesBody = RequestPayload<PostUrls['/admin/challenges/']>
export type PutProgramsIdBody = RequestPayload<PutUrls['/admin/programs/{id}']>
export type PutChallengesIdBody = RequestPayload<PutUrls['/admin/challenges/{id}']>

export enum GET_PROGRAMS {
	START = 'GET_PROGRAMS_START',
	DONE = 'GET_PROGRAMS_DONE',
	FAILED = 'GET_PROGRAMS_FAILED'
}

export enum GET_PROGRAM {
	START = 'GET_PROGRAM_START',
	DONE = 'GET_PROGRAM_DONE',
	FAILED = 'GET_PROGRAM_FAILED'
}

export enum GET_PROGRAM_USERS {
	START = 'GET_PROGRAM_USERS_START',
	DONE = 'GET_PROGRAM_USERS_DONE',
	FAILED = 'GET_PROGRAM_USERS_FAILED'
}

export enum LOAD_CHALLENGE {
	START = 'LOAD_CHALLENGE_START',
	DONE = 'LOAD_CHALLENGE_DONE',
	FAILED = 'LOAD_CHALLENGE_FAILED'
}

export enum DELETE_PROGRAM {
	START = 'DELETE_PROGRAM_START',
	DONE = 'DELETE_PROGRAM_DONE',
	FAILED = 'DELETE_PROGRAM_FAILED'
}

export enum DELETE_CHALLENGE {
	START = 'DELETE_CHALLENGE_START',
	DONE = 'DELETE_CHALLENGE_DONE',
	FAILED = 'DELETE_CHALLENGE_FAILED'
}

export enum CREATE_PROGRAM {
	START = 'CREATE_PROGRAM_START',
	DONE = 'CREATE_PROGRAM_DONE',
	FAILED = 'CREATE_PROGRAM_FAILED'
}

export enum CREATE_CHALLENGE {
	START = 'CREATE_CHALLENGE_START',
	DONE = 'CREATE_CHALLENGE_DONE',
	FAILED = 'CREATE_CHALLENGE_FAILED'
}

export enum UPDATE_PROGRAM {
	START = 'UPDATE_PROGRAM_START',
	DONE = 'UPDATE_PROGRAM_DONE',
	FAILED = 'UPDATE_PROGRAM_FAILED'
}

export enum UPDATE_CHALLENGE {
	START = 'UPDATE_CHALLENGE_START',
	DONE = 'UPDATE_CHALLENGE_DONE',
	FAILED = 'UPDATE_CHALLENGE_FAILED'
}

export enum EXPORT_CHALLENGE_USERS {
	START = 'EXPORT_CHALLENGE_USERS_START',
	DONE = 'EXPORT_CHALLENGE_USERS_DONE',
	FAILED = 'EXPORT_CHALLENGE_USERS_FAILED'
}
