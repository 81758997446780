import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, notification, Row, Tooltip } from 'antd'
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { get, map } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../redux'
import { getDownloadCategories } from '../../../redux/downloadCategories/actions'
import { deleteDownload } from '../../../redux/downloads/actions'

// utils
import { FORMS, LANGUAGES, UPLOAD_CATEGORY } from '../../../utils/enums'
import { IFileToUpload, uploadFile } from '../../../utils/fileUploader'
import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validateDownloadForm, { DownloadFormValues } from './validateDownloadForm'

// components
import DetailHeader from '../../../components/DetailHeader'

// atoms
import SelectField from '../../../atoms/form/SelectField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

export type DownloadFormProps = {
	isCreate: boolean
}

const DownloadForm = ({ handleSubmit, invalid, pristine, isCreate }: DownloadFormProps & InjectedFormProps<DownloadFormValues, DownloadFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.DOWNLOAD_FORM)(state)) as DownloadFormValues
	const downloadCategories = useSelector((state: RootState) => state.downloadCategories?.list?.tableList)
	const formErrors = useSelector(getFormSyncErrors(FORMS.DOWNLOAD_FORM))

	// constants
	const MAX_UPLOAD_FILE_SIZE = 20971520 // 20MB

	const [file, setFile] = useState({
		url: get(fieldValues, 'file', null),
		isLoading: false
	})

	const [image, setImage] = useState({
		url: fieldValues?.image || null,
		isLoading: false
	})

	// constants
	const downloadCategoriesOptions = map(downloadCategories, (category) => ({
		value: category.key,
		label: category.name
	}))

	const fieldLabels: IFieldLabels = {
		title: t('Názov'),
		description: t('Popis'),
		category: t('Kategória'),
		language: t('Jazyk'),
		file: t('Súbor'),
		url: t('URL'),
		image: t('Obrázok'),
		imageTitle: t('Titulok pre obrázok'),
		imageAlt: t('Alternatívny text pre obrázok')
	}

	useEffect(() => {
		dispatch(getDownloadCategories())
	}, [dispatch])

	const handleDeleteDownload = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať tento súbor?'),
			icon: <DeleteOutlined />,
			okType: 'danger',
			okText: t('Vymazať'),
			onOk: () => {
				dispatch(deleteDownload(fieldValues.id, () => history.push(t('paths:downloads|path'))) as any)
			},
			cancelText: t('Zrušiť')
		})
	}

	const handleUploadFile = async ({ file: upload }: { file: IFileToUpload }) => {
		if (upload.size > MAX_UPLOAD_FILE_SIZE) {
			notification.error({
				message: t('Maximálna povolená veľkosť súboru je 20MB')
			})
			return
		}
		setFile({ ...file, isLoading: true })
		try {
			const uploadUrl: any = await uploadFile(upload, UPLOAD_CATEGORY.DOWNLOAD)
			if (uploadUrl) {
				dispatch(change(FORMS.DOWNLOAD_FORM, 'file', uploadUrl))
				setFile({ url: uploadUrl, isLoading: false })
			}
		} catch {
			setFile({ ...file, isLoading: false })
		}
	}

	const handleUploadImage = async ({ file: upload }: { file: IFileToUpload }) => {
		setImage({ ...image, isLoading: true })
		try {
			const uploadUrl: any = await uploadFile(upload, UPLOAD_CATEGORY.DOWNLOAD_COVER)
			if (uploadUrl) {
				dispatch(change(FORMS.DOWNLOAD_FORM, 'image', uploadUrl))
				setImage({ url: uploadUrl, isLoading: false })
			}
		} catch {
			setImage({ ...image, isLoading: false })
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={t(isCreate ? 'Pridať nový súbor' : 'Detail súboru na stiahnutie')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nový súbor'),
										onClick: () => {
											history.push({
												pathname: t('paths:downloadCreate|path')
											})
										}
									}
								]
							}
						/>
						<Field name={'title'} component={TextField} label={t('Názov')} required />
						<Field name={'description'} component={TextField} label={t('Popis')} required />
						<Field name={'category'} component={SelectField} label={t('Kategória')} options={downloadCategoriesOptions} required />
						<Field
							name={'language'}
							component={SelectField}
							label={t('Jazyk')}
							options={map(LANGUAGES, (lang) => ({
								value: lang,
								label: getLanguageName(lang)
							}))}
							required
						/>
						<Row gutter={16}>
							<Col span={8} className={'grid'}>
								<Field
									name={'file'}
									imageUrl={get(fieldValues, 'file')}
									component={UploadInputField}
									label={t('Súbor')}
									customHelper={t('allowedFileTypes|download')}
									customRequest={handleUploadFile}
									isLoading={file.isLoading}
									required
								/>
							</Col>
							<Col span={16} className={'grid'}>
								<Field name={'url'} component={TextField} label={t('URL')} required />
							</Col>
						</Row>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						{!isCreate && (
							<>
								<Field name={'createdAt'} component={TextField} label={t('Dátum vytvorenia')} disabled />
								<Field name={'updatedAt'} component={TextField} label={t('Dátum poslednej úpravy')} disabled />
							</>
						)}
						<Field
							name={'image'}
							imageUrl={get(fieldValues, 'image')}
							component={UploadInputField}
							label={t('Obrázok')}
							customHelper={t('recommendedSize|downloadImage')}
							customRequest={handleUploadImage}
							isLoading={image.isLoading}
							required
						/>
						<Field name={'imageTitle'} component={TextField} label={t('Titulok pre obrázok')} required />
						<Field name={'imageAlt'} component={TextField} label={t('Alternatívny text pre obrázok')} required />
						<div className={'flex direction-col justify-center'}>
							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>
							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={handleDeleteDownload} style={{ margin: '20px' }}>
									{t('Vymazať súbor')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</form>
	)
}

export default reduxForm<DownloadFormValues, DownloadFormProps>({
	form: FORMS.DOWNLOAD_FORM,
	validate: validateDownloadForm,
	touchOnChange: true,
	forceUnregisterOnUnmount: true,
	destroyOnUnmount: true
})(DownloadForm)
