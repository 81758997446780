import { useEffect } from 'react'
import dayjs from 'dayjs'
import { Spin } from 'antd'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createDownload, getDownload, updateDownload } from '../../redux/downloads/actions'

// utils
import { FORMS } from '../../utils/enums'

// types
import { DownloadFormValues } from './forms/validateDownloadForm'
import { GetDownloadsIdPayload, PostDownloadsBody, PutDownloadsIdBody } from '../../redux/downloads/types'
import { IComputedMatch } from '../../types/interfaces'

// forms
import DownloadForm from './forms/DownloadForm'

type DetailDownloadProps = {
	computedMatch: IComputedMatch
}

const DetailDownload = ({ computedMatch }: DetailDownloadProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const download = useSelector((state: RootState) => state.downloads?.detail)

	// constants
	const id = computedMatch?.params?.id
	const isLoading = download?.isLoading

	const initDetailForm = (initData: GetDownloadsIdPayload) => {
		const data = initData || download?.data
		const initValues = {
			...data,
			category: data?.category && data?.category.id,
			createdAt: dayjs(data.createdAt).format('H:mm:ss D. MMM YYYY'),
			updatedAt: dayjs(data.updatedAt).format('H:mm:ss D. MMM YYYY')
		}
		dispatch(
			initialize(FORMS.DOWNLOAD_FORM, {
				...initValues
			})
		)
	}

	const initCreateForm = () => {
		const initValues = {}
		dispatch(
			initialize(FORMS.DOWNLOAD_FORM, {
				...initValues
			})
		)
	}

	useEffect(() => {
		if (id) {
			dispatch(getDownload(id, (data) => initDetailForm(data)))
		} else {
			initCreateForm()
		}
	}, [dispatch, id])

	const handleCreate = (body: PostDownloadsBody) =>
		dispatch(createDownload(body, (newId: number) => history.push(`${t('paths:downloadDetail|path')}/${newId}`)))

	const handleUpdate = (body: PutDownloadsIdBody) =>
		dispatch(updateDownload(id, body, () => dispatch(getDownload(id, (data: GetDownloadsIdPayload) => initDetailForm(data)))))

	const handleSubmit = (values: DownloadFormValues) => {
		const body: PostDownloadsBody | PutDownloadsIdBody = {
			title: values.title,
			description: values.description,
			categoryID: values?.category ? values.category : null,
			language: values.language,
			file: !values?.url || values?.url === '' ? values?.file || null : null,
			url: values?.url || null,
			image: values.image,
			imageTitle: values?.imageTitle,
			imageAlt: values?.imageAlt
		}

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<DownloadForm isCreate={!id} onSubmit={handleSubmit} />
			</Spin>
		</div>
	)
}

export default DetailDownload
