import debounce from 'lodash/debounce'
import each from 'lodash/each'
import find from 'lodash/find'
import map from 'lodash/map'
import slugify from 'slugify'
import { Button, Col, Modal, Row, Select, Tooltip } from 'antd'
import { DeleteOutlined, EyeOutlined, SaveOutlined } from '@ant-design/icons'
import { change, Field, getFormSyncErrors, getFormValues, InjectedFormProps, reduxForm } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// redux
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../../redux'
import { deleteBlogPost } from '../../../redux/blog/actions'
import { getBlogCategoriesList, getBlogPostList, getEshopProductList } from '../../../redux/lists/actions'

// utils
import { BLOG_POST_TAG, BLOG_POST_TYPE, FORMS, LANGUAGE, LANGUAGES, UPLOAD_CATEGORY } from '../../../utils/enums'

import { getErrorFieldsLabel, getFieldLabel, getLanguageName } from '../../../utils/helpers'
import { uploadFile } from '../../../utils/fileUploader'

// types
import { IFieldLabels } from '../../../types/interfaces'

// forms
import validateBlogForm, { BlogFormValues } from './validateBlogForm'

// components
import Area from '../../../components/articleBuilder/Area'
import DetailHeader from '../../../components/DetailHeader'

// atoms
import AsyncTransferField from '../../../atoms/form/AsyncTransferField'
import CustomOptionsSelectField from '../../../atoms/form/CustomOptionsSelectField'
import DateField from '../../../atoms/form/DateField'
import SelectField from '../../../atoms/form/SelectField'
import SwitchField from '../../../atoms/form/SwitchField'
import TextField from '../../../atoms/form/TextField'
import UploadInputField from '../../../atoms/form/UploadField'

const { Option } = Select

type BlogFormProps = {
	onUpdateForm: (data: any) => void
	isCreate?: boolean
	isPublished?: boolean
	blogBuilderData?: any
}

const BlogForm = ({
	handleSubmit,
	invalid,
	pristine,
	isCreate = false,
	isPublished,
	onUpdateForm,
	blogBuilderData = {}
}: BlogFormProps & InjectedFormProps<BlogFormValues, BlogFormProps>) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.BLOG_FORM)(state)) as BlogFormValues
	const formErrors = useSelector(getFormSyncErrors(FORMS.BLOG_FORM))
	const authors = useSelector((state: RootState) => state.administrators?.list?.tableList)
	const tags = useSelector((state: RootState) => state.tags?.list?.tags)
	const listContext = useSelector((state: RootState) => state.lists?.list?.context)
	const blogPosts = useSelector((state: RootState) => state.lists?.list?.blogPosts)
	const products = useSelector((state: RootState) => state.lists?.list?.upsellProducts)
	const blogCategories = useSelector((state: RootState) => state.lists?.list?.blogCategories)

	// states
	const [profilePhoto, setProfilePhoto] = useState({
		isLoading: false,
		imageUrl: fieldValues?.image || ''
	})

	// constants
	const blogPostsList = map(blogPosts, (post) => ({ id: post.id, title: post.title }))
	const blogCategoriesList = map(blogCategories, (post) => ({ id: post.id, title: post.title }))
	const authorsSource: { value: number; label: string }[] = []

	const fieldLabels: IFieldLabels = {
		title: t('Názov'),
		urlSlug: t('URL'),
		briefDescription: t('Krátky popis'),
		image: t('Titulný obrázok (upload)'),
		language: t('Jazyk'),
		type: t('Typ príspevku'),
		categories: t('Kategórie'),
		tags: t('Tagy'),
		imageAlt: t('Alternatívny text pre obrázok'),
		publishedAt: t('Dátum publikovania'),
		imageTitle: t('Titulok pre obrázok')
	}

	each(authors, (item) => {
		let label = `${item?.firstName} ${item?.lastName}`
		if (!item.firstName || !item.lastName) {
			label = item?.email
		}
		authorsSource.push({
			value: item?.id,
			label
		})
	})

	const languagesSource = map(LANGUAGES, (item) => ({
		value: item,
		label: getLanguageName(item)
	}))

	const typeOptions = [
		{ value: BLOG_POST_TYPE.ARTICLE, label: t('Článok') },
		{ value: BLOG_POST_TYPE.RECIPE, label: t('Recept') }
	]

	const labelsOptions = [
		{ value: BLOG_POST_TAG.VEGETARIAN, label: t('Vegetariánske') },
		{ value: BLOG_POST_TAG.GLUTEN_FREE, label: t('Bezlepkové') },
		{ value: BLOG_POST_TAG.LACTOSE_FREE, label: t('Bezlaktózové') }
	]

	const refreshAfterUpload = (fileUrl: any) => {
		setProfilePhoto({
			isLoading: false,
			imageUrl: fileUrl
		})
	}

	const removeBlogPost = () => {
		Modal.confirm({
			title: t('Skutočne si prajete vymazať blogový príspevok?'),
			icon: <DeleteOutlined />,
			okText: t('Vymazať'),
			cancelText: t('Zrušiť'),
			okType: 'danger',
			onOk: () => {
				dispatch(
					deleteBlogPost(fieldValues?.id, () => {
						history.push(t('paths:blog|path'))
					})
				)
			}
		})
	}

	const debounceChangeField = debounce((value) => {
		const slug = slugify(value.toLowerCase(), {
			replacement: '-',
			remove: /[^A-Za-z0-9\s-]/g,
			lower: true
		})

		dispatch(change(FORMS.BLOG_FORM, 'urlSlug', slug))
	}, 300)

	const changeUrlSlug = (event: any) => {
		if (isCreate) {
			debounceChangeField(event.target.value)
		}
	}

	const uploadImage = async ({ file }: any) => {
		setProfilePhoto({
			...profilePhoto,
			isLoading: true
		})

		const fileUrl = await uploadFile(file, UPLOAD_CATEGORY.BLOG)

		if (fileUrl) {
			dispatch(change(FORMS.BLOG_FORM, 'image', fileUrl))
			refreshAfterUpload(fileUrl)
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<Row gutter={16}>
				<Col span={18} className={'grid'}>
					<div className={'flex direction-col justify-start main-content'}>
						<DetailHeader
							title={isCreate ? t('Pridať nový príspevok') : t('Detail blogového príspevku')}
							detailButtons={
								!isCreate && [
									{
										title: t('Pridať nový príspevok'),
										onClick: () =>
											history.push({
												pathname: t('paths:blogCreate|path')
											})
									}
								]
							}
						/>
						<Field name={'title'} component={TextField} label={t('Názov')} onInput={changeUrlSlug} size={'large'} required />

						<Field name={'urlSlug'} component={TextField} label={t('URL')} required />

						<Field name={'briefDescription'} component={TextField} label={t('Krátky popis')} required />

						<Field name={'language'} component={SelectField} label={t('Jazyk')} options={languagesSource} required />

						<Field
							name={'tags'}
							component={CustomOptionsSelectField}
							label={t('Tagy')}
							options={map(tags, (tag, index) => (
								<Option key={index} value={tag?.id}>
									{find(tag?.translations, ['language', LANGUAGE.SK])?.name || ''}
								</Option>
							))}
							filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							multiple
						/>

						{fieldValues?.type === BLOG_POST_TYPE.RECIPE && (
							<Field name={'labels'} component={SelectField} label={t('Nálepky')} options={labelsOptions} multiple />
						)}

						<Field
							name={'pageMetadata.title'}
							component={TextField}
							label={t('Metadáta titulok')}
							counter={{
								label: t('optimálne'),
								actualCount: fieldValues?.pageMetadata?.title?.length || 0,
								totalCount: 60
							}}
						/>

						<Field
							name={'pageMetadata.description'}
							component={TextField}
							label={t('Metadáta popis')}
							counter={{
								label: t('optimálne'),
								actualCount: fieldValues?.pageMetadata?.description?.length || 0,
								totalCount: 150
							}}
						/>

						<Field
							name={'pageMetadata.ogTitle'}
							component={TextField}
							label={t('OG titulok')}
							counter={{
								label: t('optimálne'),
								actualCount: fieldValues?.pageMetadata?.ogTitle?.length || 0,
								totalCount: 60
							}}
						/>

						<Field
							name={'pageMetadata.ogDescription'}
							component={TextField}
							label={t('OG popis')}
							counter={{
								label: t('optimálne'),
								actualCount: fieldValues?.pageMetadata?.ogDescription?.length || 0,
								totalCount: 150
							}}
						/>

						<Field
							name={'upsellProducts'}
							component={AsyncTransferField}
							label={t('Upsell produkty')}
							reduxFetch={(config: any) => dispatch(getEshopProductList(config))}
							modalDataSource={products}
							context={listContext}
						/>

						<Field
							name={'categories'}
							component={AsyncTransferField}
							label={t('Kategórie')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:blogCategoryDetail|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getBlogCategoriesList(config))}
							modalDataSource={blogCategoriesList}
							showPagination={false}
							context={listContext}
						/>

						<Field
							name={'recommendedBlogPosts'}
							component={AsyncTransferField}
							label={t('Odporúčané blogové články')}
							customButton={{
								icon: <EyeOutlined />,
								handler: (id: number) => {
									window.open(`${t('paths:blogDetail|path')}/${id}`, '_blank')
								}
							}}
							reduxFetch={(config: any) => dispatch(getBlogPostList(config))}
							modalDataSource={blogPostsList}
							context={listContext}
						/>
					</div>
				</Col>
				<Col span={6} className={'grid'}>
					<div className={'flex direction-col justify-start sidebar-content'}>
						<Field
							name={'authorID'}
							component={CustomOptionsSelectField}
							label={t('Autor')}
							options={map(authorsSource, (author, index) => (
								<Option key={index} value={author?.value}>
									{author?.label || ''}
								</Option>
							))}
							showSearch
							filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
						/>

						{!isCreate && (
							<Field name={'publishedAt'} component={DateField} dttmFormat={'D.M.YYYY HH:mm'} label={t('Dátum publikovania')} showTime required />
						)}

						{!isCreate && <Field name={'updatedAt'} component={TextField} disabled label={t('Dátum poslednej úpravy')} />}

						<Field name={'type'} component={SelectField} label={t('Typ príspevku')} options={typeOptions} required />

						{fieldValues?.type === BLOG_POST_TYPE.RECIPE && (
							<div>
								<Field name={'duration'} component={TextField} label={t('Dĺžka prípravy receptu')} suffix={'min'} type={'number'} />

								<Field name={'portions'} component={TextField} label={t('Počet porcií')} suffix={t('porcií')} type={'number'} />

								<Tooltip title={t('Ak nechcete zmeniť typ porcie, zanechajte prázdne')} placement={'left'}>
									<div>
										<Field
											name={'portionType'}
											component={TextField}
											label={t('Typ porcie')}
											type={'text'}
											placeholder={t(' (porcia), ks, plech')}
										/>
									</div>
								</Tooltip>
							</div>
						)}

						<Field
							name={'image'}
							imageUrl={fieldValues?.image}
							component={UploadInputField}
							label={t('Titulný obrázok (upload)')}
							customHelper={t('recommendedSize|videoThumbnail')}
							customRequest={uploadImage}
							isLoading={profilePhoto.isLoading}
							required
						/>

						<Field name={'imageAlt'} component={TextField} label={t('Alternatívny text pre obrázok')} required />

						<Field name={'imageTitle'} component={TextField} label={t('Titulok pre obrázok')} required />

						<Field name={'isPublished'} component={SwitchField} label={t('Publikované')} />

						<Field name={'notFeatured'} component={SwitchField} label={t('Nezobrazovať vo vybraných článkoch')} />

						<div className={'flex direction-col justify-center'}>
							{fieldValues?.id && (
								<Tooltip title={isPublished ? t('Članok je publikovaný, náhľad nie je možné zobraziť') : ''}>
									<Button
										icon={<EyeOutlined />}
										onClick={() =>
											window.open(
												`${window.__RUNTIME_CONFIG__.REACT_APP_FRONTEND_URL}${t('paths:blogPreview|path')}/${fieldValues?.id}`,
												'_blank'
											)
										}
										type={'default'}
										style={{ margin: '20px' }}
										disabled={isPublished}
									>
										{t('Zobraziť náhľad')}
									</Button>
								</Tooltip>
							)}

							<Tooltip
								title={
									invalid
										? `${t('Pred odoslaním treba správne vyplniť')}: ${getErrorFieldsLabel(formErrors, (field) =>
												getFieldLabel(field, fieldLabels)
											)}`
										: null
								}
								placement={'bottom'}
							>
								<Button
									icon={<SaveOutlined />}
									onClick={handleSubmit}
									disabled={pristine || invalid}
									type={'primary'}
									style={{ margin: '20px' }}
								>
									{t('Uložiť')}
								</Button>
							</Tooltip>

							{!isCreate && (
								<Button icon={<DeleteOutlined />} onClick={() => removeBlogPost()} type={'primary'} danger style={{ margin: '20px' }}>
									{t('Vymazať príspevok')}
								</Button>
							)}
						</div>
					</div>
				</Col>
			</Row>
			<div className={'page-builder'}>
				<label className={'general-label'}>{t('Detail článku')}</label>
				<Area data={blogBuilderData} onChange={(data: any) => onUpdateForm(data)} />
				<div className={'flex direction-col justify-center'}>
					<Button icon={<SaveOutlined />} onClick={handleSubmit} disabled={pristine || invalid} type={'primary'} style={{ margin: '30px 0' }}>
						{t('Uložiť')}
					</Button>
				</div>
			</div>
		</form>
	)
}

export default reduxForm<BlogFormValues, BlogFormProps>({
	form: FORMS.BLOG_FORM,
	destroyOnUnmount: true,
	forceUnregisterOnUnmount: true,
	touchOnChange: true,
	validate: validateBlogForm
})(BlogForm)
