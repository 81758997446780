// any
import { GET_DOWNLOADS, GET_DOWNLOAD } from './types'

export const initState = {
	items: {
		list: [],
		context: {},
		filterOptions: {},
		isLoading: false,
		isFailure: false
	},
	categories: {
		list: [],
		isLoading: false,
		isFailure: false
	},
	detail: {
		data: {},
		isLoading: false,
		isFailure: false
	}
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_DOWNLOADS.START:
			return {
				...state,
				items: {
					...state.items,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_DOWNLOADS.DONE:
			return {
				...state,
				items: {
					...action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_DOWNLOADS.FAILED:
			return {
				...state,
				items: {
					...state.items,
					isLoading: false,
					isFailure: true
				}
			}
		case GET_DOWNLOAD.START:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_DOWNLOAD.DONE:
			return {
				...state,
				detail: {
					data: action.payload,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_DOWNLOAD.FAILED:
			return {
				...state,
				detail: {
					...state.detail,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
