// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// payloads
export type GetAccessoriesPayload = RequestResponse<GetUrls['/admin/accessories/']>['accessories']
export type GetAccessoriesIdPayload = RequestResponse<GetUrls['/admin/accessories/{id}']>

// query params
export type GetAccessoriesQueryParams = RequestParams<GetUrls['/admin/accessories/']>['query']

// bodies
export type PostAccessoriesBody = RequestPayload<PostUrls['/admin/accessories/']>
export type PutAccessoriesIdBody = RequestPayload<PutUrls['/admin/accessories/{id}']>

export enum GET_ACCESSORIES {
	START = 'GET_ACCESSORIES_START',
	DONE = 'GET_ACCESSORIES_DONE',
	FAILED = 'GET_ACCESSORIES_FAILED'
}

export enum GET_ACCESSORY {
	START = 'GET_ACCESSORY_START',
	DONE = 'GET_ACCESSORY_DONE',
	FAILED = 'GET_ACCESSORY_FAILED'
}

export enum DELETE_ACCESSORY {
	START = 'DELETE_ACCESSORY_START',
	DONE = 'DELETE_ACCESSORY_DONE',
	FAILED = 'DELETE_ACCESSORY_FAILED'
}

export enum CREATE_ACCESSORY {
	START = 'CREATE_ACCESSORY_START',
	DONE = 'CREATE_ACCESSORY_DONE',
	FAILED = 'CREATE_ACCESSORY_FAILED'
}

export enum UPDATE_ACCESSORY {
	START = 'UPDATE_ACCESSORY_START',
	DONE = 'UPDATE_ACCESSORY_DONE',
	FAILED = 'UPDATE_ACCESSORY_FAILED'
}
