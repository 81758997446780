// utils
import { GetUrls, PostUrls, PutUrls, RequestParams, RequestPayload, RequestResponse } from '../../utils/request'

// types
import { ArrElement } from '../../types/types'

// payloads
export type GetPromoCodesPayload = RequestResponse<GetUrls['/admin/promo-codes/']>['codes']
export type GetPromoCodesListItemPayload = ArrElement<GetPromoCodesPayload>
export type GetAdminPromoCodesIdPayload = RequestResponse<GetUrls['/admin/promo-codes/{id}']>

// query params
export type GetPromoCodesQueryParams = RequestParams<GetUrls['/admin/promo-codes/']>['query']
export type GetPromoCodesApplicationsQueryParams = RequestParams<GetUrls['/admin/promo-codes/applications']>['query']

// bodies
export type PostPromoCodesBody = RequestPayload<PostUrls['/admin/promo-codes/']>
export type PostPromoCodesBatchBody = RequestPayload<PostUrls['/admin/promo-codes/batch']>
export type PutPromoCodesCodeIdBody = RequestPayload<PutUrls['/admin/promo-codes/{codeID}']>

export enum GET_SELECT_PROMO_CODES {
	START = 'GET_SELECT_PROMO_CODES_START',
	DONE = 'GET_SELECT_PROMO_CODES_DONE',
	FAILED = 'GET_SELECT_PROMO_CODES_FAILED'
}

export enum GET_PROMO_CODE {
	START = 'GET_PROMO_CODE_START',
	DONE = 'GET_PROMO_CODE_DONE',
	FAILED = 'GET_PROMO_CODE_FAILED'
}

export enum CREATE_PROMO_CODES {
	START = 'CREATE_PROMO_CODES_START',
	DONE = 'CREATE_PROMO_CODES_DONE',
	FAILED = 'CREATE_PROMO_CODES_FAILED'
}

export enum UPDATE_PROMO_CODES {
	START = 'UPDATE_PROMO_CODES_START',
	DONE = 'UPDATE_PROMO_CODES_DONE',
	FAILED = 'UPDATE_PROMO_CODES_FAILED'
}

export enum GET_PROMO_CODES_APPLICATIONS {
	START = 'GET_PROMO_CODES_APPLICATIONS_START',
	DONE = 'GET_PROMO_CODES_APPLICATIONS_DONE',
	FAILED = 'GET_PROMO_CODES_APPLICATIONS_FAILED'
}

export enum GENERATE_PROMO_CODES {
	START = 'GENERATE_PROMO_CODES_START',
	DONE = 'GENERATE_PROMO_CODES_DONE',
	FAILED = 'GENERATE_PROMO_CODES_FAILED'
}

export const RESET_PROMO_CODES_LIST = 'RESET_PROMO_CODES_LIST_DONE'
