import { FC, useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import { Spin } from 'antd'
import { each, find, forEach, get } from 'lodash'
import { initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { createPushNotification, getPushNotification, updatePushNotification } from '../../redux/pushNotifications/actions'

// utils
import { FORMS, LANGUAGES, PUSH_NOTIFICATION_STATE } from '../../utils/enums'

// types
import { ArrElement } from '../../types/types'
import { GetPushNotificationsIdPayload, PatchPushNotificationsIdBody, PostPushNotificationsBody } from '../../redux/pushNotifications/types'

// forms
import PushNotificationForm from './forms/PushNotificationForm'

interface IDetailPushNotification {
	computedMatch: {
		params: any
	}
}

const DetailPushNotification: FC<IDetailPushNotification> = ({ computedMatch }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()
	const { id } = computedMatch.params

	// selectors
	const { data, isLoading } = useSelector((state: RootState) => state.pushNotifications?.detail)

	const initEmptyDetailForm = useCallback(() => {
		const initValues = {
			state: PUSH_NOTIFICATION_STATE.DRAFT
		}

		dispatch(initialize(FORMS.PUSH_NOTIFICATION_FORM, initValues))
	}, [dispatch])

	const initDetailForm = useCallback(
		(callbackData: any) => {
			const detailData = callbackData || data
			const langData = get(detailData, 'translations')

			const initValues = {
				...detailData,
				state: detailData?.state
			}

			const langValues: any = {}

			forEach(LANGUAGES, (item) => {
				const lang = find(langData, { language: item })
				langValues[`title_${item}`] = lang?.title
				langValues[`message_${item}`] = lang?.message
			})

			dispatch(
				initialize(FORMS.PUSH_NOTIFICATION_FORM, {
					...initValues,
					...langValues
				})
			)
		},
		[dispatch, data]
	)

	useEffect(() => {
		if (id) {
			dispatch(getPushNotification(id, (response: GetPushNotificationsIdPayload) => initDetailForm(response)))
		} else {
			initEmptyDetailForm()
		}
	}, [dispatch])

	const handleCreate = (body: PostPushNotificationsBody) => dispatch(createPushNotification(body, () => history.push(t('paths:pushNotifications|path'))))

	const handleUpdate = (body: PatchPushNotificationsIdBody) =>
		dispatch(
			updatePushNotification(id, body, () => dispatch(getPushNotification(id, (response: GetPushNotificationsIdPayload) => initDetailForm(response))))
		)

	const handleSubmit = (values: any) => {
		const body: any = {
			translations: [],
			scheduledAt: dayjs(values.scheduledAt).toISOString(),
			image: values.image,
			state: values.state,
			audiences: values.audiences
		}

		each(LANGUAGES, (language) => {
			const title = get(values, `title_${language}`)
			const message = get(values, `message_${language}`)

			if (!title || !message) return

			const translation: ArrElement<PostPushNotificationsBody['translations']> = {
				title,
				message,
				language
			}

			body.translations.push(translation)
		})

		if (id) {
			handleUpdate(body)
		} else {
			handleCreate(body)
		}
	}

	return (
		<div className={'page-wrapper'}>
			<Spin spinning={isLoading}>
				<PushNotificationForm isCreate={!id} originalState={data?.state} onSubmit={handleSubmit} />
			</Spin>
		</div>
	)
}

export default DetailPushNotification
