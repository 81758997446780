import { useEffect, useState } from 'react'
import ceil from 'lodash/ceil'
import dayjs from 'dayjs'
import get from 'lodash/get'
import { Button, Col, Empty, Row, Table, Tooltip } from 'antd'
import { DeleteOutlined, StopOutlined } from '@ant-design/icons'
import { getFormValues } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../../../redux'
import { getUserSubscriptions } from '../../../../redux/subscriptions/actions'

// utils
import { EMPTY_VALUE, FORMS, PAYMENT_TYPE, SUBSCRIPTION_STATE, SUBSCRIPTION_TIER, SUBSCRIPTION_TYPE } from '../../../../utils/enums'
import { formatReasons, formatSubscriptionState, formatSubscriptionTier, formatSubscriptionType } from '../../../../utils/helpers'

// types
import { GetSubscriptionsQueryParams } from '../../../../redux/subscriptions/types'

// forms
import { UserFormValues } from '../../forms/validateUserForm'

type SubscriptionsTableProps = {
	showPayments: (id: number) => void
	cancelSubscription: (record: any) => void
	removeSubscription: (record: any) => void
}

const PAGE_SIZE = 20

const SubscriptionsTable = ({ showPayments, cancelSubscription, removeSubscription }: SubscriptionsTableProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	// selectors
	const fieldValues = useSelector((state: RootState) => getFormValues(FORMS.USER_FORM)(state)) as UserFormValues
	const { subscriptions, context, isLoading } = useSelector((state: RootState) => state.subscriptions?.userSubscriptions)

	// states
	const [filter, setFilter] = useState<GetSubscriptionsQueryParams>({
		limit: PAGE_SIZE,
		page: 1,
		orderBy: 'id',
		orderDirection: 'desc'
	})

	useEffect(() => {
		dispatch(getUserSubscriptions(fieldValues?.id, filter))
	}, [dispatch, filter])

	const getSubscriptionStateColor = (state: SUBSCRIPTION_STATE, daysToEnd: number) => {
		switch (state) {
			case SUBSCRIPTION_STATE.INITIALIZED:
			case SUBSCRIPTION_STATE.PREAUTHORIZED:
			case SUBSCRIPTION_STATE.UPGRADED:
				return '#808080'
			case SUBSCRIPTION_STATE.DOWNGRADED:
				return '#FDDA0D'
			case SUBSCRIPTION_STATE.CANCELED:
				if (daysToEnd > 0) {
					return '#008000'
				}
				return '#FF0000'
			case SUBSCRIPTION_STATE.ACTIVE:
				return '#008000'
			case SUBSCRIPTION_STATE.FAILED:
				return '#FF0000'
			default:
				return '#000000'
		}
	}

	const subscriptionsColumns = [
		{
			title: t('ID'),
			dataIndex: 'id',
			key: 'id',
			ellipsis: true,
			sorter: true,
			width: 60
		},
		{
			title: t('Typ členstva'),
			dataIndex: 'tier',
			key: 'tier',
			ellipsis: false,
			width: 100,
			render: (text: SUBSCRIPTION_TIER) => {
				return formatSubscriptionTier(text)
			}
		},
		{
			title: t('Stav'),
			dataIndex: 'state',
			key: 'state',
			ellipsis: false,
			width: 150,
			render: (state: SUBSCRIPTION_STATE, record: any) => {
				const difference = ceil(dayjs.utc(record?.activeTo).diff(dayjs(), 'day', true))

				if (state === SUBSCRIPTION_STATE.ACTIVE && record?.daysToEnd === 0) {
					if (difference < 1) {
						return <span>{difference > -1 ? t('Vyprší dnes') : t('Vypršané')}</span>
					}
				}

				return <span style={{ color: getSubscriptionStateColor(state, record?.daysToEnd) }}>{formatSubscriptionState(state, record?.daysToEnd)}</span>
			}
		},
		{
			title: t('Trvanie členstva'),
			dataIndex: 'type',
			key: 'type',
			ellipsis: false,
			width: 125,
			render: (text: string, record: any) => {
				if (text === SUBSCRIPTION_TYPE.CUSTOM) {
					return (
						<>
							{formatSubscriptionType(text, get(record, 'paymentType'))}
							<br />
							<span style={{ fontSize: 10 }} className={'note'}>{`${formatReasons(record.activateReason)} ${
								record.note ? `- ${record.note}` : ''
							}`}</span>
						</>
					)
				}
				return formatSubscriptionType(text, get(record, 'paymentType'))
			}
		},
		{
			title: t('Platnosť od/do'),
			dataIndex: 'activeFrom',
			key: 'activeFrom',
			width: 120,
			render: (text: string, record: any) =>
				`${text ? dayjs.utc(text).format('D.M.YYYY') : EMPTY_VALUE} / ${
					record.activeTo ? dayjs.utc(get(record, 'activeTo')).format('D.M.YYYY') : EMPTY_VALUE
				}`
		},
		{
			title: t('Zostávajúci počet dní'),
			dataIndex: 'daysToEnd',
			key: 'daysToEnd',
			width: 120,
			render: (item: number, record: any) => {
				const difference = ceil(dayjs.utc(record?.activeTo).diff(dayjs(), 'day', true))

				if (record?.state === SUBSCRIPTION_STATE.UPGRADED) {
					return (
						<span style={{ color: getSubscriptionStateColor(record?.state, record?.daysToEnd) }}>
							{formatSubscriptionState(record?.state, record?.daysToEnd)}
						</span>
					)
				}

				if (dayjs.utc(record?.activeFrom).format('YYYY.MM.DD') > dayjs().format('YYYY.MM.DD')) {
					return t('V budúcnosti')
				}

				if (record.type === SUBSCRIPTION_TYPE.MONTH && record.paymentType !== PAYMENT_TYPE.MANUAL && record.paymentType !== PAYMENT_TYPE.REFUND) {
					return EMPTY_VALUE
				}

				if (item === 0) {
					if (difference < 1) {
						return difference > -1 ? t('Vyprší dnes') : t('Vypršané')
					}
				}

				return difference
			}
		},
		{
			title: t('Aktivoval'),
			dataIndex: 'activatedBy',
			key: 'activatedBy',
			width: 160,
			render: (item: any) => `${get(item, 'firstName', EMPTY_VALUE)} ${get(item, 'lastName', '')}`
		},
		{
			title: t('Priradené platby'),
			dataIndex: 'payments',
			key: 'payments',
			width: 125,
			render: (item: any, record: any) => <Button onClick={() => showPayments(record.id)}>{'Zobraziť'}</Button>,
			ellipsis: true
		},
		{
			title: '',
			key: 'action',
			width: 90,
			render: (record: any) => (
				<Row gutter={8}>
					<Col>
						<Tooltip title={t('Ukončiť členstvo')}>
							<Button
								type={'dashed'}
								disabled={
									!(
										[SUBSCRIPTION_STATE.ACTIVE, SUBSCRIPTION_STATE.CANCELED, SUBSCRIPTION_STATE.DOWNGRADED].includes(record?.state) &&
										(dayjs(record?.activeTo).isSameOrAfter(dayjs().format('YYYY.MM.DD')) || record?.activeTo === null)
									)
								}
								icon={<StopOutlined />}
								onClick={() => cancelSubscription(record)}
							/>
						</Tooltip>
					</Col>
					<Col>
						<Tooltip title={t('Vymazať členstvo')}>
							<Button
								type={'primary'}
								danger
								disabled={record?.state === SUBSCRIPTION_STATE.UPGRADED || dayjs(record?.activeTo).isBefore(dayjs().format('YYYY.MM.DD'))}
								icon={<DeleteOutlined />}
								onClick={() => removeSubscription(record)}
							/>
						</Tooltip>
					</Col>
				</Row>
			)
		}
	]

	const handleSubscriptionsTableChange = (pagination: any, _: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setFilter({
			...filter,
			page: pagination.current,
			...order
		})
	}

	return (
		<Table
			className={'general-table overflow-x-scroll'}
			columns={subscriptionsColumns}
			dataSource={subscriptions}
			onChange={handleSubscriptionsTableChange}
			showSorterTooltip={false}
			rowKey={'id'}
			pagination={{
				pageSize: PAGE_SIZE,
				total: get(context, 'totalCount'),
				current: get(context, 'page'),
				showSizeChanger: false
			}}
			loading={isLoading}
			locale={{
				emptyText: <Empty description={t('Žiadne dáta')} />
			}}
			size={'small'}
		/>
	)
}

export default SubscriptionsTable
