import { useEffect, useState } from 'react'
import i18next from 'i18next'
import { Button, Empty, Popconfirm, Table } from 'antd'
import { DeleteOutlined, LinkOutlined, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { RootState } from '../../redux'
import { deleteAccessory, getAccessories } from '../../redux/accessories/actions'
import { setFilters } from '../../redux/filters/actions'

// utils
import { setFiltersForPage } from '../../utils/helpers'

// types
import { GetAccessoriesPayload } from '../../redux/accessories/types'
import { ArrElement } from '../../types/types'

// atoms
import BooleanRepresent from '../../atoms/BooleanRepresent'
import DateRepresent from '../../atoms/DateRepresent'

const PAGE_SIZE = 20

const Accessories = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()

	// selectors
	const persistFilter = useSelector((state: RootState) => state.filters)
	const [filter, setFilter] = useState(setFiltersForPage(t('paths:accessories|key'), persistFilter))
	const accessories = useSelector((state: RootState) => state.accessories?.list?.tableList)
	const isLoadingAccessories = useSelector((state: RootState) => state.accessories?.list?.isLoading)
	const isLoadingDetail = useSelector((state: RootState) => state.accessories?.detail?.isLoading)
	const context = useSelector((state: RootState) => state.accessories?.list?.context)
	const columns = [
		{
			title: i18next.t('Obrázok'),
			dataIndex: 'image',
			key: 'image',
			width: 150,
			render: (image: ArrElement<GetAccessoriesPayload>['image'], record: ArrElement<GetAccessoriesPayload>) => (
				<div className={'flex justify-start align-center'} style={{ width: '150px' }}>
					<img src={image || ''} height={30} alt={record?.imageAlt || ''} style={{ paddingLeft: '15px' }} />
				</div>
			)
		},
		{
			title: i18next.t('Názov'),
			dataIndex: 'translations',
			key: 'translations',
			ellipsis: true,
			render: (translations: ArrElement<GetAccessoriesPayload>['translations']) => translations?.[0] && <span>{translations[0].name}</span>
		},
		{
			title: i18next.t('Publikované'),
			dataIndex: 'isPublished',
			sorter: true,
			align: 'center',
			key: 'isPublished',
			width: 150,
			render: (state: GetAccessoriesPayload[0]['isPublished']) => <BooleanRepresent isTrue={state} />
		},
		{
			title: i18next.t('Dátum poslednej úpravy'),
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			width: 210,
			render: (value: GetAccessoriesPayload[0]['updatedAt']) => <DateRepresent value={value} />
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 100,
			render: (_: any, record: GetAccessoriesPayload[0]) => (
				<>
					<span style={{ marginRight: '8px' }}>
						<Link to={`${t('paths:accessory|path')}/${record?.id}`} target={'_blank'} rel={'noopener noreferrer'}>
							<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
						</Link>
					</span>
					<Popconfirm
						title={t('Skutočne chcete vymazať záznam?')}
						icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						cancelText={t('Zrušiť')}
						okText={t('Vymazať')}
						onConfirm={(e: any) => {
							e.stopPropagation()
							dispatch(
								deleteAccessory(record.id, () => {
									dispatch(
										getAccessories({
											limit: PAGE_SIZE,
											page: 1,
											...filter
										})
									)
								})
							)
						}}
						onCancel={(e: any) => e.stopPropagation()}
						okButtonProps={{
							size: 'small',
							type: 'primary',
							danger: true
						}}
						cancelButtonProps={{
							size: 'small',
							ghost: true
						}}
					>
						<Button icon={<DeleteOutlined />} type={'primary'} danger onClick={(e) => e.stopPropagation()} />
					</Popconfirm>
				</>
			)
		}
	]

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			...filter
		}

		dispatch(getAccessories(body))

		dispatch(setFilters(t('paths:accessories|key'), body))
	}, [filter, dispatch, t])

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}
		setFilter({
			limit: PAGE_SIZE,
			...filter,
			page: pagination.current,
			...order
		})
	}

	return (
		<div className={'page-wrapper'}>
			<div className={'flex justify-end'}>
				<Button icon={<PlusCircleOutlined />} href={t('paths:accessoryCreate|path')} type={'primary'}>
					{t('Pridať pomôcku')}
				</Button>
			</div>
			<Table
				className={'general-table'}
				columns={columns as any}
				dataSource={accessories}
				onChange={handleTableChange}
				showSorterTooltip={false}
				scroll={{ x: 'max-content' }}
				rowKey={'id'}
				pagination={{
					pageSize: context?.count,
					total: context?.totalCount,
					current: context?.page,
					showSizeChanger: false
				}}
				style={{ marginTop: 20 }}
				loading={isLoadingAccessories || isLoadingDetail}
				onRow={(record) => ({
					onClick: () => history.push(`${t('paths:accessory|path')}/${record?.id}`)
				})}
				locale={{
					emptyText: <Empty description={t('Žiadne dáta')} />
				}}
				size={'small'}
			/>
		</div>
	)
}

Accessories.propTypes = {}

export default Accessories
