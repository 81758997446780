import mapValues from 'lodash/mapValues'

// utils
import { ENDPOINTS } from '../../utils/enums'
import { getReq, postReq } from '../../utils/request'

// types
import {
	CHANGE_USER_SUBSCRIPTIONS_TIER,
	GET_USER_SUBSCRIPTIONS,
	GET_USER_SUBSCRIPTIONS_TIER,
	GET_USER_SUBSCRIPTIONS_TIER_VALIDATE,
	GetSubscriptionsPayload,
	GetSubscriptionsQueryParams,
	GetUsersUserIdSubscriptionsTierQueryParams,
	PostUsersUserIdSubscriptionsTierBody
} from './types'
import { GetUsersUserIdSubscriptionsTier } from '../users/types'

export const getUserSubscriptions =
	(userID: number, params?: GetSubscriptionsQueryParams, onSuccess?: (args: GetSubscriptionsPayload) => void, onFailure?: (error: unknown) => void) =>
	async (dispatch: any) => {
		dispatch({ type: GET_USER_SUBSCRIPTIONS.START })
		try {
			const queries = {
				limit: 20,
				page: 1,
				userID,
				orderBy: 'id',
				orderDirection: 'desc',
				...params
			}

			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.GET_SUBSCRIPTIONS, normalizeQueryParams)

			dispatch({
				type: GET_USER_SUBSCRIPTIONS.DONE,
				payload: data
			})

			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_USER_SUBSCRIPTIONS.FAILED })
			return onFailure && onFailure(error)
		}
	}

export const getUserSubscriptionsTierValidate = (userID: number, onSuccess?: () => void, onFailure?: () => void) => async (dispatch: any) => {
	dispatch({ type: GET_USER_SUBSCRIPTIONS_TIER_VALIDATE.START })
	try {
		const { data } = await getReq(ENDPOINTS.GET_USER_SUBSCRIPTIONS_TIER_VALIDATE(userID))
		dispatch({ type: GET_USER_SUBSCRIPTIONS_TIER_VALIDATE.DONE, payload: data })
		return onSuccess && onSuccess()
	} catch (error) {
		dispatch({ type: GET_USER_SUBSCRIPTIONS_TIER_VALIDATE.FAILED })
		return onFailure && onFailure()
	}
}

export const getUserSubscriptionsTier =
	(userID: number, params: GetUsersUserIdSubscriptionsTierQueryParams, onSuccess?: (args: GetUsersUserIdSubscriptionsTier) => void, onFailure?: () => void) =>
	async (dispatch: any) => {
		dispatch({ type: GET_USER_SUBSCRIPTIONS_TIER.START })
		try {
			const queries = {
				...params,
				tier: params?.tier
			}
			const normalizeQueryParams = mapValues(queries, (query) => query || undefined)
			const { data } = await getReq(ENDPOINTS.GET_USER_SUBSCRIPTIONS_TIER(userID), normalizeQueryParams)
			dispatch({
				type: GET_USER_SUBSCRIPTIONS_TIER.DONE
			})
			return onSuccess && onSuccess(data)
		} catch (error) {
			dispatch({ type: GET_USER_SUBSCRIPTIONS_TIER.FAILED })
			return onFailure && onFailure()
		}
	}

export const changeUserSubscriptionsTier =
	(userID: number, body: PostUsersUserIdSubscriptionsTierBody, onSuccess?: () => void, onFailure?: (error: unknown) => void) => async (dispatch: any) => {
		dispatch({ type: CHANGE_USER_SUBSCRIPTIONS_TIER.START })
		try {
			await postReq(ENDPOINTS.CHANGE_USER_SUBSCRIPTIONS_TIER(userID), undefined, body)
			dispatch({ type: CHANGE_USER_SUBSCRIPTIONS_TIER.DONE })
			return onSuccess && onSuccess()
		} catch (error) {
			dispatch({ type: CHANGE_USER_SUBSCRIPTIONS_TIER.FAILED })
			return onFailure && onFailure(error)
		}
	}
