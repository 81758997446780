import each from 'lodash/each'
import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import i18next from 'i18next'
import { Button, Empty, Table } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { LinkOutlined, SaveOutlined } from '@ant-design/icons'
import { getFormValues, initialize } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'

// redux
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RootState } from '../../redux'
import { getInstructors } from '../../redux/instructors/actions'
import { getLandingPage } from '../../redux/landing/actions'
import { getPrograms } from '../../redux/programs/actions'
import { setFilters } from '../../redux/filters/actions'

// utils
import { CHALLENGE_TYPE, EMPTY_VALUE, ENDPOINTS, FORMS, LANDING_STATES, LANGUAGES, VISIBILITY_ON_LANDING_PAGE } from '../../utils/enums'
import { putReq } from '../../utils/request'
import { setFiltersForPage } from '../../utils/helpers'

// types
import { GetAdminProgramsPayload } from '../../redux/programs/types'

// forms
import MetaTagsForm from './forms/MetatagsForm'

// components
import ChallengeModal from './components/ChallengeModal'
import ChallengesTable from './components/ChallengesTable'
import PartnerModal from './components/PartnerModal'
import PartnersTable from './components/PartnersTable'
import TestimonialModal from './components/TestimonialModal'
import TestimonialsTable from './components/TestimonialsTable'

// atoms
import BooleanRepresent from '../../atoms/BooleanRepresent'

const PAGE_SIZE = 10

const LandingPage = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const history = useHistory()

	// selectors
	const metadataFieldValues = useSelector((state: RootState) => getFormValues(FORMS.LANDING_METATAGS_FORM)(state))
	const persistFilter = useSelector((state: RootState) => state.filters)
	const testimonials = useSelector((state: RootState) => state.landing?.page?.data?.testimonials)
	const pageMetadata = useSelector((state: RootState) => state.landing?.page?.data?.pageMetadata)
	const partners = useSelector((state: RootState) => state.landing?.page?.data?.partners)
	const challenges = useSelector((state: RootState) => state.landing?.page?.data?.challenges)
	const isLoading = useSelector((state: RootState) => state.landing?.page?.isLoading)
	const programs = useSelector((state: RootState) => state.programs?.list?.tableList)
	const instructors = useSelector((state: RootState) => state.instructors?.instructors?.tableList)
	const programContext = useSelector((state: RootState) => state.programs?.list?.context)
	const instructorsContext = useSelector((state: RootState) => state.instructors?.instructors?.context)

	// states
	const [programFilter, setProgramFilter] = useState(setFiltersForPage(t('paths:programs|key'), persistFilter))
	const [instructorFilter, setInstructorFilter] = useState(setFiltersForPage(t('paths:instructors|key'), persistFilter))
	const [modalState, setModalState] = useState<{ open: LANDING_STATES | boolean; id?: number }>({ open: false })

	const initPageMetadata = () => {
		const langValues: any = {}
		forEach(LANGUAGES, (item) => {
			const lang = find(pageMetadata?.translations, { language: item })
			langValues[`title_${item}`] = get(lang, 'title')
			langValues[`ogTitle_${item}`] = get(lang, 'ogTitle')
			langValues[`description_${item}`] = get(lang, 'description')
			langValues[`ogDescription_${item}`] = get(lang, 'ogDescription')
		})
		dispatch(initialize(FORMS.LANDING_METATAGS_FORM, langValues))
	}

	useEffect(() => {
		initPageMetadata()
	}, [pageMetadata])

	useEffect(() => {
		dispatch(getLandingPage())
	}, [dispatch])

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			isVisibleOnLandingPage: VISIBILITY_ON_LANDING_PAGE.VISIBLE,
			page: 1,
			...programFilter
		}

		dispatch(getPrograms(body))
		dispatch(setFilters(t('paths:programs|key'), body))
	}, [programFilter, dispatch, t])

	useEffect(() => {
		const body = {
			limit: PAGE_SIZE,
			page: 1,
			isVisibleOnLandingPage: VISIBILITY_ON_LANDING_PAGE.VISIBLE,
			...instructorFilter
		}

		dispatch(getInstructors(body))
		dispatch(setFilters(t('paths:instructors|key'), body))
	}, [instructorFilter, dispatch, t])

	const handleProgramTableChange = (pagination: any, _: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setProgramFilter({
			limit: PAGE_SIZE,
			...programFilter,
			page: pagination.current,
			...order
		})
	}

	const handleInstructorTableChange = (pagination: any, _: any, sorter: any) => {
		let order = {}
		if (sorter.order) {
			order = {
				orderBy: sorter.field,
				orderDirection: sorter.order === 'ascend' ? 'asc' : 'desc'
			}
		}

		setInstructorFilter({
			limit: PAGE_SIZE,
			...programFilter,
			page: pagination.current,
			...order
		})
	}
	const renderModal = () => {
		switch (modalState.open) {
			case LANDING_STATES.CHALLENGE_CREATE:
			case LANDING_STATES.CHALLENGE_EDIT:
				return <ChallengeModal modalState={modalState} setModalState={setModalState} />
			case LANDING_STATES.PARTNER_CREATE:
			case LANDING_STATES.PARTNER_EDIT:
				return <PartnerModal modalState={modalState} setShowModal={setModalState} />
			case LANDING_STATES.TESTIMONIAL_CREATE:
			case LANDING_STATES.TESTIMONIAL_EDIT:
				return <TestimonialModal modalState={modalState} setModalState={setModalState} />
			default:
				return null
		}
	}

	const handleUpdateLandingPage = async () => {
		const values = metadataFieldValues
		const data: any = {
			pageMetadata: [],
			partners,
			testimonials,
			challenges
		}

		each(LANGUAGES, (item) => {
			const translation = {
				title: get(values, `title_${item}`),
				ogTitle: get(values, `ogTitle_${item}`),
				description: get(values, `description_${item}`),
				ogDescription: get(values, `ogDescription_${item}`),
				language: item
			}
			data.pageMetadata.push(translation)
		})

		try {
			await putReq(ENDPOINTS.GET_LANDING_PAGE, undefined, data)
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error)
		}
	}

	const programColumns = [
		{
			title: i18next.t('Logo'),
			dataIndex: 'logo',
			key: 'logo',
			width: 200,
			render: (logo: string, record: GetAdminProgramsPayload[0]) => <img src={logo} height={30} alt={record.name} />
		},
		{
			title: i18next.t('Názov'),
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Program/Výzva'),
			dataIndex: 'isChallenge',
			key: 'isChallenge',
			align: 'center',
			sorter: true,
			render: (isChallenge: boolean, record: GetAdminProgramsPayload[0]) => {
				if (isChallenge) {
					return record?.challengeType === CHALLENGE_TYPE.COMPETITIVE ? (
						<span className={'program-type'} style={{ background: '#f15859' }}>
							{t('Súťažná výzva')}
						</span>
					) : (
						<span className={'program-type'} style={{ background: '#58aff1' }}>
							{t('Harmonicka výzva')}
						</span>
					)
				}
				return (
					<span className={'program-type'} style={{ background: '#fea385' }}>
						{t('Program')}
					</span>
				)
			}
		},
		{
			title: i18next.t('Publikované'),
			dataIndex: 'isPublished',
			sorter: true,
			key: 'isPublished',
			width: 110,
			align: 'center',
			render: (state: boolean) => <BooleanRepresent isTrue={state} />
		},
		{
			title: i18next.t('Dátum poslednej úpravy'),
			dataIndex: 'updatedAt',
			key: 'updatedAt',
			sorter: true,
			width: 210,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (_: any, record: GetAdminProgramsPayload[0]) => (
				<span style={{ marginRight: '5px' }}>
					<Link to={`${t('paths:program|path')}/${get(record, 'key')}`} target={'_blank'} rel={'noopener noreferrer'}>
						<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
					</Link>
				</span>
			)
		}
	]

	const trainerColumns = [
		{
			title: i18next.t('Meno'),
			dataIndex: 'name',
			key: 'name',
			sorter: true,
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Krátky popis'),
			dataIndex: 'briefDescription',
			key: 'briefDescription',
			ellipsis: true,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: i18next.t('Publikované'),
			dataIndex: 'isPublished',
			sorter: true,
			key: 'isPublished',
			align: 'center',
			width: 110,
			render: (state: boolean) => <BooleanRepresent isTrue={state} />
		},
		{
			title: i18next.t('Dátum poslednej úpravy'),
			dataIndex: 'updatedAt',
			sorter: true,
			key: 'updatedAt',
			width: 210,
			render: (value: string) => value || EMPTY_VALUE
		},
		{
			title: '',
			key: 'operation',
			fixed: 'right',
			width: 50,
			render: (_: any, record: GetAdminProgramsPayload[0]) => (
				<span style={{ marginRight: '5px' }}>
					<Link to={`${t('paths:instructor|path')}/${get(record, 'key')}`} target={'_blank'} rel={'noopener noreferrer'}>
						<Button icon={<LinkOutlined />} onClick={(e) => e.stopPropagation()} />
					</Link>
				</span>
			)
		}
	]

	return (
		<div className={'page-wrapper'}>
			<Button icon={<SaveOutlined />} onClick={handleUpdateLandingPage} type={'primary'} style={{ float: 'right' }}>
				{t('Uložiť')}
			</Button>
			<div className={'landing-section'}>
				<h3>{t('Meta dáta')}</h3>
				<br />
				<MetaTagsForm />
			</div>
			<div className={'landing-section'}>
				<h3>{t('Výzvy')}</h3>
				<Button onClick={() => setModalState({ open: LANDING_STATES.CHALLENGE_CREATE })}>{'Pridať'}</Button>
				<ChallengesTable challenges={challenges} isLoading={isLoading} setModalState={setModalState} />
			</div>
			<div className={'landing-section'}>
				<h3>{t('Premeny')}</h3>
				<Button onClick={() => setModalState({ open: LANDING_STATES.TESTIMONIAL_CREATE })}>{'Pridať'}</Button>
				<TestimonialsTable testimonials={testimonials} isLoading={isLoading} setModalState={setModalState} />
			</div>
			<div className={'landing-section'}>
				<h3>{t('Partneri')}</h3>
				<Button onClick={() => setModalState({ open: LANDING_STATES.PARTNER_CREATE })}>{'Pridať'}</Button>
				<PartnersTable partners={partners} isLoading={isLoading} setModalState={setModalState} />
			</div>
			<div className={'landing-section'}>
				<h3>{t('Programy')}</h3>
				<div>{t('Na úvodnej stránke sa zobrazuje maximálne prvých šesť zvolených programov')}</div>
				<span>{`${t('Úpravy realizujte v')} `}</span>
				<a href={t('paths:programs|path')}>{t('zozname')}</a>
				<Table
					className={'general-table'}
					columns={programColumns as any}
					dataSource={programs}
					onChange={handleProgramTableChange}
					showSorterTooltip={false}
					pagination={{
						pageSize: PAGE_SIZE,
						total: get(programContext, 'totalCount'),
						current: get(programContext, 'page'),
						showSizeChanger: false
					}}
					style={{ marginTop: 20 }}
					loading={isLoading}
					onRow={(record) => ({
						onClick: () => {
							if (get(record, 'isChallenge')) {
								history.push(`${t('paths:challenge|path')}/${get(record, 'key')}`)
							} else {
								history.push(`${t('paths:program|path')}/${get(record, 'key')}`)
							}
						}
					})}
					locale={{
						emptyText: <Empty description={t('Žiadne dáta')} />
					}}
					size={'small'}
				/>
			</div>
			<div className={'landing-section'}>
				<h3>{t('Inštruktori')}</h3>
				<span>{`${t('Úpravy realizujte v')} `}</span>
				<a href={t('paths:instructors|path')}>{t('zozname')}</a>
				<Table
					className={'general-table'}
					columns={trainerColumns as any}
					style={{ marginTop: 20 }}
					dataSource={instructors}
					showSorterTooltip={false}
					onChange={handleInstructorTableChange}
					pagination={{
						pageSize: PAGE_SIZE,
						total: get(instructorsContext, 'totalCount'),
						current: get(instructorsContext, 'page'),
						showSizeChanger: false
					}}
					onRow={(record) => ({
						onClick: () => history.push(`${t('paths:instructor|path')}/${get(record, 'key')}`)
					})}
					locale={{
						emptyText: <Empty description={t('Žiadne dáta')} />
					}}
					size={'small'}
				/>
			</div>
			{renderModal()}
		</div>
	)
}

export default LandingPage
