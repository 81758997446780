// types
import { GET_GIFT_PRODUCTS } from './types'
import { ILoadingAndFailure } from '../../types/interfaces'

// TODO: Add GiftProductsPayload as type when Gift Products will be back in api doc
const initState = {
	giftProducts: {
		year: {},
		isLoading: false,
		isFailure: false
	} as any & ILoadingAndFailure
}

export default (state = initState, action: any) => {
	switch (action.type) {
		case GET_GIFT_PRODUCTS.START:
			return {
				...initState,
				...state,
				giftProducts: {
					year: state.giftProducts.year,
					isLoading: true,
					isFailure: false
				}
			}
		case GET_GIFT_PRODUCTS.DONE:
			return {
				...initState,
				...state,
				giftProducts: {
					year: action?.payload?.year,
					isLoading: false,
					isFailure: false
				}
			}
		case GET_GIFT_PRODUCTS.FAILED:
			return {
				...state,
				giftProducts: {
					...state.giftProducts,
					isLoading: false,
					isFailure: true
				}
			}
		default:
			return state
	}
}
