import { useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Card } from 'antd'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// redux
import { loginUser } from '../../redux/user/userActions'

// utils
import { ADMIN_ROLE } from '../../utils/enums'

// types
import { PostAuthorizationLoginBody, PostAuthorizationLoginPayload } from '../../redux/user/userTypes'

// forms
import LoginForm from './forms/LoginForm'
import { LoginFormValues } from './forms/validateLoginForm'

const LoginPage = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { t } = useTranslation()

	// refs
	const recaptchaRef = useRef<any>()

	const handleSubmit = async (values: LoginFormValues) => {
		const recaptcha = await recaptchaRef.current.executeAsync()

		dispatch(
			loginUser({ ...values, recaptcha } as PostAuthorizationLoginBody, ({ data }: PostAuthorizationLoginPayload) => {
				if (data?.profile?.role) {
					switch (data.profile.role) {
						case ADMIN_ROLE.BLOGGER:
							return history.push(t('paths:blog|path'))
						case ADMIN_ROLE.ADMIN:
							return history.push(t('paths:instructors|path'))
						case ADMIN_ROLE.SUPERADMIN:
							return history.push(t('paths:dashboard|path'))
						default:
							return history.push(t('paths:base|path'))
					}
				}
				return history.push(t('paths:base|path'))
			})
		)
	}

	return (
		<div className={'flex-container'}>
			<Card style={{ width: '400px', borderRadius: '8px', boxShadow: '0 0 15px #eaeaea' }}>
				<div className={'login-logo'} />
				<LoginForm onSubmit={handleSubmit} />
				<Link to={t('paths:forgottenPassword|path')}>{t('Zabudli ste heslo?')}</Link>
				<ReCAPTCHA ref={recaptchaRef} size={'invisible'} sitekey={window.__RUNTIME_CONFIG__.REACT_APP_RE_CAPTCHA_SITE_KEY} />
			</Card>
		</div>
	)
}

export default LoginPage
